import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { listTournamentDetails, updateTournament } from '../actions/tournamentActions'
import { listSeries } from '../actions/seriesActions'
import { listPokerGames } from '../actions/pokerGameActions'
import { TOURNAMENT_UPDATE_RESET } from '../constants/tournamentConstants'

const TournamentEditScreen = ({ match, history }) => {
  const tournamentId = match.params.id

  const [series, setSeries] = useState({})
  const [pokerGame, setPokerGame] = useState({})
  const [name, setName] = useState('')
  const [garranty, setGarranty] = useState(0)
  const [buyin, setBuyin] = useState(0)
  const [rake, setRake] = useState(0)
  const [enrolled, setEnrolled] = useState(0)
  const [stage, setStage] = useState(0)
  const [startDate, setStartDate] = useState('')
  const [finishDate, setFinishDate] = useState('')

  const dispatch = useDispatch()

  const seriesList = useSelector((state) => state.seriesList)
  const { loading: loadingSeries, error: errorSeries, seriesArray } = seriesList

  const pokerGameList = useSelector((state) => state.pokerGameList)
  const { loading: loadingPokerGames, error: errorPokerGames, pokerGames } = pokerGameList

  const tournamentDetails = useSelector((state) => state.tournamentDetails)
  const { loading, error, tournament } = tournamentDetails

  const tournamentUpdate = useSelector((state) => state.tournamentUpdate)
  const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = tournamentUpdate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }
    if (successUpdate) {
      dispatch({ type: TOURNAMENT_UPDATE_RESET })
      dispatch(listTournamentDetails(tournamentId))
      history.push('/admin/tournamentlist')
    } else {
      if (!tournament.name || tournament._id !== tournamentId) {
        dispatch(listTournamentDetails(tournamentId))
      } else {
        setSeries(tournament.series._id)
        setPokerGame(tournament.pokerGame._id)
        setName(tournament.name)
        setGarranty(tournament.garranty)
        setBuyin(tournament.buyin)
        setRake(tournament.rake)
        setEnrolled(tournament.enrolled)
        setStage(tournament.stage)
        setStartDate(tournament.startDate)
        setFinishDate(tournament.finishDate)
      }
      dispatch(listSeries())
      dispatch(listPokerGames())
    }
  }, [dispatch, history, tournament, tournamentId, successUpdate, userInfo])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateTournament({
        _id: tournamentId,
        series,
        pokerGame,
        name,
        garranty,
        buyin,
        rake,
        enrolled,
        stage,
        startDate,
        finishDate,
      })
    )
  }

  return (
    <>
      <Link to='/admin/tournamentlist' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Tournament</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            {loadingSeries ? (
              <Loader />
            ) : errorSeries ? (
              <Message variant='danger'>{errorSeries}</Message>
            ) : (
              <Form.Group controlId='series'>
                <Form.Label>Series</Form.Label>
                <Form.Control as='select' value={series} onChange={(e) => setSeries(e.target.value)}>
                  <option value=''>Select...</option>
                  {seriesArray
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map(
                      (seriesname) =>
                        seriesname.isActive === true && (
                          <option key={seriesname._id} value={seriesname._id}>
                            {seriesname.name}
                          </option>
                        )
                    )}
                </Form.Control>
              </Form.Group>
            )}

            {loadingPokerGames ? (
              <Loader />
            ) : errorPokerGames ? (
              <Message variant='danger'>{errorPokerGames}</Message>
            ) : (
              <Form.Group controlId='pokerGame'>
                <Form.Label>Game</Form.Label>
                <Form.Control as='select' value={pokerGame} onChange={(e) => setPokerGame(e.target.value)}>
                  <option value=''>Select...</option>
                  {pokerGames
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((game) => (
                      <option key={game._id} value={game._id}>
                        {game.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            )}

            <Form.Group controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='name'
                placeholder='Enter name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='stage'>
              <Form.Label>Stage</Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter stage'
                value={stage}
                onChange={(e) => setStage(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='garranty'>
              <Form.Label>Garranty</Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter garranty'
                value={garranty}
                onChange={(e) => setGarranty(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='buyin'>
              <Form.Label>Buy-in</Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter buy-in'
                value={buyin}
                onChange={(e) => setBuyin(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='rake'>
              <Form.Label>Rake</Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter rake'
                value={rake}
                onChange={(e) => setRake(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='enrolled'>
              <Form.Label>Enrolled</Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter number of entries'
                value={enrolled}
                onChange={(e) => setEnrolled(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='startDate'>
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type='date'
                placeholder='Enter start date'
                value={startDate.substring(0, 10)}
                onChange={(e) => setStartDate(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='finishDate'>
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type='date'
                placeholder='Enter finish date'
                value={finishDate.substring(0, 10)}
                onChange={(e) => setFinishDate(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button type='submit' variant='primary'>
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}
export default TournamentEditScreen
