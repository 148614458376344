import {
  SERIES_LIST_REQUEST,
  SERIES_LIST_SUCCESS,
  SERIES_LIST_FAIL,
  SERIES_LIST_RESET,
  SERIES_DETAILS_REQUEST,
  SERIES_DETAILS_SUCCESS,
  SERIES_DETAILS_FAIL,
  SERIES_DETAILS_RESET,
  SERIES_DELETE_FAIL,
  SERIES_DELETE_SUCCESS,
  SERIES_DELETE_REQUEST,
  SERIES_CREATE_REQUEST,
  SERIES_CREATE_SUCCESS,
  SERIES_CREATE_FAIL,
  SERIES_CREATE_RESET,
  SERIES_UPDATE_REQUEST,
  SERIES_UPDATE_SUCCESS,
  SERIES_UPDATE_FAIL,
  SERIES_UPDATE_RESET,
} from '../constants/seriesConstants'

export const seriesListReducer = (state = { seriesArray: [] }, action) => {
  switch (action.type) {
    case SERIES_LIST_REQUEST:
      return { loading: true, seriesArray: [] }
    case SERIES_LIST_SUCCESS:
      return {
        loading: false,
        seriesArray: action.payload,
      }
    case SERIES_LIST_FAIL:
      return { loading: false, error: action.payload }
    case SERIES_LIST_RESET:
      return { seriesArray: [] }
    default:
      return state
  }
}

export const seriesDetailsReducer = (state = { series: {} }, action) => {
  switch (action.type) {
    case SERIES_DETAILS_REQUEST:
      return { loading: true, ...state }
    case SERIES_DETAILS_SUCCESS:
      return { loading: false, series: action.payload }
    case SERIES_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case SERIES_DETAILS_RESET:
      return { series: {} }
    default:
      return state
  }
}

export const seriesDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SERIES_DELETE_REQUEST:
      return { loading: true }
    case SERIES_DELETE_SUCCESS:
      return { loading: false, success: true }
    case SERIES_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const seriesCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SERIES_CREATE_REQUEST:
      return { loading: true }
    case SERIES_CREATE_SUCCESS:
      return { loading: false, success: true, series: action.payload }
    case SERIES_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case SERIES_CREATE_RESET:
      return { seriesDetails: {} }
    default:
      return state
  }
}

export const seriesUpdateReducer = (state = { series: {} }, action) => {
  switch (action.type) {
    case SERIES_UPDATE_REQUEST:
      return { loading: true }
    case SERIES_UPDATE_SUCCESS:
      return { loading: false, success: true, series: action.payload }
    case SERIES_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case SERIES_UPDATE_RESET:
      return { series: {} }
    default:
      return state
  }
}
