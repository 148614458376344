export const PLAYER_LIST_REQUEST = 'PLAYER_LIST_REQUEST'
export const PLAYER_LIST_SUCCESS = 'PLAYER_LIST_SUCCESS'
export const PLAYER_LIST_FAIL = 'PLAYER_LIST_FAIL'
export const PLAYER_LIST_RESET = 'PLAYER_LIST_RESET'

export const PLAYER_DETAILS_REQUEST = 'PLAYER_DETAILS_REQUEST'
export const PLAYER_DETAILS_SUCCESS = 'PLAYER_DETAILS_SUCCESS'
export const PLAYER_DETAILS_FAIL = 'PLAYER_DETAILS_FAIL'

export const PLAYER_DELETE_REQUEST = 'PLAYER_DELETE_REQUEST'
export const PLAYER_DELETE_SUCCESS = 'PLAYER_DELETE_SUCCESS'
export const PLAYER_DELETE_FAIL = 'PLAYER_DELETE_FAIL'

export const PLAYER_CREATE_REQUEST = 'PLAYER_CREATE_REQUEST'
export const PLAYER_CREATE_SUCCESS = 'PLAYER_CREATE_SUCCESS'
export const PLAYER_CREATE_FAIL = 'PLAYER_CREATE_FAIL'
export const PLAYER_CREATE_RESET = 'PLAYER_CREATE_RESET'

export const PLAYER_UPDATE_REQUEST = 'PLAYER_UPDATE_REQUEST'
export const PLAYER_UPDATE_SUCCESS = 'PLAYER_UPDATE_SUCCESS'
export const PLAYER_UPDATE_FAIL = 'PLAYER_UPDATE_FAIL'
export const PLAYER_UPDATE_RESET = 'PLAYER_UPDATE_RESET'

export const PLAYER_LEADERBOARD_REQUEST = 'PLAYER_LEADERBOARD_REQUEST'
export const PLAYER_LEADERBOARD_SUCCESS = 'PLAYER_LEADERBOARD_SUCCESS'
export const PLAYER_LEADERBOARD_FAIL = 'PLAYER_LEADERBOARD_FAIL'
export const PLAYER_LEADERBOARD_RESET = 'PLAYER_LEADERBOARD_RESET'
