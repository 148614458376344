import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import DraftScreen from './screens/DraftScreen'
import CartScreen from './screens/CartScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import ProfileScreen from './screens/ProfileScreen'
import PaymentScreen from './screens/PaymentScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import OrderScreen from './screens/OrderScreen'
import UserListScreen from './screens/UserListScreen'
import UserEditScreen from './screens/UserEditScreen'
import BrandListScreen from './screens/BrandListScreen'
import BrandEditScreen from './screens/BrandEditScreen'
import PokerGameListScreen from './screens/PokerGameListScreen'
import PokerGameEditScreen from './screens/PokerGameEditScreen'
import DraftListScreen from './screens/DraftListScreen'
import DraftEditScreen from './screens/DraftEditScreen'
import PoolPlayersListScreen from './screens/PoolPlayersListScreen'
import PoolPlayersEditScreen from './screens/PoolPlayersEditScreen'
import PoolPlayersGroupEditScreen from './screens/PoolPlayersGroupEditScreen'
import PoolTourneysListScreen from './screens/PoolTourneysListScreen'
import PoolTourneysEditScreen from './screens/PoolTourneysEditScreen'
import PoolTourneysTournamentEditScreen from './screens/PoolTourneysTournamentEditScreen'
import PlayerListScreen from './screens/PlayerListScreen'
import PlayerEditScreen from './screens/PlayerEditScreen'
import SeriesListScreen from './screens/SeriesListScreen'
import SeriesEditScreen from './screens/SeriesEditScreen'
import TournamentListScreen from './screens/TournamentListScreen'
import TournamentEditScreen from './screens/TournamentEditScreen'
import TournamentResultListScreen from './screens/TournamentResultListScreen'
import TournamentResultEditScreen from './screens/TournamentResultEditScreen'
import TeamListScreen from './screens/TeamListScreen'
import TeamEditScreen from './screens/TeamEditScreen'
import TeamPlayerListScreen from './screens/TeamPlayerListScreen'
import TeamLeaderboardScreen from './screens/TeamLeaderboardScreen'
import PlayerStatsScreen from './screens/PlayerStatsScreen'
import OrderListScreen from './screens/OrderListScreen'

const App = () => {
  return (
    <Router>
      <Header />
      <main className='py-3'>
        <Container>
          <Route path='/order/:id' component={OrderScreen} />
          <Route path='/payment' component={PaymentScreen} />
          <Route path='/placeorder' component={PlaceOrderScreen} />
          <Route path='/admin/orderlist' component={OrderListScreen} />
          <Route path='/login' component={LoginScreen} />
          <Route path='/register' component={RegisterScreen} />
          <Route path='/profile' component={ProfileScreen} />
          <Route path='/draft/:id/:buyin' component={DraftScreen} />
          <Route path='/leaderboard/teams/:id/:buyin' component={TeamLeaderboardScreen} />
          <Route path='/leaderboard/players/:id/:buyin' component={PlayerStatsScreen} />
          <Route path='/cart/:id?' component={CartScreen} />
          <Route path='/admin/userlist' component={UserListScreen} />
          <Route path='/admin/user/:id/edit' component={UserEditScreen} />
          <Route path='/admin/brandlist' component={BrandListScreen} />
          <Route path='/admin/brand/:id/edit' component={BrandEditScreen} />
          <Route path='/admin/pokergamelist' component={PokerGameListScreen} />
          <Route path='/admin/pokergame/:id/edit' component={PokerGameEditScreen} />
          <Route path='/admin/serieslist' component={SeriesListScreen} />
          <Route path='/admin/series/:id/edit' component={SeriesEditScreen} />
          <Route path='/admin/tournamentlist' component={TournamentListScreen} />
          <Route path='/admin/tournament/:id/edit' component={TournamentEditScreen} />
          <Route path='/admin/tournament/:id/results' component={TournamentResultListScreen} />
          <Route path='/admin/tournament/:id/result/:resultid/edit' component={TournamentResultEditScreen} />
          <Route path='/admin/teamlist' component={TeamListScreen} />
          <Route path='/admin/team/:id/edit' component={TeamEditScreen} />
          <Route path='/admin/team/:id/players' component={TeamPlayerListScreen} />
          <Route path='/admin/team/:id/players/captain' component={TeamPlayerListScreen} />
          <Route path='/admin/draftlist' component={DraftListScreen} exact />
          <Route path='/admin/draftlist/:pageNumber' component={DraftListScreen} exact />
          <Route path='/admin/draft/:id/:buyin/edit' component={DraftEditScreen} />
          <Route path='/admin/poolplayerslist' component={PoolPlayersListScreen} />
          <Route path='/admin/poolplayers/:id/edit' component={PoolPlayersEditScreen} />
          <Route path='/admin/poolplayers/:id/groups/:groupid/edit' component={PoolPlayersGroupEditScreen} />
          <Route path='/admin/pooltourneyslist' component={PoolTourneysListScreen} />
          <Route path='/admin/pooltourneys/:id/edit' component={PoolTourneysEditScreen} />
          <Route
            path='/admin/pooltourneys/:id/tournaments/:tournamentid/edit'
            component={PoolTourneysTournamentEditScreen}
          />
          <Route path='/admin/playerlist' component={PlayerListScreen} exact />
          <Route path='/admin/playerlist/:pageNumber' component={PlayerListScreen} exact />
          <Route path='/admin/player/:id/edit' component={PlayerEditScreen} />
          <Route path='/search/:keyword' component={HomeScreen} exact />
          <Route path='/page/:pageNumber' component={HomeScreen} exact />
          <Route path='/search/:keyword/page/:pageNumber' component={HomeScreen} exact />
          <Route path='/' component={HomeScreen} exact />
        </Container>
      </main>
      <Footer />
    </Router>
  )
}

export default App
