import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  listPoolsPlayers,
  deletePoolPlayers,
  createPoolPlayers,
} from '../actions/poolPlayersActions'
import { POOLPLAYERS_CREATE_RESET } from '../constants/poolPlayersConstants'
import { SERIES_DETAILS_RESET } from '../constants/seriesConstants'

const PoolPlayersListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const poolPlayersList = useSelector((state) => state.poolPlayersList)
  const { loading, error, poolsPlayers } = poolPlayersList

  const poolPlayersDelete = useSelector((state) => state.poolPlayersDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = poolPlayersDelete

  const poolPlayersCreate = useSelector((state) => state.poolPlayersCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    poolPlayers: createdPoolPlayers,
  } = poolPlayersCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    dispatch({ type: POOLPLAYERS_CREATE_RESET })
    dispatch({ type: SERIES_DETAILS_RESET })
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }
    if (successCreate) {
      history.push(`/admin/poolplayers/${createdPoolPlayers._id}/edit`)
    } else {
      dispatch(listPoolsPlayers())
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdPoolPlayers,
  ])

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deletePoolPlayers(id))
    }
  }

  const createPoolPlayersHandler = () => {
    dispatch(createPoolPlayers())
  }

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Pools of Players</h1>
        </Col>
        <Col className='text-right'>
          <Button className='my-3' onClick={createPoolPlayersHandler}>
            <i className='fas fa-plus'></i> Create Pool
          </Button>
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>NAME</th>
                <th>SERIES</th>
                <th>ACTIVE</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {poolsPlayers
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((poolPlayers) => (
                  <tr key={poolPlayers._id}>
                    <td>{poolPlayers.name}</td>
                    {poolPlayers.series ? (
                      <td>{poolPlayers.series.name}</td>
                    ) : (
                      <td></td>
                    )}
                    <td>
                      {poolPlayers.isActive ? (
                        <i
                          className='fas fa-check'
                          style={{ color: 'green' }}
                        ></i>
                      ) : (
                        <i
                          className='fas fa-times'
                          style={{ color: 'grey' }}
                        ></i>
                      )}
                    </td>
                    <td>
                      <LinkContainer
                        to={`/admin/poolplayers/${poolPlayers._id}/edit`}
                      >
                        <Button variant='light' className='btn-sm'>
                          <i className='fas fa-edit'></i>
                        </Button>
                      </LinkContainer>
                      <Button
                        variant='danger'
                        className='btn-sm'
                        onClick={() => deleteHandler(poolPlayers._id)}
                      >
                        <i className='fas fa-trash'></i>
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  )
}

export default PoolPlayersListScreen
