import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { listPlayerDetails, updatePlayer } from '../actions/playerActions'
import { listBrands } from '../actions/brandActions'
import { PLAYER_UPDATE_RESET } from '../constants/playerConstants'

const PlayerEditScreen = ({ match, history }) => {
  const playerId = match.params.id

  const [image, setImage] = useState('')
  const [nickname, setNickname] = useState('')
  const [name, setName] = useState('')
  const [altname, setAltname] = useState('')
  const [country, setCountry] = useState('')
  const [city, setCity] = useState('')
  const [uploading, setUploading] = useState(false)

  const dispatch = useDispatch()

  const brandList = useSelector((state) => state.brandList)
  const { brands } = brandList

  const [brand, setBrand] = useState({})

  const playerDetails = useSelector((state) => state.playerDetails)
  const { loading, error, player } = playerDetails

  const playerUpdate = useSelector((state) => state.playerUpdate)
  const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = playerUpdate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }
    if (successUpdate) {
      dispatch({ type: PLAYER_UPDATE_RESET })
      dispatch(listPlayerDetails(playerId))
      history.push('/admin/playerlist')
    } else {
      if (!player || player._id !== playerId) {
        dispatch(listPlayerDetails(playerId))
      } else {
        setImage(player.image)
        setNickname(player.nickname)
        setName(player.name)
        setAltname(player.altname)
        setCountry(player.country)
        setCity(player.city)
        setBrand(player.brand._id)
      }
      dispatch(listBrands())
    }
  }, [dispatch, history, player, playerId, successUpdate, userInfo])

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await axios.post('/api/upload', formData, config)

      setImage(data)
      setUploading(false)
    } catch (error) {
      console.log(error)
      setUploading(false)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updatePlayer({
        _id: playerId,
        image,
        nickname,
        name,
        altname,
        country,
        city,
        brand,
      })
    )
  }

  return (
    <>
      <Link to='/admin/playerlist' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Player</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='nickname'>
              <Form.Label>Nickname</Form.Label>
              <Form.Control
                type='nickname'
                value={nickname}
                onChange={(e) => setNickname(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control type='name' value={name} onChange={(e) => setName(e.target.value)}></Form.Control>
            </Form.Group>

            <Form.Group controlId='altname'>
              <Form.Label>Alt Name</Form.Label>
              <Form.Control type='altname' value={altname} onChange={(e) => setAltname(e.target.value)}></Form.Control>
            </Form.Group>

            <Form.Group controlId='image'>
              <Form.Label>Image</Form.Label>
              <Form.Control type='text' value={image} onChange={(e) => setImage(e.target.value)}></Form.Control>
              <Form.File id='image-file' label='Choose File' custom onChange={uploadFileHandler}></Form.File>
              {uploading && <Loader />}
            </Form.Group>

            <Form.Group controlId='country'>
              <Form.Label>Country</Form.Label>
              <Form.Control type='country' value={country} onChange={(e) => setCountry(e.target.value)}></Form.Control>
            </Form.Group>

            <Form.Group controlId='city'>
              <Form.Label>City</Form.Label>
              <Form.Control type='city' value={city} onChange={(e) => setCity(e.target.value)}></Form.Control>
            </Form.Group>

            <Form.Group controlId='brand'>
              <Form.Label>Brand</Form.Label>
              <Form.Control as='select' value={brand} onChange={(e) => setBrand(e.target.value)}>
                <option value=''>Select...</option>
                {brands
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((brandname) => (
                    <option key={brandname._id} value={brandname._id}>
                      {brandname.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>

            <Button type='submit' variant='primary'>
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}
export default PlayerEditScreen
