export const POKERGAME_LIST_REQUEST = 'POKERGAME_LIST_REQUEST'
export const POKERGAME_LIST_SUCCESS = 'POKERGAME_LIST_SUCCESS'
export const POKERGAME_LIST_FAIL = 'POKERGAME_LIST_FAIL'
export const POKERGAME_LIST_RESET = 'POKERGAME_LIST_RESET'

export const POKERGAME_DETAILS_REQUEST = 'POKERGAME_DETAILS_REQUEST'
export const POKERGAME_DETAILS_SUCCESS = 'POKERGAME_DETAILS_SUCCESS'
export const POKERGAME_DETAILS_FAIL = 'POKERGAME_DETAILS_FAIL'

export const POKERGAME_DELETE_REQUEST = 'POKERGAME_DELETE_REQUEST'
export const POKERGAME_DELETE_SUCCESS = 'POKERGAME_DELETE_SUCCESS'
export const POKERGAME_DELETE_FAIL = 'POKERGAME_DELETE_FAIL'

export const POKERGAME_CREATE_REQUEST = 'POKERGAME_CREATE_REQUEST'
export const POKERGAME_CREATE_SUCCESS = 'POKERGAME_CREATE_SUCCESS'
export const POKERGAME_CREATE_FAIL = 'POKERGAME_CREATE_FAIL'
export const POKERGAME_CREATE_RESET = 'POKERGAME_CREATE_RESET'

export const POKERGAME_UPDATE_REQUEST = 'POKERGAME_UPDATE_REQUEST'
export const POKERGAME_UPDATE_SUCCESS = 'POKERGAME_UPDATE_SUCCESS'
export const POKERGAME_UPDATE_FAIL = 'POKERGAME_UPDATE_FAIL'
export const POKERGAME_UPDATE_RESET = 'POKERGAME_UPDATE_RESET'
