import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import {
  listPokerGameDetails,
  updatePokerGame,
} from '../actions/pokerGameActions'
import { POKERGAME_UPDATE_RESET } from '../constants/pokerGameConstants'

const PokerGameEditScreen = ({ match, history }) => {
  const pokerGameId = match.params.id

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  const dispatch = useDispatch()

  const pokerGameDetails = useSelector((state) => state.pokerGameDetails)
  const { loading, error, pokerGame } = pokerGameDetails

  const pokerGameUpdate = useSelector((state) => state.pokerGameUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = pokerGameUpdate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }
    if (successUpdate) {
      dispatch({ type: POKERGAME_UPDATE_RESET })
      history.push('/admin/pokergamelist')
    } else {
      if (!pokerGame.name || pokerGame._id !== pokerGameId) {
        dispatch(listPokerGameDetails(pokerGameId))
      } else {
        setName(pokerGame.name)
        setDescription(pokerGame.description)
      }
    }
  }, [dispatch, history, pokerGame, pokerGameId, successUpdate, userInfo])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updatePokerGame({
        _id: pokerGameId,
        name,
        description,
      })
    )
  }

  return (
    <>
      <Link to='/admin/pokergamelist' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Poker Game</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='name'
                placeholder='Enter name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='description'>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                placeholder='Enter description'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button type='submit' variant='primary'>
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}
export default PokerGameEditScreen
