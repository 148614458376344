import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import {
  brandListReducer,
  brandDetailsReducer,
  brandDeleteReducer,
  brandCreateReducer,
  brandUpdateReducer,
} from './reducers/brandReducers'
import {
  pokerGameListReducer,
  pokerGameDetailsReducer,
  pokerGameDeleteReducer,
  pokerGameCreateReducer,
  pokerGameUpdateReducer,
} from './reducers/pokerGameReducers'
import {
  playerListReducer,
  playerDetailsReducer,
  playerDeleteReducer,
  playerCreateReducer,
  playerUpdateReducer,
  playersLeaderboardReducer,
} from './reducers/playerReducers'
import {
  seriesListReducer,
  seriesDetailsReducer,
  seriesDeleteReducer,
  seriesCreateReducer,
  seriesUpdateReducer,
} from './reducers/seriesReducers'
import {
  tournamentListReducer,
  tournamentDetailsReducer,
  tournamentDeleteReducer,
  tournamentCreateReducer,
  tournamentUpdateReducer,
  tournamentResultCreateReducer,
  tournamentResultDeleteReducer,
  tournamentResultDetailsReducer,
  tournamentResultUpdateReducer,
} from './reducers/tournamentReducers'
import {
  teamListReducer,
  teamDetailsReducer,
  teamDeleteReducer,
  teamCreateReducer,
  teamCreateByUserReducer,
  teamUpdateReducer,
  teamPlayerCreateReducer,
  teamCaptainSelectReducer,
  teamPlayerDeleteReducer,
  teamPlayerDetailsReducer,
  teamsLeaderboardReducer,
} from './reducers/teamReducers'
import {
  poolPlayersListReducer,
  poolPlayersDetailsReducer,
  poolPlayersDeleteReducer,
  poolPlayersCreateReducer,
  poolPlayersUpdateReducer,
  poolPlayersGroupCreateReducer,
  poolPlayersGroupDeleteReducer,
  poolPlayersGroupDetailsReducer,
  poolPlayersGroupUpdateReducer,
  poolPlayersGroupPlayerAddReducer,
  poolPlayersGroupPlayerRemoveReducer,
} from './reducers/poolPlayersReducers'
import {
  poolTourneysListReducer,
  poolTourneysDetailsReducer,
  poolTourneysDeleteReducer,
  poolTourneysCreateReducer,
  poolTourneysUpdateReducer,
  poolTourneysTournamentCreateReducer,
  poolTourneysTournamentDeleteReducer,
  poolTourneysTournamentDetailsReducer,
  poolTourneysTournamentUpdateReducer,
} from './reducers/poolTourneysReducers'
import {
  draftListReducer,
  draftDetailsReducer,
  draftDeleteReducer,
  draftCreateReducer,
  draftUpdateReducer,
} from './reducers/draftReducers'
import { cartReducer } from './reducers/cartReducers'
import { myTeamReducer } from './reducers/myTeamReducers'
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
} from './reducers/userReducers'
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderMyOrdersReducer,
  orderListReducer,
} from './reducers/orderReducers'

const reducer = combineReducers({
  brandList: brandListReducer,
  brandDetails: brandDetailsReducer,
  brandDelete: brandDeleteReducer,
  brandCreate: brandCreateReducer,
  brandUpdate: brandUpdateReducer,
  pokerGameList: pokerGameListReducer,
  pokerGameDetails: pokerGameDetailsReducer,
  pokerGameDelete: pokerGameDeleteReducer,
  pokerGameCreate: pokerGameCreateReducer,
  pokerGameUpdate: pokerGameUpdateReducer,
  playerList: playerListReducer,
  playerDetails: playerDetailsReducer,
  playerDelete: playerDeleteReducer,
  playerCreate: playerCreateReducer,
  playerUpdate: playerUpdateReducer,
  playersLeaderboard: playersLeaderboardReducer,
  seriesList: seriesListReducer,
  seriesDetails: seriesDetailsReducer,
  seriesDelete: seriesDeleteReducer,
  seriesCreate: seriesCreateReducer,
  seriesUpdate: seriesUpdateReducer,
  tournamentList: tournamentListReducer,
  tournamentDetails: tournamentDetailsReducer,
  tournamentDelete: tournamentDeleteReducer,
  tournamentCreate: tournamentCreateReducer,
  tournamentUpdate: tournamentUpdateReducer,
  tournamentResultCreate: tournamentResultCreateReducer,
  tournamentResultDelete: tournamentResultDeleteReducer,
  tournamentResultDetails: tournamentResultDetailsReducer,
  tournamentResultUpdate: tournamentResultUpdateReducer,
  teamList: teamListReducer,
  teamDetails: teamDetailsReducer,
  teamDelete: teamDeleteReducer,
  teamCreate: teamCreateReducer,
  teamCreateByUser: teamCreateByUserReducer,
  teamUpdate: teamUpdateReducer,
  teamPlayerCreate: teamPlayerCreateReducer,
  teamCaptainSelect: teamCaptainSelectReducer,
  teamPlayerDelete: teamPlayerDeleteReducer,
  teamPlayerDetails: teamPlayerDetailsReducer,
  teamsLeaderboard: teamsLeaderboardReducer,
  draftList: draftListReducer,
  draftDetails: draftDetailsReducer,
  draftDelete: draftDeleteReducer,
  draftCreate: draftCreateReducer,
  draftUpdate: draftUpdateReducer,
  poolPlayersList: poolPlayersListReducer,
  poolPlayersDetails: poolPlayersDetailsReducer,
  poolPlayersDelete: poolPlayersDeleteReducer,
  poolPlayersCreate: poolPlayersCreateReducer,
  poolPlayersUpdate: poolPlayersUpdateReducer,
  poolPlayersGroupCreate: poolPlayersGroupCreateReducer,
  poolPlayersGroupDelete: poolPlayersGroupDeleteReducer,
  poolPlayersGroupDetails: poolPlayersGroupDetailsReducer,
  poolPlayersGroupUpdate: poolPlayersGroupUpdateReducer,
  poolPlayersGroupPlayerAdd: poolPlayersGroupPlayerAddReducer,
  poolPlayersGroupPlayerRemove: poolPlayersGroupPlayerRemoveReducer,
  poolTourneysList: poolTourneysListReducer,
  poolTourneysDetails: poolTourneysDetailsReducer,
  poolTourneysDelete: poolTourneysDeleteReducer,
  poolTourneysCreate: poolTourneysCreateReducer,
  poolTourneysUpdate: poolTourneysUpdateReducer,
  poolTourneysTournamentCreate: poolTourneysTournamentCreateReducer,
  poolTourneysTournamentDelete: poolTourneysTournamentDeleteReducer,
  poolTourneysTournamentDetails: poolTourneysTournamentDetailsReducer,
  poolTourneysTournamentUpdate: poolTourneysTournamentUpdateReducer,
  cart: cartReducer,
  myTeam: myTeamReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderMyOrders: orderMyOrdersReducer,
  orderList: orderListReducer,
})

const cartItemsFromStorage = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : []

const myTeamPlayersFromStorage = localStorage.getItem('myTeamPlayers')
  ? JSON.parse(localStorage.getItem('myTeamPlayers'))
  : []

const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null

const paymentMethodFromStorage = localStorage.getItem('paymentMethod')
  ? JSON.parse(localStorage.getItem('paymentMethod'))
  : {}

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    paymentMethod: paymentMethodFromStorage,
  },
  myTeam: {
    myTeamPlayers: myTeamPlayersFromStorage,
    paymentMethod: paymentMethodFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store
