import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col, ListGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { utcToZonedTime, format } from 'date-fns-tz'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import {
  listPoolTourneysDetails,
  updatePoolTourneys,
  createPoolTourneysTournament,
  deletePoolTourneysTournament,
} from '../actions/poolTourneysActions'
import { listSeries } from '../actions/seriesActions'
import { listTournaments } from '../actions/tournamentActions'
import {
  POOLTOURNEYS_UPDATE_RESET,
  POOLTOURNEYS_CREATE_TOURNAMENT_RESET,
  POOLTOURNEYS_DELETE_TOURNAMENT_RESET,
  POOLTOURNEYS_DETAILS_RESET,
} from '../constants/poolTourneysConstants'

const PoolTourneysEditScreen = ({ match, history }) => {
  const poolTourneysId = match.params.id
  const myTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const [name, setName] = useState('')
  const [series, setSeries] = useState('')
  const [isActive, setIsActive] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endDate, setEndDate] = useState('')
  const [endTime, setEndTime] = useState('')
  const [poolTourneysTournaments, setPoolTourneysTournaments] = useState([])
  const [tournament, setTournament] = useState('')
  const [number, setNumber] = useState(0)

  const dispatch = useDispatch()

  const seriesList = useSelector((state) => state.seriesList)
  const { seriesArray } = seriesList

  const tournamentList = useSelector((state) => state.tournamentList)
  const { loading: loadingTournaments, error: errorTournaments, tournaments } = tournamentList

  const poolTourneysDetails = useSelector((state) => state.poolTourneysDetails)
  const { loading, error, poolTourneys } = poolTourneysDetails

  const poolTourneysTournamentCreate = useSelector((state) => state.poolTourneysTournamentCreate)
  const { success: successPoolTourneysTournament, error: errorPoolTourneysTournament } = poolTourneysTournamentCreate

  const poolTourneysTournamentDelete = useSelector((state) => state.poolTourneysTournamentDelete)
  const { success: successPoolTourneysTournamentDelete, error: errorPoolTourneysTournamentDelete } =
    poolTourneysTournamentDelete

  const poolTourneysUpdate = useSelector((state) => state.poolTourneysUpdate)
  const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = poolTourneysUpdate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    } else {
      dispatch(listSeries())
      if (successPoolTourneysTournament) {
        setNumber(0)
        setTournament('')
        dispatch({ type: POOLTOURNEYS_CREATE_TOURNAMENT_RESET })
        dispatch({ type: POOLTOURNEYS_DETAILS_RESET })
        dispatch(listPoolTourneysDetails(poolTourneysId))
        setSeries('')
      }

      if (successPoolTourneysTournamentDelete) {
        dispatch({ type: POOLTOURNEYS_DELETE_TOURNAMENT_RESET })
        dispatch({ type: POOLTOURNEYS_DETAILS_RESET })
        dispatch(listPoolTourneysDetails(poolTourneysId))
        setSeries('')
      }

      if (successUpdate) {
        dispatch({ type: POOLTOURNEYS_UPDATE_RESET })
        history.push('/admin/pooltourneyslist')
      } else {
        if (series !== '') {
          dispatch(listTournaments(series))
        } else {
          if (poolTourneys.series) {
            setSeries(poolTourneys.series._id)
            setName(poolTourneys.name)
            setIsActive(poolTourneys.isActive)
            let timePattern = 'HH:mm'
            let datePattern = 'yyyy-MM-dd'
            let localStartDateTime = utcToZonedTime(poolTourneys.startDate, myTimeZone)
            let localStartDate = format(localStartDateTime, datePattern)
            let localStartTime = format(localStartDateTime, timePattern)
            let localEndDateTime = utcToZonedTime(poolTourneys.endDate, myTimeZone)
            let localEndDate = format(localEndDateTime, datePattern)
            let localEndTime = format(localEndDateTime, timePattern)
            setStartDate(localStartDate)
            setStartTime(localStartTime)
            setEndDate(localEndDate)
            setEndTime(localEndTime)
            setPoolTourneysTournaments(poolTourneys.poolTourneysTournaments)
          } else {
            if (!poolTourneys || poolTourneys._id !== poolTourneysId) {
              dispatch(listPoolTourneysDetails(poolTourneysId))
            }
          }
        }
      }
    }
  }, [
    dispatch,
    history,
    poolTourneys,
    poolTourneysId,
    successUpdate,
    successPoolTourneysTournament,
    successPoolTourneysTournamentDelete,
    userInfo,
    series,
    myTimeZone,
  ])

  const submitHandler = (e) => {
    e.preventDefault()
    const startDateTime = startDate + 'T' + startTime
    const endDateTime = endDate + 'T' + endTime
    dispatch(
      updatePoolTourneys({
        _id: poolTourneysId,
        name,
        series,
        isActive,
        startDateTime,
        endDateTime,
      })
    )
  }

  const submitTournamentHandler = (e) => {
    e.preventDefault()
    dispatch(
      createPoolTourneysTournament(match.params.id, {
        tournament,
        number,
      })
    )
  }

  const deleteTournamentHandler = (tournamentid) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deletePoolTourneysTournament(match.params.id, tournamentid))
    }
  }

  return (
    <>
      <Link to='/admin/pooltourneyslist' className='btn btn-light my-3'>
        Go Back To Pool List
      </Link>
      <Row>
        <Col md={7}>
          <FormContainer>
            <h1>Edit Pool</h1>
            {loadingUpdate && <Loader />}
            {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <Form onSubmit={submitHandler}>
                <Form.Group controlId='name'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type='name'
                    placeholder='Enter name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId='brand'>
                  <Form.Label>Series</Form.Label>
                  <Form.Control as='select' value={series} onChange={(e) => setSeries(e.target.value)}>
                    <option value=''>Select...</option>
                    {seriesArray
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((seriesname) => (
                        <option key={seriesname._id} value={seriesname._id}>
                          {seriesname.name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId='isactive'>
                  <Form.Check
                    type='checkbox'
                    label='Is Active'
                    checked={isActive}
                    onChange={(e) => setIsActive(e.target.checked)}
                  ></Form.Check>
                </Form.Group>

                <Form.Group controlId='startDate'>
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type='date'
                    placeholder='Enter start date'
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId='startTime'>
                  <Form.Label>Start Time</Form.Label>
                  <Form.Control
                    type='time'
                    placeholder='Enter start time'
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId='endDate'>
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type='date'
                    placeholder='Enter end date'
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId='endTime'>
                  <Form.Label>End Time</Form.Label>
                  <Form.Control
                    type='time'
                    placeholder='Enter end time'
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Button type='submit' variant='primary'>
                  Update Pool
                </Button>
              </Form>
            )}
          </FormContainer>
        </Col>
        <Col md={5}>
          <Row>
            <Col>
              <h2>Tournaments</h2>
              {poolTourneysTournaments.length === 0 && <Message>No Tournaments</Message>}
              <ListGroup variant='flush'>
                {poolTourneysTournaments
                  .sort((a, b) => (a.number > b.number ? 1 : -1))
                  .map((tournament) => (
                    <ListGroup.Item key={tournament._id}>
                      <Row>
                        <Col>
                          <strong>
                            {tournament.number}: {tournament.tournament.name}
                          </strong>
                        </Col>
                        <Col>
                          <LinkContainer
                            to={`/admin/pooltourneys/${poolTourneysId}/tournaments/${tournament._id}/edit`}
                          >
                            <Button variant='light' className='btn-sm'>
                              <i className='fas fa-edit'></i>
                            </Button>
                          </LinkContainer>
                          <Button
                            variant='danger'
                            className='btn-sm'
                            onClick={() => deleteTournamentHandler(tournament._id)}
                          >
                            <i className='fas fa-trash'></i>
                          </Button>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                <ListGroup.Item>
                  {errorPoolTourneysTournament && <Message variant='danger'>{errorPoolTourneysTournament}</Message>}
                  {errorPoolTourneysTournamentDelete && (
                    <Message variant='danger'>{errorPoolTourneysTournamentDelete}</Message>
                  )}
                  {loadingTournaments ? (
                    <Loader />
                  ) : errorTournaments ? (
                    <Message variant='danger'>{errorTournaments}</Message>
                  ) : (
                    <Form onSubmit={submitTournamentHandler}>
                      <Form.Group controlId='tournament'>
                        <Form.Label>Tournament</Form.Label>
                        <Form.Control as='select' value={tournament} onChange={(e) => setTournament(e.target.value)}>
                          <option value=''>Select...</option>
                          {tournaments.map((tournamentItem) => (
                            <option key={tournamentItem._id} value={tournamentItem._id}>
                              {tournamentItem.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId='tournamentNumber'>
                        <Form.Label>Tourney number</Form.Label>
                        <Form.Control
                          type='number'
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                        ></Form.Control>
                      </Form.Group>

                      <Button type='submit' variant='primary'>
                        Add Tournament
                      </Button>
                    </Form>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
export default PoolTourneysEditScreen
