import {
  POOLTOURNEYS_LIST_REQUEST,
  POOLTOURNEYS_LIST_SUCCESS,
  POOLTOURNEYS_LIST_FAIL,
  POOLTOURNEYS_DETAILS_REQUEST,
  POOLTOURNEYS_DETAILS_SUCCESS,
  POOLTOURNEYS_DETAILS_FAIL,
  POOLTOURNEYS_DETAILS_RESET,
  POOLTOURNEYS_DELETE_FAIL,
  POOLTOURNEYS_DELETE_SUCCESS,
  POOLTOURNEYS_DELETE_REQUEST,
  POOLTOURNEYS_CREATE_REQUEST,
  POOLTOURNEYS_CREATE_SUCCESS,
  POOLTOURNEYS_CREATE_FAIL,
  POOLTOURNEYS_CREATE_RESET,
  POOLTOURNEYS_UPDATE_REQUEST,
  POOLTOURNEYS_UPDATE_SUCCESS,
  POOLTOURNEYS_UPDATE_FAIL,
  POOLTOURNEYS_UPDATE_RESET,
  POOLTOURNEYS_CREATE_TOURNAMENT_REQUEST,
  POOLTOURNEYS_CREATE_TOURNAMENT_SUCCESS,
  POOLTOURNEYS_CREATE_TOURNAMENT_FAIL,
  POOLTOURNEYS_CREATE_TOURNAMENT_RESET,
  POOLTOURNEYS_DELETE_TOURNAMENT_REQUEST,
  POOLTOURNEYS_DELETE_TOURNAMENT_SUCCESS,
  POOLTOURNEYS_DELETE_TOURNAMENT_FAIL,
  POOLTOURNEYS_DELETE_TOURNAMENT_RESET,
  POOLTOURNEYS_TOURNAMENT_DETAILS_REQUEST,
  POOLTOURNEYS_TOURNAMENT_DETAILS_SUCCESS,
  POOLTOURNEYS_TOURNAMENT_DETAILS_FAIL,
  POOLTOURNEYS_TOURNAMENT_DETAILS_RESET,
  POOLTOURNEYS_TOURNAMENT_UPDATE_REQUEST,
  POOLTOURNEYS_TOURNAMENT_UPDATE_SUCCESS,
  POOLTOURNEYS_TOURNAMENT_UPDATE_FAIL,
  POOLTOURNEYS_TOURNAMENT_UPDATE_RESET,
} from '../constants/poolTourneysConstants'

export const poolTourneysListReducer = (
  state = { poolsTourneys: [] },
  action
) => {
  switch (action.type) {
    case POOLTOURNEYS_LIST_REQUEST:
      return { loading: true, poolsTourneys: [] }
    case POOLTOURNEYS_LIST_SUCCESS:
      return {
        loading: false,
        poolsTourneys: action.payload,
      }
    case POOLTOURNEYS_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const poolTourneysDetailsReducer = (
  state = { poolTourneys: {} },
  action
) => {
  switch (action.type) {
    case POOLTOURNEYS_DETAILS_REQUEST:
      return { ...state, loading: true }
    case POOLTOURNEYS_DETAILS_SUCCESS:
      return { loading: false, poolTourneys: action.payload }
    case POOLTOURNEYS_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case POOLTOURNEYS_DETAILS_RESET:
      return { poolTourneys: {} }
    default:
      return state
  }
}

export const poolTourneysDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case POOLTOURNEYS_DELETE_REQUEST:
      return { loading: true }
    case POOLTOURNEYS_DELETE_SUCCESS:
      return { loading: false, success: true }
    case POOLTOURNEYS_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const poolTourneysCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case POOLTOURNEYS_CREATE_REQUEST:
      return { loading: true }
    case POOLTOURNEYS_CREATE_SUCCESS:
      return { loading: false, success: true, poolTourneys: action.payload }
    case POOLTOURNEYS_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case POOLTOURNEYS_CREATE_RESET:
      return { poolTourneysDetails: {} }
    default:
      return state
  }
}

export const poolTourneysUpdateReducer = (
  state = { poolTourneys: {} },
  action
) => {
  switch (action.type) {
    case POOLTOURNEYS_UPDATE_REQUEST:
      return { loading: true }
    case POOLTOURNEYS_UPDATE_SUCCESS:
      return { loading: false, success: true, poolTourneys: action.payload }
    case POOLTOURNEYS_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case POOLTOURNEYS_UPDATE_RESET:
      return { poolTourneys: {} }
    default:
      return state
  }
}

export const poolTourneysTournamentCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case POOLTOURNEYS_CREATE_TOURNAMENT_REQUEST:
      return { loading: true }
    case POOLTOURNEYS_CREATE_TOURNAMENT_SUCCESS:
      return { loading: false, success: true }
    case POOLTOURNEYS_CREATE_TOURNAMENT_FAIL:
      return { loading: false, error: action.payload }
    case POOLTOURNEYS_CREATE_TOURNAMENT_RESET:
      return {}
    default:
      return state
  }
}

export const poolTourneysTournamentDeleteReducer = (
  state = { poolTourneys: {} },
  action
) => {
  switch (action.type) {
    case POOLTOURNEYS_DELETE_TOURNAMENT_REQUEST:
      return { loading: true }
    case POOLTOURNEYS_DELETE_TOURNAMENT_SUCCESS:
      return { loading: false, success: true }
    case POOLTOURNEYS_DELETE_TOURNAMENT_FAIL:
      return { loading: false, error: action.payload }
    case POOLTOURNEYS_DELETE_TOURNAMENT_RESET:
      return { poolTourneys: {} }
    default:
      return state
  }
}

export const poolTourneysTournamentDetailsReducer = (
  state = { tournament: {} },
  action
) => {
  switch (action.type) {
    case POOLTOURNEYS_TOURNAMENT_DETAILS_REQUEST:
      return { loading: true, ...state }
    case POOLTOURNEYS_TOURNAMENT_DETAILS_SUCCESS:
      return { loading: false, tournament: action.payload }
    case POOLTOURNEYS_TOURNAMENT_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case POOLTOURNEYS_TOURNAMENT_DETAILS_RESET:
      return { tournament: {} }
    default:
      return state
  }
}

export const poolTourneysTournamentUpdateReducer = (
  state = { tournament: {} },
  action
) => {
  switch (action.type) {
    case POOLTOURNEYS_TOURNAMENT_UPDATE_REQUEST:
      return { loading: true }
    case POOLTOURNEYS_TOURNAMENT_UPDATE_SUCCESS:
      return { loading: false, success: true, tournament: action.payload }
    case POOLTOURNEYS_TOURNAMENT_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case POOLTOURNEYS_TOURNAMENT_UPDATE_RESET:
      return { tournament: {} }
    default:
      return state
  }
}
