import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listPlayers, deletePlayer, createPlayer } from '../actions/playerActions'
import { PLAYER_CREATE_RESET } from '../constants/playerConstants'

const PlayerListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const playerList = useSelector((state) => state.playerList)
  const { loading, error, players } = playerList

  const playerDelete = useSelector((state) => state.playerDelete)
  const { loading: loadingDelete, error: errorDelete, success: successDelete } = playerDelete

  const playerCreate = useSelector((state) => state.playerCreate)
  const { loading: loadingCreate, error: errorCreate, success: successCreate, player: createdPlayer } = playerCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    dispatch({ type: PLAYER_CREATE_RESET })
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }
    if (successCreate) {
      history.push(`/admin/player/${createdPlayer._id}/edit`)
    } else {
      dispatch(listPlayers('all'))
    }
  }, [dispatch, history, userInfo, successDelete, successCreate, createdPlayer])

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deletePlayer(id))
    }
  }

  const createPlayerHandler = () => {
    dispatch(createPlayer())
  }

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Players</h1>
        </Col>
        <Col className='text-right'>
          <Button className='my-3' onClick={createPlayerHandler}>
            <i className='fas fa-plus'></i> Create Player
          </Button>
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>IMAGE</th>
              <th>NICKNAME</th>
              <th>NAME</th>
              <th>ALTNAME</th>
              <th>COUNTRY</th>
              <th>BRAND</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {players
              .sort((a, b) =>
                a.brand.name === b.brand.name
                  ? a.nickname.toLowerCase() === b.nickname.toLowerCase()
                    ? a.name.toLowerCase() > b.name.toLowerCase()
                      ? 1
                      : -1
                    : a.nickname.toLowerCase() > b.nickname.toLowerCase()
                    ? 1
                    : -1
                  : a.brand.name > b.brand.name
                  ? 1
                  : -1
              )
              .map((player) => (
                <tr key={player._id}>
                  <td>
                    <Image src={player.image} alt={player.name} width='100vw' fluid rounded />
                  </td>
                  <td>{player.nickname}</td>
                  <td>{player.name}</td>
                  <td>{player.altname}</td>
                  <td>{player.country}</td>
                  <td>{player.brand.name}</td>
                  <td>
                    <LinkContainer to={`/admin/player/${player._id}/edit`}>
                      <Button variant='light' className='btn-sm'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </LinkContainer>
                    <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(player._id)}>
                      <i className='fas fa-trash'></i>
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  )
}

export default PlayerListScreen
