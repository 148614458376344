export const POOLPLAYERS_LIST_REQUEST = 'POOLPLAYERS_LIST_REQUEST'
export const POOLPLAYERS_LIST_SUCCESS = 'POOLPLAYERS_LIST_SUCCESS'
export const POOLPLAYERS_LIST_FAIL = 'POOLPLAYERS_LIST_FAIL'

export const POOLPLAYERS_DETAILS_REQUEST = 'POOLPLAYERS_DETAILS_REQUEST'
export const POOLPLAYERS_DETAILS_SUCCESS = 'POOLPLAYERS_DETAILS_SUCCESS'
export const POOLPLAYERS_DETAILS_FAIL = 'POOLPLAYERS_DETAILS_FAIL'
export const POOLPLAYERS_DETAILS_RESET = 'POOLPLAYERS_DETAILS_RESET'

export const POOLPLAYERS_DELETE_REQUEST = 'POOLPLAYERS_DELETE_REQUEST'
export const POOLPLAYERS_DELETE_SUCCESS = 'POOLPLAYERS_DELETE_SUCCESS'
export const POOLPLAYERS_DELETE_FAIL = 'POOLPLAYERS_DELETE_FAIL'

export const POOLPLAYERS_CREATE_REQUEST = 'POOLPLAYERS_CREATE_REQUEST'
export const POOLPLAYERS_CREATE_SUCCESS = 'POOLPLAYERS_CREATE_SUCCESS'
export const POOLPLAYERS_CREATE_FAIL = 'POOLPLAYERS_CREATE_FAIL'
export const POOLPLAYERS_CREATE_RESET = 'POOLPLAYERS_CREATE_RESET'

export const POOLPLAYERS_UPDATE_REQUEST = 'POOLPLAYERS_UPDATE_REQUEST'
export const POOLPLAYERS_UPDATE_SUCCESS = 'POOLPLAYERS_UPDATE_SUCCESS'
export const POOLPLAYERS_UPDATE_FAIL = 'POOLPLAYERS_UPDATE_FAIL'
export const POOLPLAYERS_UPDATE_RESET = 'POOLPLAYERS_UPDATE_RESET'

export const POOLPLAYERS_CREATE_GROUP_REQUEST =
  'POOLPLAYERS_CREATE_GROUP_REQUEST'
export const POOLPLAYERS_CREATE_GROUP_SUCCESS =
  'POOLPLAYERS_CREATE_GROUP_SUCCESS'
export const POOLPLAYERS_CREATE_GROUP_FAIL = 'POOLPLAYERS_CREATE_GROUP_FAIL'
export const POOLPLAYERS_CREATE_GROUP_RESET = 'POOLPLAYERS_CREATE_GROUP_RESET'

export const POOLPLAYERS_DELETE_GROUP_REQUEST =
  'POOLPLAYERS_DELETE_GROUP_REQUEST'
export const POOLPLAYERS_DELETE_GROUP_SUCCESS =
  'POOLPLAYERS_DELETE_GROUP_SUCCESS'
export const POOLPLAYERS_DELETE_GROUP_FAIL = 'POOLPLAYERS_DELETE_GROUP_FAIL'
export const POOLPLAYERS_DELETE_GROUP_RESET = 'POOLPLAYERS_DELETE_GROUP_RESET'

export const POOLPLAYERS_GROUP_DETAILS_REQUEST =
  'POOLPLAYERS_GROUP_DETAILS_REQUEST'
export const POOLPLAYERS_GROUP_DETAILS_SUCCESS =
  'POOLPLAYERS_GROUP_DETAILS_SUCCESS'
export const POOLPLAYERS_GROUP_DETAILS_FAIL = 'POOLPLAYERS_GROUP_DETAILS_FAIL'
export const POOLPLAYERS_GROUP_DETAILS_RESET = 'POOLPLAYERS_GROUP_DETAILS_RESET'

export const POOLPLAYERS_GROUP_UPDATE_REQUEST =
  'POOLPLAYERS_GROUP_UPDATE_REQUEST'
export const POOLPLAYERS_GROUP_UPDATE_SUCCESS =
  'POOLPLAYERS_GROUP_UPDATE_SUCCESS'
export const POOLPLAYERS_GROUP_UPDATE_FAIL = 'POOLPLAYERS_GROUP_UPDATE_FAIL'
export const POOLPLAYERS_GROUP_UPDATE_RESET = 'POOLPLAYERS_GROUP_UPDATE_RESET'

export const POOLPLAYERS_ADD_PLAYER_TO_GROUP_REQUEST =
  'POOLPLAYERS_ADD_PLAYER_TO_GROUP_REQUEST'
export const POOLPLAYERS_ADD_PLAYER_TO_GROUP_SUCCESS =
  'POOLPLAYERS_ADD_PLAYER_TO_GROUP_SUCCESS'
export const POOLPLAYERS_ADD_PLAYER_TO_GROUP_FAIL =
  'POOLPLAYERS_ADD_PLAYER_TO_GROUP_FAIL'
export const POOLPLAYERS_ADD_PLAYER_TO_GROUP_RESET =
  'POOLPLAYERS_ADD_PLAYER_TO_GROUP_RESET'

export const POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_REQUEST =
  'POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_REQUEST'
export const POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_SUCCESS =
  'POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_SUCCESS'
export const POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_FAIL =
  'POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_FAIL'
export const POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_RESET =
  'POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_RESET'
