import {
  POOLPLAYERS_LIST_REQUEST,
  POOLPLAYERS_LIST_SUCCESS,
  POOLPLAYERS_LIST_FAIL,
  POOLPLAYERS_DETAILS_REQUEST,
  POOLPLAYERS_DETAILS_SUCCESS,
  POOLPLAYERS_DETAILS_FAIL,
  POOLPLAYERS_DETAILS_RESET,
  POOLPLAYERS_DELETE_FAIL,
  POOLPLAYERS_DELETE_SUCCESS,
  POOLPLAYERS_DELETE_REQUEST,
  POOLPLAYERS_CREATE_REQUEST,
  POOLPLAYERS_CREATE_SUCCESS,
  POOLPLAYERS_CREATE_FAIL,
  POOLPLAYERS_CREATE_RESET,
  POOLPLAYERS_UPDATE_REQUEST,
  POOLPLAYERS_UPDATE_SUCCESS,
  POOLPLAYERS_UPDATE_FAIL,
  POOLPLAYERS_UPDATE_RESET,
  POOLPLAYERS_CREATE_GROUP_REQUEST,
  POOLPLAYERS_CREATE_GROUP_SUCCESS,
  POOLPLAYERS_CREATE_GROUP_FAIL,
  POOLPLAYERS_CREATE_GROUP_RESET,
  POOLPLAYERS_DELETE_GROUP_REQUEST,
  POOLPLAYERS_DELETE_GROUP_SUCCESS,
  POOLPLAYERS_DELETE_GROUP_FAIL,
  POOLPLAYERS_DELETE_GROUP_RESET,
  POOLPLAYERS_GROUP_DETAILS_REQUEST,
  POOLPLAYERS_GROUP_DETAILS_SUCCESS,
  POOLPLAYERS_GROUP_DETAILS_FAIL,
  POOLPLAYERS_GROUP_DETAILS_RESET,
  POOLPLAYERS_GROUP_UPDATE_REQUEST,
  POOLPLAYERS_GROUP_UPDATE_SUCCESS,
  POOLPLAYERS_GROUP_UPDATE_FAIL,
  POOLPLAYERS_GROUP_UPDATE_RESET,
  POOLPLAYERS_ADD_PLAYER_TO_GROUP_REQUEST,
  POOLPLAYERS_ADD_PLAYER_TO_GROUP_SUCCESS,
  POOLPLAYERS_ADD_PLAYER_TO_GROUP_FAIL,
  POOLPLAYERS_ADD_PLAYER_TO_GROUP_RESET,
  POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_REQUEST,
  POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_SUCCESS,
  POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_FAIL,
  POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_RESET,
} from '../constants/poolPlayersConstants'

export const poolPlayersListReducer = (state = { poolsPlayers: [] }, action) => {
  switch (action.type) {
    case POOLPLAYERS_LIST_REQUEST:
      return { loading: true, poolsPlayers: [] }
    case POOLPLAYERS_LIST_SUCCESS:
      return {
        loading: false,
        poolsPlayers: action.payload,
      }
    case POOLPLAYERS_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const poolPlayersDetailsReducer = (state = { poolPlayers: {} }, action) => {
  switch (action.type) {
    case POOLPLAYERS_DETAILS_REQUEST:
      return { ...state, loading: true }
    case POOLPLAYERS_DETAILS_SUCCESS:
      return { loading: false, poolPlayers: action.payload }
    case POOLPLAYERS_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case POOLPLAYERS_DETAILS_RESET:
      return { poolPlayers: {} }
    default:
      return state
  }
}

export const poolPlayersDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case POOLPLAYERS_DELETE_REQUEST:
      return { loading: true }
    case POOLPLAYERS_DELETE_SUCCESS:
      return { loading: false, success: true }
    case POOLPLAYERS_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const poolPlayersCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case POOLPLAYERS_CREATE_REQUEST:
      return { loading: true }
    case POOLPLAYERS_CREATE_SUCCESS:
      return { loading: false, success: true, poolPlayers: action.payload }
    case POOLPLAYERS_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case POOLPLAYERS_CREATE_RESET:
      return { poolPlayersDetails: {} }
    default:
      return state
  }
}

export const poolPlayersUpdateReducer = (state = { poolPlayers: {} }, action) => {
  switch (action.type) {
    case POOLPLAYERS_UPDATE_REQUEST:
      return { loading: true }
    case POOLPLAYERS_UPDATE_SUCCESS:
      return { loading: false, success: true, poolPlayers: action.payload }
    case POOLPLAYERS_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case POOLPLAYERS_UPDATE_RESET:
      return { poolPlayers: {} }
    default:
      return state
  }
}

export const poolPlayersGroupCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case POOLPLAYERS_CREATE_GROUP_REQUEST:
      return { loading: true }
    case POOLPLAYERS_CREATE_GROUP_SUCCESS:
      return { loading: false, success: true }
    case POOLPLAYERS_CREATE_GROUP_FAIL:
      return { loading: false, error: action.payload }
    case POOLPLAYERS_CREATE_GROUP_RESET:
      return {}
    default:
      return state
  }
}

export const poolPlayersGroupDeleteReducer = (state = { poolPlayers: {} }, action) => {
  switch (action.type) {
    case POOLPLAYERS_DELETE_GROUP_REQUEST:
      return { loading: true }
    case POOLPLAYERS_DELETE_GROUP_SUCCESS:
      return { loading: false, success: true }
    case POOLPLAYERS_DELETE_GROUP_FAIL:
      return { loading: false, error: action.payload }
    case POOLPLAYERS_DELETE_GROUP_RESET:
      return { poolPlayers: {} }
    default:
      return state
  }
}

export const poolPlayersGroupDetailsReducer = (state = { group: {} }, action) => {
  switch (action.type) {
    case POOLPLAYERS_GROUP_DETAILS_REQUEST:
      return { loading: true, ...state }
    case POOLPLAYERS_GROUP_DETAILS_SUCCESS:
      return { loading: false, group: action.payload }
    case POOLPLAYERS_GROUP_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case POOLPLAYERS_GROUP_DETAILS_RESET:
      return { group: {} }
    default:
      return state
  }
}

export const poolPlayersGroupUpdateReducer = (state = { group: {} }, action) => {
  switch (action.type) {
    case POOLPLAYERS_GROUP_UPDATE_REQUEST:
      return { loading: true }
    case POOLPLAYERS_GROUP_UPDATE_SUCCESS:
      return { loading: false, success: true, group: action.payload }
    case POOLPLAYERS_GROUP_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case POOLPLAYERS_GROUP_UPDATE_RESET:
      return { group: {} }
    default:
      return state
  }
}

export const poolPlayersGroupPlayerAddReducer = (state = {}, action) => {
  switch (action.type) {
    case POOLPLAYERS_ADD_PLAYER_TO_GROUP_REQUEST:
      return { loading: true }
    case POOLPLAYERS_ADD_PLAYER_TO_GROUP_SUCCESS:
      return { loading: false, success: true }
    case POOLPLAYERS_ADD_PLAYER_TO_GROUP_FAIL:
      return { loading: false, error: action.payload }
    case POOLPLAYERS_ADD_PLAYER_TO_GROUP_RESET:
      return {}
    default:
      return state
  }
}

export const poolPlayersGroupPlayerRemoveReducer = (state = { group: {} }, action) => {
  switch (action.type) {
    case POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_REQUEST:
      return { loading: true }
    case POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_SUCCESS:
      return { loading: false, success: true }
    case POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_FAIL:
      return { loading: false, error: action.payload }
    case POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_RESET:
      return { group: {} }
    default:
      return state
  }
}
