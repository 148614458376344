import React, { useEffect } from 'react'
import { Table, OverlayTrigger, Popover, Button, ListGroup, ListGroupItem } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { leaderboardPlayers } from '../actions/playerActions'
import { listDraftDetails } from '../actions/draftActions'

const PlayerLeaderboardScreen = ({ match }) => {
  const draftId = match.params.id
  const buyin = match.params.buyin
  let rank = 0

  const dispatch = useDispatch()

  const draftDetails = useSelector((state) => state.draftDetails)
  const { draft } = draftDetails

  const playersLeaderboard = useSelector((state) => state.playersLeaderboard)
  const { loading, error, players } = playersLeaderboard

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    dispatch(leaderboardPlayers(draftId, buyin))
    dispatch(listDraftDetails(draftId, buyin))
  }, [dispatch, draftId, buyin])

  return (
    <>
      <h1>
        {draft && draft.name} ${buyin} Stats
      </h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>RANK</th>
              <th>PLAYER</th>
              <th>PRICE</th>
              <th>OWN</th>
              <th>OWN%</th>
              {draft.category === 'Daily' && <td>CHIPS</td>}
              <th>SCORE</th>
            </tr>
          </thead>
          <tbody>
            {players
              .sort((a, b) =>
                draft.category === 'Daily'
                  ? a.points === b.points
                    ? a.chips > b.chips
                      ? -1
                      : 1
                    : a.points > b.points
                    ? -1
                    : 1
                  : a.points > b.points
                  ? -1
                  : 1
              )
              .map((player, x) => (
                <tr key={player._id}>
                  <td>{(rank = rank + 1)}</td>
                  <td>{player.name}</td>
                  <td>{player.price}</td>
                  <td>
                    <OverlayTrigger
                      delay={{ show: 250, hide: 400 }}
                      placement='auto'
                      overlay={
                        <Popover id='popover-teams'>
                          <Popover.Title as='h3'>Teams with {player.name}</Popover.Title>
                          <Popover.Content>
                            <ListGroup>
                              {player.teams &&
                                player.teams
                                  .sort((a, b) => a.userName.toLowerCase() > b.userName.toLowerCase())
                                  .map((item, i) => (
                                    <ListGroupItem key={i}>
                                      {item.userName !== 'Special Admin' ? item.userName : ''} {item.name}
                                    </ListGroupItem>
                                  ))}
                            </ListGroup>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <Button className='btn-block rounded btn-team-details'>{player.count}</Button>
                    </OverlayTrigger>
                  </td>
                  <td>{player.share ? player.share.toFixed(1) : 0}</td>
                  {draft.category === 'Daily' && (
                    <td>{player.chips.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
                  )}
                  <td>
                    <OverlayTrigger
                      delay={{ show: 250, hide: 400 }}
                      placement='auto'
                      overlay={
                        <Popover id='popover-score'>
                          <Popover.Title as='h3'>{player.name} Score Details</Popover.Title>
                          <Popover.Content>
                            <ListGroup>
                              {player.tournaments &&
                                player.tournaments
                                  .sort((a, b) => a.points < b.points)
                                  .map((item, i) => (
                                    <ListGroupItem key={i}>
                                      {item.name}, rank: {item.rank}, score: {item.points} pts
                                    </ListGroupItem>
                                  ))}
                            </ListGroup>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <Button className='btn-block rounded btn-details'>{player.points}</Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  )
}

export default PlayerLeaderboardScreen
