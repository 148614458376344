import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { listDraftDetails, updateDraft } from '../actions/draftActions'
import { listSeries } from '../actions/seriesActions'
import { listPoolsPlayers } from '../actions/poolPlayersActions'
import { listPoolsTourneys } from '../actions/poolTourneysActions'
import { DRAFT_UPDATE_RESET } from '../constants/draftConstants'

const DraftEditScreen = ({ match, history }) => {
  const draftId = match.params.id
  const buyin = match.params.buyin

  const [name, setName] = useState('')
  const [image, setImage] = useState('')
  const [series, setSeries] = useState('')
  const [buyinLow, setBuyinLow] = useState(0)
  const [rakeLow, setRakeLow] = useState(0)
  const [garrantyLow, setGarrantyLow] = useState(0)
  const [buyinHigh, setBuyinHigh] = useState(0)
  const [rakeHigh, setRakeHigh] = useState(0)
  const [garrantyHigh, setGarrantyHigh] = useState(0)
  const [playersLimit, setPlayersLimit] = useState(0)
  const [category, setCategory] = useState('')
  const [description, setDescription] = useState('')
  const [poolPlayersId, setPoolPlayersId] = useState('')
  const [poolTourneysId, setPoolTourneysId] = useState('')
  const [budget, setBudget] = useState(0)
  const [isPublic, setIsPublic] = useState(false)
  const [uploading, setUploading] = useState(false)

  const dispatch = useDispatch()

  const seriesList = useSelector((state) => state.seriesList)
  const { seriesArray } = seriesList

  const poolPlayersList = useSelector((state) => state.poolPlayersList)
  const { poolsPlayers } = poolPlayersList

  const poolTourneysList = useSelector((state) => state.poolTourneysList)
  const { poolsTourneys } = poolTourneysList

  const draftDetails = useSelector((state) => state.draftDetails)
  const { loading, error, draft } = draftDetails

  const draftUpdate = useSelector((state) => state.draftUpdate)
  const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = draftUpdate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }
    if (successUpdate) {
      dispatch({ type: DRAFT_UPDATE_RESET })
      dispatch(listDraftDetails(draftId))
      history.push('/admin/draftlist')
    } else {
      if (!draft || draft._id !== draftId) {
        dispatch(listDraftDetails(draftId, buyin))
      } else {
        setName(draft.name)
        setImage(draft.image)
        setSeries(draft.series._id)
        setBuyinLow(draft.buyinLow)
        setRakeLow(draft.rakeLow)
        setGarrantyLow(draft.garrantyLow)
        setBuyinHigh(draft.buyinHigh)
        setRakeHigh(draft.rakeHigh)
        setGarrantyHigh(draft.garrantyHigh)
        setPlayersLimit(draft.playersLimit)
        setCategory(draft.category)
        setDescription(draft.description)
        setPoolPlayersId(draft.poolPlayers._id)
        setPoolTourneysId(draft.poolTourneys._id)
        setBudget(draft.budget)
        setIsPublic(draft.isPublic)
      }
      dispatch(listSeries())
      dispatch(listPoolsPlayers())
      dispatch(listPoolsTourneys())
    }
  }, [dispatch, history, draft, draftId, successUpdate, userInfo, buyin])

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await axios.post('/api/upload', formData, config)

      setImage(data)
      setUploading(false)
    } catch (error) {
      console.log(error)
      setUploading(false)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateDraft({
        _id: draftId,
        name,
        image,
        series,
        buyinLow,
        rakeLow,
        garrantyLow,
        buyinHigh,
        rakeHigh,
        garrantyHigh,
        playersLimit,
        category,
        description,
        poolPlayersId,
        poolTourneysId,
        budget,
        isPublic,
      })
    )
  }

  return (
    <>
      <Link to='/admin/draftlist' className='btn btn-light my-3'>
        Go Back To Draft List
      </Link>
      <Row>
        <Col md={8}>
          <FormContainer>
            <h1>Edit Draft</h1>
            {loadingUpdate && <Loader />}
            {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              draft && (
                <Form onSubmit={submitHandler}>
                  <Form.Group controlId='name'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type='name'
                      placeholder='Enter name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='image'>
                    <Form.Label>Image</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter image URL'
                      value={image}
                      onChange={(e) => setImage(e.target.value)}
                    ></Form.Control>
                    <Form.File id='image-file' label='Choose File' custom onChange={uploadFileHandler}></Form.File>
                    {uploading && <Loader />}
                  </Form.Group>

                  <Form.Group controlId='buyinLow'>
                    <Form.Label>Buy-in Low</Form.Label>
                    <Form.Control
                      type='number'
                      placeholder='Enter low buy-in'
                      value={buyinLow}
                      onChange={(e) => setBuyinLow(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='buyinHigh'>
                    <Form.Label>Buy-in High</Form.Label>
                    <Form.Control
                      type='number'
                      placeholder='Enter high buy-in'
                      value={buyinHigh}
                      onChange={(e) => setBuyinHigh(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='rakeLow'>
                    <Form.Label>Rake Low</Form.Label>
                    <Form.Control
                      type='number'
                      placeholder='Enter low rake'
                      value={rakeLow}
                      onChange={(e) => setRakeLow(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='rakeHigh'>
                    <Form.Label>Rake High</Form.Label>
                    <Form.Control
                      type='number'
                      placeholder='Enter high rake'
                      value={rakeHigh}
                      onChange={(e) => setRakeHigh(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='garrantyLow'>
                    <Form.Label>Garranty Low</Form.Label>
                    <Form.Control
                      type='number'
                      placeholder='Enter low garranty'
                      value={garrantyLow}
                      onChange={(e) => setGarrantyLow(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='garrantyHigh'>
                    <Form.Label>Garranty High</Form.Label>
                    <Form.Control
                      type='number'
                      placeholder='Enter high garranty'
                      value={garrantyHigh}
                      onChange={(e) => setGarrantyHigh(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='budget'>
                    <Form.Label>Team budget</Form.Label>
                    <Form.Control
                      type='number'
                      placeholder='Enter budget'
                      value={budget}
                      onChange={(e) => setBudget(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='playersLimit'>
                    <Form.Label>Players limit</Form.Label>
                    <Form.Control
                      type='number'
                      placeholder='Enter players limit'
                      value={playersLimit}
                      onChange={(e) => setPlayersLimit(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='category'>
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      type='category'
                      placeholder='Enter category'
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='series'>
                    <Form.Label>Series</Form.Label>
                    <Form.Control as='select' value={series} onChange={(e) => setSeries(e.target.value)}>
                      <option value=''>Select...</option>
                      {seriesArray
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((x) => (
                          <option key={x._id} value={x._id}>
                            {x.name}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId='poolPlayersId'>
                    <Form.Label>Pool of Players</Form.Label>
                    <Form.Control as='select' value={poolPlayersId} onChange={(e) => setPoolPlayersId(e.target.value)}>
                      <option value=''>Select...</option>
                      {poolsPlayers
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((x) => (
                          <option key={x._id} value={x._id}>
                            {x.name}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId='poolTourneysId'>
                    <Form.Label>Pool of Tourneys</Form.Label>
                    <Form.Control
                      as='select'
                      value={poolTourneysId}
                      onChange={(e) => setPoolTourneysId(e.target.value)}
                    >
                      <option value=''>Select...</option>
                      {poolsTourneys
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((x) => (
                          <option key={x._id} value={x._id}>
                            {x.name}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId='isPublic'>
                    <Form.Check
                      type='checkbox'
                      label='Is Public'
                      checked={isPublic}
                      onChange={(e) => setIsPublic(e.target.checked)}
                    ></Form.Check>
                  </Form.Group>

                  <Form.Group controlId='description'>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      placeholder='Enter description'
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Button type='submit' variant='primary'>
                    Update Draft
                  </Button>
                </Form>
              )
            )}
          </FormContainer>
        </Col>
        <Col md={4}></Col>
      </Row>
    </>
  )
}
export default DraftEditScreen
