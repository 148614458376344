import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listTournaments, deleteTournament, createTournament } from '../actions/tournamentActions'
import { TOURNAMENT_CREATE_RESET } from '../constants/tournamentConstants'

const TournamentListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const tournamentList = useSelector((state) => state.tournamentList)
  const { loading, error, tournaments } = tournamentList

  const tournamentDelete = useSelector((state) => state.tournamentDelete)
  const { loading: loadingDelete, error: errorDelete, success: successDelete } = tournamentDelete

  const tournamentCreate = useSelector((state) => state.tournamentCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    tournament: createdTournament,
  } = tournamentCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    dispatch({ type: TOURNAMENT_CREATE_RESET })
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    } else {
      if (successCreate) {
        history.push(`/admin/tournament/${createdTournament._id}/edit`)
      } else {
        dispatch(listTournaments('all'))
      }
    }
  }, [dispatch, history, userInfo, successDelete, successCreate, createdTournament])

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteTournament(id))
    }
  }

  const createTournamentHandler = () => {
    dispatch(createTournament())
  }

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Tournaments</h1>
        </Col>
        <Col className='text-right'>
          <Button className='my-3' onClick={createTournamentHandler}>
            <i className='fas fa-plus'></i> Create Tournament
          </Button>
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>SERIES</th>
              <th>GAME</th>
              <th>NAME</th>
              <th>START</th>
              <th>BUY-IN</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tournaments
              .filter(({ series }) => series.isActive === true)
              .sort((a, b) =>
                a.series.name === b.series.name ? (a.name > b.name ? 1 : -1) : a.series.name > b.series.name ? 1 : -1
              )
              .map((tournament) => (
                <tr key={tournament._id}>
                  {tournament.series ? <td>{tournament.series.name}</td> : <td></td>}
                  <td>{tournament.pokerGame.name}</td>
                  <td>{tournament.name}</td>
                  <td>{tournament.startDate.substring(0, 10)}</td>
                  <td>{tournament.buyin}</td>
                  <td>
                    <LinkContainer to={`/admin/tournament/${tournament._id}/edit`}>
                      <Button variant='light' className='btn-sm'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </LinkContainer>
                    <LinkContainer to={`/admin/tournament/${tournament._id}/results`}>
                      <Button variant='light' className='btn-sm'>
                        <i className='fas fa-award'></i>
                      </Button>
                    </LinkContainer>
                    <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(tournament._id)}>
                      <i className='fas fa-trash'></i>
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  )
}

export default TournamentListScreen
