export const TEAM_LIST_REQUEST = 'TEAM_LIST_REQUEST'
export const TEAM_LIST_SUCCESS = 'TEAM_LIST_SUCCESS'
export const TEAM_LIST_FAIL = 'TEAM_LIST_FAIL'
export const TEAM_LIST_RESET = 'TEAM_LIST_RESET'

export const TEAM_DETAILS_REQUEST = 'TEAM_DETAILS_REQUEST'
export const TEAM_DETAILS_SUCCESS = 'TEAM_DETAILS_SUCCESS'
export const TEAM_DETAILS_FAIL = 'TEAM_DETAILS_FAIL'
export const TEAM_DETAILS_RESET = 'TEAM_DETAILS_RESET'

export const TEAM_DELETE_REQUEST = 'TEAM_DELETE_REQUEST'
export const TEAM_DELETE_SUCCESS = 'TEAM_DELETE_SUCCESS'
export const TEAM_DELETE_FAIL = 'TEAM_DELETE_FAIL'

export const TEAM_CREATE_REQUEST = 'TEAM_CREATE_REQUEST'
export const TEAM_CREATE_SUCCESS = 'TEAM_CREATE_SUCCESS'
export const TEAM_CREATE_FAIL = 'TEAM_CREATE_FAIL'
export const TEAM_CREATE_RESET = 'TEAM_CREATE_RESET'

export const TEAM_CREATE_BY_USER_REQUEST = 'TEAM_CREATE_BY_USER_REQUEST'
export const TEAM_CREATE_BY_USER_SUCCESS = 'TEAM_CREATE_BY_USER_SUCCESS'
export const TEAM_CREATE_BY_USER_FAIL = 'TEAM_CREATE_BY_USER_FAIL'
export const TEAM_CREATE_BY_USER_RESET = 'TEAM_CREATE_RESET'

export const TEAM_UPDATE_REQUEST = 'TEAM_UPDATE_REQUEST'
export const TEAM_UPDATE_SUCCESS = 'TEAM_UPDATE_SUCCESS'
export const TEAM_UPDATE_FAIL = 'TEAM_UPDATE_FAIL'
export const TEAM_UPDATE_RESET = 'TEAM_UPDATE_RESET'

export const TEAM_CREATE_RESULT_REQUEST = 'TEAM_CREATE_RESULT_REQUEST'
export const TEAM_CREATE_RESULT_SUCCESS = 'TEAM_CREATE_RESULT_SUCCESS'
export const TEAM_CREATE_RESULT_FAIL = 'TEAM_CREATE_RESULT_FAIL'
export const TEAM_CREATE_RESULT_RESET = 'TEAM_CREATE_RESULT_RESET'

export const TEAM_CAPTAIN_REQUEST = 'TEAM_CAPTAIN_REQUEST'
export const TEAM_CAPTAIN_SUCCESS = 'TEAM_CAPTAIN_SUCCESS'
export const TEAM_CAPTAIN_FAIL = 'TEAM_CAPTAIN_FAIL'
export const TEAM_CAPTAIN_RESET = 'TEAM_CAPTAIN_RESET'

export const TEAM_DELETE_RESULT_REQUEST = 'TEAM_DELETE_RESULT_REQUEST'
export const TEAM_DELETE_RESULT_SUCCESS = 'TEAM_DELETE_RESULT_SUCCESS'
export const TEAM_DELETE_RESULT_FAIL = 'TEAM_DELETE_RESULT_FAIL'
export const TEAM_DELETE_RESULT_RESET = 'TEAM_DELETE_RESULT_RESET'

export const TEAM_RESULT_DETAILS_REQUEST = 'TEAM_RESULT_DETAILS_REQUEST'
export const TEAM_RESULT_DETAILS_SUCCESS = 'TEAM_RESULT_DETAILS_SUCCESS'
export const TEAM_RESULT_DETAILS_FAIL = 'TEAM_RESULT_DETAILS_FAIL'
export const TEAM_RESULT_DETAILS_RESET = 'TEAM_RESULT_DETAILS_RESET'

export const TEAM_LEADERBOARD_REQUEST = 'TEAM_LEADERBOARD_REQUEST'
export const TEAM_LEADERBOARD_SUCCESS = 'TEAM_LEADERBOARD_SUCCESS'
export const TEAM_LEADERBOARD_FAIL = 'TEAM_LEADERBOARD_FAIL'
export const TEAM_LEADERBOARD_RESET = 'TEAM_LEADERBOARD_RESET'
