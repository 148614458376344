import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listTeams, deleteTeam, createTeam } from '../actions/teamActions'
import { TEAM_CREATE_RESET } from '../constants/teamConstants'
import { SERIES_DETAILS_RESET } from '../constants/seriesConstants'

const TeamListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const teamList = useSelector((state) => state.teamList)
  const { loading, error, teams } = teamList

  const teamDelete = useSelector((state) => state.teamDelete)
  const { loading: loadingDelete, error: errorDelete, success: successDelete } = teamDelete

  const teamCreate = useSelector((state) => state.teamCreate)
  const { loading: loadingCreate, error: errorCreate, success: successCreate, team: createdTeam } = teamCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    dispatch({ type: TEAM_CREATE_RESET })
    dispatch({ type: SERIES_DETAILS_RESET })
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    } else {
      if (successCreate) {
        history.push(`/admin/team/${createdTeam._id}/edit`)
      } else {
        dispatch(listTeams('all'))
      }
    }
  }, [dispatch, history, userInfo, successDelete, successCreate, createdTeam])

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteTeam(id))
    }
  }

  const createTeamHandler = () => {
    dispatch(createTeam())
  }

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Teams</h1>
        </Col>
        <Col className='text-right'>
          <Button className='my-3' onClick={createTeamHandler}>
            <i className='fas fa-plus'></i> Create Team
          </Button>
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>DRAFT</th>
              <th>BUYIN</th>
              <th>USER</th>
              <th>NAME</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {teams
              .filter(({ draft, user }) => draft.isPublic === true && user.name !== 'Special Admin')
              .sort((a, b) =>
                a.draft.name === b.draft.name
                  ? a.user.name === b.user.name
                    ? a.name > b.name
                      ? 1
                      : -1
                    : a.user.name > b.user.name
                    ? 1
                    : -1
                  : a.draft.name > b.draft.name
                  ? 1
                  : -1
              )
              .map((team) => (
                <tr key={team._id}>
                  <td>{team.draft.name}</td>
                  <td>{team.buyin}</td>
                  <td>{team.user.name}</td>
                  <td>{team.name}</td>
                  <td>
                    <LinkContainer to={`/admin/team/${team._id}/edit`}>
                      <Button variant='light' className='btn-sm'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </LinkContainer>
                    <LinkContainer to={`/admin/team/${team._id}/players`}>
                      <Button variant='light' className='btn-sm'>
                        <i className='fas fa-award'></i>
                      </Button>
                    </LinkContainer>
                    <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(team._id)}>
                      <i className='fas fa-trash'></i>
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  )
}

export default TeamListScreen
