import {
  TEAM_LIST_REQUEST,
  TEAM_LIST_SUCCESS,
  TEAM_LIST_FAIL,
  TEAM_LIST_RESET,
  TEAM_DETAILS_REQUEST,
  TEAM_DETAILS_SUCCESS,
  TEAM_DETAILS_FAIL,
  TEAM_DETAILS_RESET,
  TEAM_DELETE_FAIL,
  TEAM_DELETE_SUCCESS,
  TEAM_DELETE_REQUEST,
  TEAM_CREATE_REQUEST,
  TEAM_CREATE_SUCCESS,
  TEAM_CREATE_FAIL,
  TEAM_CREATE_RESET,
  TEAM_CREATE_BY_USER_REQUEST,
  TEAM_CREATE_BY_USER_SUCCESS,
  TEAM_CREATE_BY_USER_FAIL,
  TEAM_CREATE_BY_USER_RESET,
  TEAM_UPDATE_REQUEST,
  TEAM_UPDATE_SUCCESS,
  TEAM_UPDATE_FAIL,
  TEAM_UPDATE_RESET,
  TEAM_CREATE_RESULT_REQUEST,
  TEAM_CREATE_RESULT_SUCCESS,
  TEAM_CREATE_RESULT_FAIL,
  TEAM_CREATE_RESULT_RESET,
  TEAM_CAPTAIN_REQUEST,
  TEAM_CAPTAIN_SUCCESS,
  TEAM_CAPTAIN_FAIL,
  TEAM_CAPTAIN_RESET,
  TEAM_DELETE_RESULT_REQUEST,
  TEAM_DELETE_RESULT_SUCCESS,
  TEAM_DELETE_RESULT_FAIL,
  TEAM_DELETE_RESULT_RESET,
  TEAM_RESULT_DETAILS_REQUEST,
  TEAM_RESULT_DETAILS_SUCCESS,
  TEAM_RESULT_DETAILS_FAIL,
  TEAM_RESULT_DETAILS_RESET,
  TEAM_LEADERBOARD_REQUEST,
  TEAM_LEADERBOARD_SUCCESS,
  TEAM_LEADERBOARD_FAIL,
  TEAM_LEADERBOARD_RESET,
} from '../constants/teamConstants'

export const teamListReducer = (state = { teams: [] }, action) => {
  switch (action.type) {
    case TEAM_LIST_REQUEST:
      return { loading: true, teams: [] }
    case TEAM_LIST_SUCCESS:
      return {
        loading: false,
        teams: action.payload,
      }
    case TEAM_LIST_FAIL:
      return { loading: false, error: action.payload }
    case TEAM_LIST_RESET:
      return { teams: [] }
    default:
      return state
  }
}

export const teamDetailsReducer = (state = { team: {} }, action) => {
  switch (action.type) {
    case TEAM_DETAILS_REQUEST:
      return { loading: true, ...state }
    case TEAM_DETAILS_SUCCESS:
      return { loading: false, team: action.payload }
    case TEAM_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case TEAM_DETAILS_RESET:
      return { team: {} }
    default:
      return state
  }
}

export const teamDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_DELETE_REQUEST:
      return { loading: true }
    case TEAM_DELETE_SUCCESS:
      return { loading: false, success: true }
    case TEAM_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const teamCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_CREATE_REQUEST:
      return { loading: true }
    case TEAM_CREATE_SUCCESS:
      return { loading: false, success: true, team: action.payload }
    case TEAM_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case TEAM_CREATE_RESET:
      return { teamDetails: {} }
    default:
      return state
  }
}

export const teamCreateByUserReducer = (state = { team: {} }, action) => {
  switch (action.type) {
    case TEAM_CREATE_BY_USER_REQUEST:
      return { loading: true }
    case TEAM_CREATE_BY_USER_SUCCESS:
      return { loading: false, success: true, team: action.payload }
    case TEAM_CREATE_BY_USER_FAIL:
      return { loading: false, error: action.payload }
    case TEAM_CREATE_BY_USER_RESET:
      return { team: {} }
    default:
      return state
  }
}

export const teamUpdateReducer = (state = { team: {} }, action) => {
  switch (action.type) {
    case TEAM_UPDATE_REQUEST:
      return { loading: true }
    case TEAM_UPDATE_SUCCESS:
      return { loading: false, success: true, team: action.payload }
    case TEAM_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case TEAM_UPDATE_RESET:
      return { team: {} }
    default:
      return state
  }
}

export const teamPlayerCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_CREATE_RESULT_REQUEST:
      return { loading: true }
    case TEAM_CREATE_RESULT_SUCCESS:
      return { loading: false, success: true }
    case TEAM_CREATE_RESULT_FAIL:
      return { loading: false, error: action.payload }
    case TEAM_CREATE_RESULT_RESET:
      return {}
    default:
      return state
  }
}

export const teamCaptainSelectReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_CAPTAIN_REQUEST:
      return { loading: true }
    case TEAM_CAPTAIN_SUCCESS:
      return { loading: false, success: true }
    case TEAM_CAPTAIN_FAIL:
      return { loading: false, error: action.payload }
    case TEAM_CAPTAIN_RESET:
      return {}
    default:
      return state
  }
}

export const teamPlayerDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_DELETE_RESULT_REQUEST:
      return { loading: true }
    case TEAM_DELETE_RESULT_SUCCESS:
      return { loading: false, success: true }
    case TEAM_DELETE_RESULT_FAIL:
      return { loading: false, error: action.payload }
    case TEAM_DELETE_RESULT_RESET:
      return {}
    default:
      return state
  }
}

export const teamPlayerDetailsReducer = (state = { player: {} }, action) => {
  switch (action.type) {
    case TEAM_RESULT_DETAILS_REQUEST:
      return { loading: true, ...state }
    case TEAM_RESULT_DETAILS_SUCCESS:
      return { loading: false, player: action.payload }
    case TEAM_RESULT_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case TEAM_RESULT_DETAILS_RESET:
      return { player: {} }
    default:
      return state
  }
}

export const teamsLeaderboardReducer = (state = { teams: [] }, action) => {
  switch (action.type) {
    case TEAM_LEADERBOARD_REQUEST:
      return { loading: true, teams: [] }
    case TEAM_LEADERBOARD_SUCCESS:
      return {
        loading: false,
        teams: action.payload,
      }
    case TEAM_LEADERBOARD_FAIL:
      return { loading: false, error: action.payload }
    case TEAM_LEADERBOARD_RESET:
      return { teams: [] }
    default:
      return state
  }
}
