import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { listTeamDetails, updateTeam } from '../actions/teamActions'
import { listDrafts } from '../actions/draftActions'
import { listUsers } from '../actions/userActions'
import { TEAM_UPDATE_RESET } from '../constants/teamConstants'

const TeamEditScreen = ({ match, history }) => {
  const teamId = match.params.id

  const [draft, setDraft] = useState('')
  const [user, setUser] = useState('')
  const [name, setName] = useState('')
  const [buyin, setBuyin] = useState(0)

  const dispatch = useDispatch()

  const draftList = useSelector((state) => state.draftList)
  const { loading: loadingDrafts, error: errorDrafts, drafts } = draftList

  const userList = useSelector((state) => state.userList)
  const { loading: loadingUsers, error: errorUsers, users } = userList

  const teamDetails = useSelector((state) => state.teamDetails)
  const { loading, error, team } = teamDetails

  const teamUpdate = useSelector((state) => state.teamUpdate)
  const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = teamUpdate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }
    if (successUpdate) {
      dispatch({ type: TEAM_UPDATE_RESET })
      dispatch(listTeamDetails(teamId))
      history.push('/admin/teamlist')
    } else {
      if (!team.name || team._id !== teamId) {
        dispatch(listTeamDetails(teamId))
      } else {
        setDraft(team.draft._id)
        setUser(team.user._id)
        setName(team.name)
        setBuyin(team.buyin)
      }
      dispatch(listUsers())
      dispatch(listDrafts())
    }
  }, [dispatch, history, team, teamId, successUpdate, userInfo])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateTeam({
        _id: teamId,
        draft,
        user,
        name,
        buyin,
      })
    )
  }

  return (
    <>
      <Link to='/admin/teamlist' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Team</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            {loadingDrafts ? (
              <Loader />
            ) : errorDrafts ? (
              <Message variant='danger'>{errorDrafts}</Message>
            ) : (
              <Form.Group controlId='draft'>
                <Form.Label>Draft</Form.Label>
                <Form.Control as='select' value={draft} onChange={(e) => setDraft(e.target.value)}>
                  <option value=''>Select...</option>
                  {drafts
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map(
                      (x) =>
                        x.isPublic === true && (
                          <option key={x._id} value={x._id}>
                            {x.name}
                          </option>
                        )
                    )}
                </Form.Control>
              </Form.Group>
            )}
            {loadingUsers ? (
              <Loader />
            ) : errorUsers ? (
              <Message variant='danger'>{errorUsers}</Message>
            ) : (
              <Form.Group controlId='user'>
                <Form.Label>User</Form.Label>
                <Form.Control as='select' value={user} onChange={(e) => setUser(e.target.value)}>
                  <option value=''>Select...</option>
                  {users
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((y) => (
                      <option key={y._id} value={y._id}>
                        {y.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            )}

            <Form.Group controlId='buyin'>
              <Form.Label>Buyin</Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter name'
                value={buyin}
                onChange={(e) => setBuyin(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='name'
                placeholder='Enter name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button type='submit' variant='primary'>
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}
export default TeamEditScreen
