import {
  POKERGAME_LIST_REQUEST,
  POKERGAME_LIST_SUCCESS,
  POKERGAME_LIST_FAIL,
  POKERGAME_LIST_RESET,
  POKERGAME_DETAILS_REQUEST,
  POKERGAME_DETAILS_SUCCESS,
  POKERGAME_DETAILS_FAIL,
  POKERGAME_DELETE_FAIL,
  POKERGAME_DELETE_SUCCESS,
  POKERGAME_DELETE_REQUEST,
  POKERGAME_CREATE_REQUEST,
  POKERGAME_CREATE_SUCCESS,
  POKERGAME_CREATE_FAIL,
  POKERGAME_CREATE_RESET,
  POKERGAME_UPDATE_REQUEST,
  POKERGAME_UPDATE_SUCCESS,
  POKERGAME_UPDATE_FAIL,
  POKERGAME_UPDATE_RESET,
} from '../constants/pokerGameConstants'

export const pokerGameListReducer = (state = { pokerGames: [] }, action) => {
  switch (action.type) {
    case POKERGAME_LIST_REQUEST:
      return { loading: true, pokerGames: [] }
    case POKERGAME_LIST_SUCCESS:
      return {
        loading: false,
        pokerGames: action.payload,
      }
    case POKERGAME_LIST_FAIL:
      return { loading: false, error: action.payload }
    case POKERGAME_LIST_RESET:
      return { pokerGames: [] }
    default:
      return state
  }
}

export const pokerGameDetailsReducer = (state = { pokerGame: {} }, action) => {
  switch (action.type) {
    case POKERGAME_DETAILS_REQUEST:
      return { loading: true, ...state }
    case POKERGAME_DETAILS_SUCCESS:
      return { loading: false, pokerGame: action.payload }
    case POKERGAME_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const pokerGameDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case POKERGAME_DELETE_REQUEST:
      return { loading: true }
    case POKERGAME_DELETE_SUCCESS:
      return { loading: false, success: true }
    case POKERGAME_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const pokerGameCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case POKERGAME_CREATE_REQUEST:
      return { loading: true }
    case POKERGAME_CREATE_SUCCESS:
      return { loading: false, success: true, pokerGame: action.payload }
    case POKERGAME_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case POKERGAME_CREATE_RESET:
      return { pokerGameDetails: {} }
    default:
      return state
  }
}

export const pokerGameUpdateReducer = (state = { pokerGame: {} }, action) => {
  switch (action.type) {
    case POKERGAME_UPDATE_REQUEST:
      return { loading: true }
    case POKERGAME_UPDATE_SUCCESS:
      return { loading: false, success: true, pokerGame: action.payload }
    case POKERGAME_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case POKERGAME_UPDATE_RESET:
      return { pokerGame: {} }
    default:
      return state
  }
}
