import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { utcToZonedTime, format } from 'date-fns-tz'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listPoolsTourneys, deletePoolTourneys, createPoolTourneys } from '../actions/poolTourneysActions'
import { POOLTOURNEYS_CREATE_RESET, POOLTOURNEYS_DETAILS_RESET } from '../constants/poolTourneysConstants'
import { SERIES_DETAILS_RESET } from '../constants/seriesConstants'

const PoolTourneysListScreen = ({ history }) => {
  const myTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timePattern = 'HH:mm'
  const datePattern = 'yyyy-MM-dd'
  const dispatch = useDispatch()

  const poolTourneysList = useSelector((state) => state.poolTourneysList)
  const { loading, error, poolsTourneys } = poolTourneysList

  const poolTourneysDelete = useSelector((state) => state.poolTourneysDelete)
  const { loading: loadingDelete, error: errorDelete, success: successDelete } = poolTourneysDelete

  const poolTourneysCreate = useSelector((state) => state.poolTourneysCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    poolTourneys: createdPoolTourneys,
  } = poolTourneysCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    dispatch({ type: POOLTOURNEYS_CREATE_RESET })
    dispatch({ type: POOLTOURNEYS_DETAILS_RESET })
    dispatch({ type: SERIES_DETAILS_RESET })
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }
    if (successCreate) {
      history.push(`/admin/pooltourneys/${createdPoolTourneys._id}/edit`)
    } else {
      dispatch(listPoolsTourneys())
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdPoolTourneys,
    myTimeZone,
    datePattern,
    timePattern,
  ])

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deletePoolTourneys(id))
    }
  }

  const createPoolTourneysHandler = () => {
    dispatch(createPoolTourneys())
  }

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Pools of Tourneys</h1>
        </Col>
        <Col className='text-right'>
          <Button className='my-3' onClick={createPoolTourneysHandler}>
            <i className='fas fa-plus'></i> Create Pool
          </Button>
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>NAME</th>
                <th>SERIES</th>
                <th>ACTIVE</th>
                <th>START</th>
                <th>END</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {poolsTourneys
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((poolTourneys) => (
                  <tr key={poolTourneys._id}>
                    <td>{poolTourneys.name}</td>
                    {poolTourneys.series ? <td>{poolTourneys.series.name}</td> : <td></td>}
                    <td>
                      {poolTourneys.isActive ? (
                        <i className='fas fa-check' style={{ color: 'green' }}></i>
                      ) : (
                        <i className='fas fa-times' style={{ color: 'grey' }}></i>
                      )}
                    </td>
                    <td>
                      {' '}
                      {format(utcToZonedTime(poolTourneys.startDate, myTimeZone), datePattern)}{' '}
                      {format(utcToZonedTime(poolTourneys.startDate, myTimeZone), timePattern)}
                    </td>
                    <td>
                      {' '}
                      {format(utcToZonedTime(poolTourneys.endDate, myTimeZone), datePattern)}{' '}
                      {format(utcToZonedTime(poolTourneys.endDate, myTimeZone), timePattern)}
                    </td>
                    <td>
                      <LinkContainer to={`/admin/pooltourneys/${poolTourneys._id}/edit`}>
                        <Button variant='light' className='btn-sm'>
                          <i className='fas fa-edit'></i>
                        </Button>
                      </LinkContainer>
                      <LinkContainer to={`/admin/pooltourneys/${poolTourneys._id}/tournaments`}>
                        <Button variant='light' className='btn-sm'>
                          <i className='fas fa-th'></i>
                        </Button>
                      </LinkContainer>
                      <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(poolTourneys._id)}>
                        <i className='fas fa-trash'></i>
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  )
}

export default PoolTourneysListScreen
