import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Card } from 'react-bootstrap'
import Draft from '../components/Draft'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import Meta from '../components/Meta'
import { listDrafts } from '../actions/draftActions'
import { TEAM_CREATE_BY_USER_RESET } from '../constants/teamConstants'
import { SERIES_DETAILS_RESET } from '../constants/seriesConstants'
import { DRAFT_DETAILS_RESET } from '../constants/draftConstants'

const HomeScreen = ({ match }) => {
  const keyword = match.params.keyword

  const pageNumber = match.params.pageNumber || 1

  const dispatch = useDispatch()

  const draftList = useSelector((state) => state.draftList)
  const { loading, error, drafts, page, pages } = draftList

  useEffect(() => {
    dispatch({ type: SERIES_DETAILS_RESET })
    dispatch({ type: TEAM_CREATE_BY_USER_RESET })
    dispatch({ type: DRAFT_DETAILS_RESET })
    dispatch(listDrafts(keyword, pageNumber))
  }, [dispatch, keyword, pageNumber])

  return (
    <>
      <Meta />
      {keyword && (
        <Link to='/' className='btn btn-light'>
          Go Back
        </Link>
      )}
      <h1>Latest Drafts</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <div className='card-drafts-list'>
          {drafts
            .sort((a, b) => (a.startDate > b.startDate ? -1 : 1))
            .map(
              (draft) =>
                draft.isPublic && (
                  <Card className='my-3 p-3 rounded' key={draft._id}>
                    <Row>
                      <Draft draft={draft} />
                    </Row>
                  </Card>
                )
            )}
          <Paginate pages={pages} page={page} keyword={keyword ? keyword : ''} />
        </div>
      )}
    </>
  )
}

export default HomeScreen
