import {
  TOURNAMENT_LIST_REQUEST,
  TOURNAMENT_LIST_SUCCESS,
  TOURNAMENT_LIST_FAIL,
  TOURNAMENT_LIST_RESET,
  TOURNAMENT_DETAILS_REQUEST,
  TOURNAMENT_DETAILS_SUCCESS,
  TOURNAMENT_DETAILS_FAIL,
  TOURNAMENT_DETAILS_RESET,
  TOURNAMENT_DELETE_FAIL,
  TOURNAMENT_DELETE_SUCCESS,
  TOURNAMENT_DELETE_REQUEST,
  TOURNAMENT_CREATE_REQUEST,
  TOURNAMENT_CREATE_SUCCESS,
  TOURNAMENT_CREATE_FAIL,
  TOURNAMENT_CREATE_RESET,
  TOURNAMENT_UPDATE_REQUEST,
  TOURNAMENT_UPDATE_SUCCESS,
  TOURNAMENT_UPDATE_FAIL,
  TOURNAMENT_UPDATE_RESET,
  TOURNAMENT_CREATE_RESULT_REQUEST,
  TOURNAMENT_CREATE_RESULT_SUCCESS,
  TOURNAMENT_CREATE_RESULT_FAIL,
  TOURNAMENT_CREATE_RESULT_RESET,
  TOURNAMENT_DELETE_RESULT_REQUEST,
  TOURNAMENT_DELETE_RESULT_SUCCESS,
  TOURNAMENT_DELETE_RESULT_FAIL,
  TOURNAMENT_DELETE_RESULT_RESET,
  TOURNAMENT_RESULT_DETAILS_REQUEST,
  TOURNAMENT_RESULT_DETAILS_SUCCESS,
  TOURNAMENT_RESULT_DETAILS_FAIL,
  TOURNAMENT_RESULT_DETAILS_RESET,
  TOURNAMENT_RESULT_UPDATE_REQUEST,
  TOURNAMENT_RESULT_UPDATE_SUCCESS,
  TOURNAMENT_RESULT_UPDATE_FAIL,
  TOURNAMENT_RESULT_UPDATE_RESET,
} from '../constants/tournamentConstants'

export const tournamentListReducer = (state = { tournaments: [] }, action) => {
  switch (action.type) {
    case TOURNAMENT_LIST_REQUEST:
      return { loading: true, tournaments: [] }
    case TOURNAMENT_LIST_SUCCESS:
      return {
        loading: false,
        tournaments: action.payload,
      }
    case TOURNAMENT_LIST_FAIL:
      return { loading: false, error: action.payload }
    case TOURNAMENT_LIST_RESET:
      return { tournaments: [] }
    default:
      return state
  }
}

export const tournamentDetailsReducer = (
  state = { tournament: {} },
  action
) => {
  switch (action.type) {
    case TOURNAMENT_DETAILS_REQUEST:
      return { loading: true, ...state }
    case TOURNAMENT_DETAILS_SUCCESS:
      return { loading: false, tournament: action.payload }
    case TOURNAMENT_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case TOURNAMENT_DETAILS_RESET:
      return { tournament: {} }
    default:
      return state
  }
}

export const tournamentDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TOURNAMENT_DELETE_REQUEST:
      return { loading: true }
    case TOURNAMENT_DELETE_SUCCESS:
      return { loading: false, success: true }
    case TOURNAMENT_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const tournamentCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TOURNAMENT_CREATE_REQUEST:
      return { loading: true }
    case TOURNAMENT_CREATE_SUCCESS:
      return { loading: false, success: true, tournament: action.payload }
    case TOURNAMENT_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case TOURNAMENT_CREATE_RESET:
      return { tournamentDetails: {} }
    default:
      return state
  }
}

export const tournamentUpdateReducer = (state = { tournament: {} }, action) => {
  switch (action.type) {
    case TOURNAMENT_UPDATE_REQUEST:
      return { loading: true }
    case TOURNAMENT_UPDATE_SUCCESS:
      return { loading: false, success: true, tournament: action.payload }
    case TOURNAMENT_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case TOURNAMENT_UPDATE_RESET:
      return { tournament: {} }
    default:
      return state
  }
}

export const tournamentResultCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TOURNAMENT_CREATE_RESULT_REQUEST:
      return { loading: true }
    case TOURNAMENT_CREATE_RESULT_SUCCESS:
      return { loading: false, success: true }
    case TOURNAMENT_CREATE_RESULT_FAIL:
      return { loading: false, error: action.payload }
    case TOURNAMENT_CREATE_RESULT_RESET:
      return {}
    default:
      return state
  }
}

export const tournamentResultDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TOURNAMENT_DELETE_RESULT_REQUEST:
      return { loading: true }
    case TOURNAMENT_DELETE_RESULT_SUCCESS:
      return { loading: false, success: true }
    case TOURNAMENT_DELETE_RESULT_FAIL:
      return { loading: false, error: action.payload }
    case TOURNAMENT_DELETE_RESULT_RESET:
      return {}
    default:
      return state
  }
}

export const tournamentResultDetailsReducer = (
  state = { result: {} },
  action
) => {
  switch (action.type) {
    case TOURNAMENT_RESULT_DETAILS_REQUEST:
      return { loading: true, ...state }
    case TOURNAMENT_RESULT_DETAILS_SUCCESS:
      return { loading: false, result: action.payload }
    case TOURNAMENT_RESULT_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case TOURNAMENT_RESULT_DETAILS_RESET:
      return { result: {} }
    default:
      return state
  }
}

export const tournamentResultUpdateReducer = (
  state = { result: {} },
  action
) => {
  switch (action.type) {
    case TOURNAMENT_RESULT_UPDATE_REQUEST:
      return { loading: true }
    case TOURNAMENT_RESULT_UPDATE_SUCCESS:
      return { loading: false, success: true, result: action.payload }
    case TOURNAMENT_RESULT_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case TOURNAMENT_RESULT_UPDATE_RESET:
      return { result: {} }
    default:
      return state
  }
}
