import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import { listDrafts, deleteDraft, createDraft } from '../actions/draftActions'
import { DRAFT_CREATE_RESET } from '../constants/draftConstants'

const DraftListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1

  const dispatch = useDispatch()

  const draftList = useSelector((state) => state.draftList)
  const { loading, error, drafts, page, pages } = draftList

  const draftDelete = useSelector((state) => state.draftDelete)
  const { loading: loadingDelete, error: errorDelete, success: successDelete } = draftDelete

  const draftCreate = useSelector((state) => state.draftCreate)
  const { loading: loadingCreate, error: errorCreate, success: successCreate, draft: createdDraft } = draftCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    dispatch({ type: DRAFT_CREATE_RESET })
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }
    if (successCreate) {
      history.push(`/admin/draft/${createdDraft._id}/edit`)
    } else {
      dispatch(listDrafts('', pageNumber))
    }
  }, [dispatch, history, userInfo, successDelete, successCreate, createdDraft, pageNumber])

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteDraft(id))
    }
  }

  const createDraftHandler = () => {
    dispatch(createDraft())
  }

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Drafts</h1>
        </Col>
        <Col className='text-right'>
          <Button className='my-3' onClick={createDraftHandler}>
            <i className='fas fa-plus'></i> Create Draft
          </Button>
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>IMAGE</th>
                <th>NAME</th>
                <th>BUY-IN</th>
                <th>RAKE</th>
                <th>SERIES</th>
                <th>CATEGORY</th>
                <th>PUBLIC</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {drafts
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((draft) => (
                  <tr key={draft._id}>
                    <td>
                      <Image src={draft.image} alt={draft.name} width='100vw' fluid rounded />
                    </td>
                    <td>{draft.name}</td>
                    <td>
                      ${draft.buyinLow.toFixed(2)} / ${draft.buyinHigh.toFixed(2)}
                    </td>
                    <td>
                      ${draft.rakeLow.toFixed(2)} / ${draft.rakeHigh.toFixed(2)}
                    </td>
                    {draft.series ? <td>{draft.series.name}</td> : <td></td>}
                    <td>{draft.category}</td>
                    <td>
                      {draft.isPublic ? (
                        <i className='fas fa-check' style={{ color: 'green' }}></i>
                      ) : (
                        <i className='fas fa-times' style={{ color: 'grey' }}></i>
                      )}
                    </td>
                    <td>
                      <LinkContainer to={`/admin/draft/${draft._id}/${draft.buyinLow}/edit`}>
                        <Button variant='light' className='btn-sm'>
                          <i className='fas fa-edit'></i>
                        </Button>
                      </LinkContainer>
                      <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(draft._id)}>
                        <i className='fas fa-trash'></i>
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Paginate pages={pages} page={page} isAdmin={true} />
        </>
      )}
    </>
  )
}

export default DraftListScreen
