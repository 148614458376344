import axios from 'axios'

import {
  POKERGAME_LIST_REQUEST,
  POKERGAME_LIST_SUCCESS,
  POKERGAME_LIST_FAIL,
  POKERGAME_DETAILS_REQUEST,
  POKERGAME_DETAILS_SUCCESS,
  POKERGAME_DETAILS_FAIL,
  POKERGAME_DELETE_SUCCESS,
  POKERGAME_DELETE_FAIL,
  POKERGAME_DELETE_REQUEST,
  POKERGAME_CREATE_REQUEST,
  POKERGAME_CREATE_SUCCESS,
  POKERGAME_CREATE_FAIL,
  POKERGAME_UPDATE_REQUEST,
  POKERGAME_UPDATE_SUCCESS,
  POKERGAME_UPDATE_FAIL,
} from '../constants/pokerGameConstants'

export const listPokerGames = () => async (dispatch) => {
  try {
    dispatch({
      type: POKERGAME_LIST_REQUEST,
    })

    const { data } = await axios.get(`/api/pokergames`)

    dispatch({
      type: POKERGAME_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: POKERGAME_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listPokerGameDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: POKERGAME_DETAILS_REQUEST })

    const { data } = await axios.get(`/api/pokergames/${id}`)

    dispatch({
      type: POKERGAME_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: POKERGAME_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deletePokerGame = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: POKERGAME_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/pokergames/${id}`, config)

    dispatch({
      type: POKERGAME_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: POKERGAME_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createPokerGame = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: POKERGAME_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/pokergames`, {}, config)

    dispatch({
      type: POKERGAME_CREATE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: POKERGAME_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: POKERGAME_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updatePokerGame = (pokerGame) => async (dispatch, getState) => {
  try {
    dispatch({
      type: POKERGAME_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/pokergames/${pokerGame._id}`,
      pokerGame,
      config
    )

    dispatch({
      type: POKERGAME_UPDATE_SUCCESS,
    })
    dispatch({
      type: POKERGAME_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: POKERGAME_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
