import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Form, Button, Row, Col, ListGroup, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  poolPlayersGroupDetails,
  updatePoolPlayersGroup,
  listPoolPlayersDetails,
  addPlayerToGroup,
  removePlayerFromGroup,
} from '../actions/poolPlayersActions'
import { listPlayers } from '../actions/playerActions'
import { listSeriesDetails } from '../actions/seriesActions'
import {
  POOLPLAYERS_GROUP_UPDATE_RESET,
  POOLPLAYERS_ADD_PLAYER_TO_GROUP_RESET,
  POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_RESET,
} from '../constants/poolPlayersConstants'

const PoolPlayersGroupEditScreen = ({ match, history }) => {
  const poolPlayersId = match.params.id
  const groupId = match.params.groupid

  const [groupName, setGroupName] = useState('')
  const [groupLimit, setGroupLimit] = useState(0)
  const [isOnline, setIsOnline] = useState(false)
  const [brand, setBrand] = useState('')
  const [seriesId, setSeriesId] = useState('')
  const [poolPlayersName, setPoolPlayersName] = useState('')
  const [groupPlayers, setGroupPlayers] = useState([])

  const dispatch = useDispatch()

  const groupDetails = useSelector((state) => state.poolPlayersGroupDetails)
  const { loading, error, group } = groupDetails
  const { poolPlayersGroups } = group

  const groupUpdate = useSelector((state) => state.poolPlayersGroupUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = groupUpdate

  const seriesDetails = useSelector((state) => state.seriesDetails)
  const { series } = seriesDetails

  const playerList = useSelector((state) => state.playerList)
  const { loading: loadingPlayers, error: errorPlayers, players } = playerList

  const poolPlayersDetails = useSelector((state) => state.poolPlayersDetails)
  const { poolPlayers } = poolPlayersDetails

  const [player, setPlayer] = useState('')
  const [playerCost, setPlayerCost] = useState(0)

  const poolPlayersGroupPlayerAdd = useSelector(
    (state) => state.poolPlayersGroupPlayerAdd
  )
  const {
    success: successGroupPlayerAdd,
    error: errorGroupPlayerAdd,
  } = poolPlayersGroupPlayerAdd

  const poolPlayersGroupPlayerRemove = useSelector(
    (state) => state.poolPlayersGroupPlayerRemove
  )
  const {
    success: successGroupPlayerRemove,
    error: errorGroupPlayerRemove,
  } = poolPlayersGroupPlayerRemove

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    } else {
      if (!poolPlayersGroups || poolPlayersGroups[0]._id !== groupId) {
        dispatch(poolPlayersGroupDetails(poolPlayersId, groupId))
      } else {
        setGroupName(poolPlayersGroups[0].groupName)
        setGroupLimit(poolPlayersGroups[0].groupLimit)
        setGroupPlayers(poolPlayersGroups[0].groupPlayers)
      }
      if (successGroupPlayerAdd) {
        setPlayerCost(0)
        setPlayer('')
        dispatch({ type: POOLPLAYERS_ADD_PLAYER_TO_GROUP_RESET })
        dispatch(poolPlayersGroupDetails(poolPlayersId, groupId))
        dispatch(listPoolPlayersDetails(poolPlayersId))
      } else {
        if (successGroupPlayerRemove) {
          dispatch({ type: POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_RESET })
          dispatch(poolPlayersGroupDetails(poolPlayersId, groupId))
          dispatch(listPoolPlayersDetails(poolPlayersId))
        } else {
          if (successUpdate) {
            dispatch({ type: POOLPLAYERS_GROUP_UPDATE_RESET })
            dispatch(poolPlayersGroupDetails(poolPlayersId, groupId))
            dispatch(listPoolPlayersDetails(poolPlayersId))
            history.push(`/admin/poolplayers/${poolPlayersId}/edit`)
          } else {
            if (brand) {
              dispatch(listPlayers(brand))
            } else {
              if (series.brand) {
                setBrand(series.brand._id)
              } else {
                if (seriesId !== '') {
                  dispatch(listSeriesDetails(seriesId))
                } else {
                  if (!poolPlayers || poolPlayers._id !== poolPlayersId) {
                    dispatch(listPoolPlayersDetails(poolPlayersId))
                  } else {
                    setIsOnline(poolPlayers.series.isOnline)
                    setPoolPlayersName(poolPlayers.name)
                    setSeriesId(poolPlayers.series._id)
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [
    dispatch,
    history,
    poolPlayersGroups,
    poolPlayersId,
    groupId,
    successUpdate,
    userInfo,
    poolPlayers,
    brand,
    series,
    seriesId,
    successGroupPlayerAdd,
    successGroupPlayerRemove,
  ])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updatePoolPlayersGroup(poolPlayersId, groupId, {
        groupName,
        groupLimit,
      })
    )
  }

  const submitPlayerHandler = (e) => {
    e.preventDefault()
    dispatch(
      addPlayerToGroup(poolPlayersId, groupId, {
        player,
        playerCost,
      })
    )
  }

  const removePlayerHandler = (playerid) => {
    if (window.confirm('Are you sure?')) {
      dispatch(removePlayerFromGroup(poolPlayersId, groupId, playerid))
    }
  }

  return (
    <>
      <LinkContainer to={`/admin/poolplayers/${poolPlayersId}/edit`}>
        <Button variant='light'>Go Back to {poolPlayersName}</Button>
      </LinkContainer>
      <Row>
        <Col md={8}>
          <h1>Players</h1>
          {groupPlayers.length === 0 ? (
            <Message>No Players</Message>
          ) : (
            <Table striped bordered hover responsive className='table-sm'>
              <thead>
                <tr>
                  <th>PLAYER</th>
                  <th>COST</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {groupPlayers
                  .sort((a, b) =>
                    isOnline === true
                      ? a.player.nickname > b.player.nickname
                        ? 1
                        : -1
                      : a.player.name > b.player.name
                      ? 1
                      : -1
                  )
                  .map((playerItem) => (
                    <tr key={playerItem._id}>
                      <td>
                        <strong>
                          {isOnline === true
                            ? playerItem.player.nickname
                            : playerItem.player.name}
                        </strong>
                      </td>
                      <td>
                        <strong>{playerItem.playerCost}</strong>
                      </td>
                      <td>
                        {' '}
                        <Button
                          variant='danger'
                          className='btn-sm'
                          onClick={() => removePlayerHandler(playerItem._id)}
                        >
                          <i className='fas fa-trash'></i>
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
        </Col>
        <Col md={4}>
          <ListGroup>
            <ListGroup.Item>
              <h1>ADD PLAYERS</h1>
              {errorGroupPlayerAdd && (
                <Message variant='danger'>{errorGroupPlayerAdd}</Message>
              )}
              {errorGroupPlayerRemove && (
                <Message variant='danger'>{errorGroupPlayerRemove}</Message>
              )}
              {loadingPlayers ? (
                <Loader />
              ) : errorPlayers ? (
                <Message variant='danger'>{errorPlayers}</Message>
              ) : (
                <Form onSubmit={submitPlayerHandler}>
                  {isOnline === true ? (
                    <Form.Group controlId='player'>
                      <Form.Label>Player</Form.Label>
                      <Form.Control
                        as='select'
                        value={player}
                        onChange={(e) => setPlayer(e.target.value)}
                      >
                        <option value=''>Select...</option>
                        {players
                          .sort((a, b) => (a.nickname > b.nickname ? 1 : -1))
                          .map((player) => (
                            <option key={player._id} value={player._id}>
                              {player.nickname}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>
                  ) : (
                    <Form.Group controlId='player'>
                      <Form.Label>Player</Form.Label>
                      <Form.Control
                        as='select'
                        value={player}
                        onChange={(e) => setPlayer(e.target.value)}
                      >
                        <option value=''>Select...</option>
                        {players
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((player) => (
                            <option key={player._id} value={player._id}>
                              {player.name}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>
                  )}
                  <Form.Group controlId='playerCost'>
                    <Form.Label>Cost</Form.Label>
                    <Form.Control
                      type='number'
                      value={playerCost}
                      onChange={(e) => setPlayerCost(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Button type='submit' variant='primary'>
                    Add Player
                  </Button>
                </Form>
              )}
            </ListGroup.Item>
            <ListGroup.Item>
              <h1>Edit Group</h1>
              {loadingUpdate && <Loader />}
              {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
              {loading ? (
                <Loader />
              ) : error ? (
                <Message variant='danger'>{error}</Message>
              ) : (
                <Form onSubmit={submitHandler}>
                  <Form.Group controlId='groupName'>
                    <Form.Label>Group name</Form.Label>
                    <Form.Control
                      type='groupName'
                      placeholder='Enter group name'
                      value={groupName}
                      onChange={(e) => setGroupName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='groupLimit'>
                    <Form.Label>Limit</Form.Label>
                    <Form.Control
                      type='number'
                      placeholder='Enter group limit'
                      value={groupLimit}
                      onChange={(e) => setGroupLimit(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Button type='submit' variant='primary'>
                    Update Group
                  </Button>
                </Form>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </>
  )
}
export default PoolPlayersGroupEditScreen
