export const DRAFT_LIST_REQUEST = 'DRAFT_LIST_REQUEST'
export const DRAFT_LIST_SUCCESS = 'DRAFT_LIST_SUCCESS'
export const DRAFT_LIST_FAIL = 'DRAFT_LIST_FAIL'

export const DRAFT_DETAILS_REQUEST = 'DRAFT_DETAILS_REQUEST'
export const DRAFT_DETAILS_SUCCESS = 'DRAFT_DETAILS_SUCCESS'
export const DRAFT_DETAILS_FAIL = 'DRAFT_DETAILS_FAIL'
export const DRAFT_DETAILS_RESET = 'DRAFT_DETAILS_RESET'

export const DRAFT_DELETE_REQUEST = 'DRAFT_DELETE_REQUEST'
export const DRAFT_DELETE_SUCCESS = 'DRAFT_DELETE_SUCCESS'
export const DRAFT_DELETE_FAIL = 'DRAFT_DELETE_FAIL'

export const DRAFT_CREATE_REQUEST = 'DRAFT_CREATE_REQUEST'
export const DRAFT_CREATE_SUCCESS = 'DRAFT_CREATE_SUCCESS'
export const DRAFT_CREATE_FAIL = 'DRAFT_CREATE_FAIL'
export const DRAFT_CREATE_RESET = 'DRAFT_CREATE_RESET'

export const DRAFT_UPDATE_REQUEST = 'DRAFT_UPDATE_REQUEST'
export const DRAFT_UPDATE_SUCCESS = 'DRAFT_UPDATE_SUCCESS'
export const DRAFT_UPDATE_FAIL = 'DRAFT_UPDATE_FAIL'
export const DRAFT_UPDATE_RESET = 'DRAFT_UPDATE_RESET'
