import React, { useEffect } from 'react'
import { Table, OverlayTrigger, Popover, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { leaderboardTeams } from '../actions/teamActions'
import { listDraftDetails } from '../actions/draftActions'

const TeamLeaderboardScreen = ({ match }) => {
  const draftId = match.params.id
  const buyin = match.params.buyin

  const dispatch = useDispatch()

  const draftDetails = useSelector((state) => state.draftDetails)
  const { draft } = draftDetails

  const teamsLeaderboard = useSelector((state) => state.teamsLeaderboard)
  const { loading, error, teams } = teamsLeaderboard

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    dispatch(leaderboardTeams(draftId, buyin))
    dispatch(listDraftDetails(draftId, buyin))
  }, [dispatch, draftId, buyin])

  return (
    <>
      <h1>
        {draft && draft.name} ${buyin} Leaderboard
      </h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>RANK</th>
              <th>TEAM</th>
              {draft.category === 'Daily' && <th>CHIPS</th>}
              {draft.category === 'Daily' && <th>REMAINING</th>}
              <th>POINTS</th>
            </tr>
          </thead>
          <tbody>
            {teams
              .sort((a, b) =>
                draft.category === 'Daily'
                  ? a.score === b.score
                    ? a.remaining === b.remaining
                      ? a.totalChips > b.totalChips
                        ? -1
                        : 1
                      : a.remaining > b.remaining
                      ? -1
                      : 1
                    : a.score > b.score
                    ? -1
                    : 1
                  : a.score > b.score
                  ? -1
                  : 1
              )
              .map((team, x) => (
                <tr key={team._id}>
                  <td>{x + 1}</td>
                  <td>
                    {team.user !== 'Special Admin' ? team.user : ''} {team.name}
                  </td>
                  {draft.category === 'Daily' && (
                    <td>
                      <OverlayTrigger
                        delay={{ show: 250, hide: 400 }}
                        placement='auto'
                        overlay={
                          <Popover id='popover-basic'>
                            <Popover.Title as='h3'>
                              {team.user !== 'Special Admin' ? team.user : ''} {team.name} Roster
                            </Popover.Title>
                            <Popover.Content>
                              <div>
                                {team.roster &&
                                  team.roster
                                    .sort((a, b) =>
                                      a.chips === b.chips ? (a.name > b.name ? 1 : -1) : a.chips > b.chips ? -1 : 1
                                    )
                                    .map((item, i) => (
                                      <Row key={i}>
                                        <Col>
                                          {item.name} {item.isCaptain === true ? '(C)' : ''}
                                          {item.isViceCaptain === true ? '(VC)' : ''} (${item.price}): {item.chips}{' '}
                                          chips
                                        </Col>
                                      </Row>
                                    ))}
                              </div>
                              <div>=============</div>
                              <div>Team Value: ${team.roster.reduce((acc, item) => item.price + acc, 0)}</div>
                            </Popover.Content>
                          </Popover>
                        }
                      >
                        <Button className='btn-block rounded btn-details'>{team.totalChips}</Button>
                      </OverlayTrigger>
                    </td>
                  )}
                  {draft.category === 'Daily' && <td>{team.remaining}</td>}
                  <td>
                    <OverlayTrigger
                      delay={{ show: 250, hide: 400 }}
                      placement='auto'
                      overlay={
                        <Popover id='popover-basic'>
                          <Popover.Title as='h3'>
                            {team.user !== 'Special Admin' ? team.user : ''} {team.name} Roster
                          </Popover.Title>
                          <Popover.Content>
                            <div>
                              {team.roster &&
                                team.roster
                                  .sort((a, b) =>
                                    a.points === b.points ? (a.name > b.name ? 1 : -1) : a.points > b.points ? -1 : 1
                                  )
                                  .map((item, i) => (
                                    <Row key={i}>
                                      <Col>
                                        {item.name} {item.isCaptain === true ? '(C)' : ''}
                                        {item.isViceCaptain === true ? '(VC)' : ''} (${item.price}): {item.points} pts
                                      </Col>
                                    </Row>
                                  ))}
                            </div>
                            <div>=============</div>
                            <div>Team Value: ${team.roster.reduce((acc, item) => item.price + acc, 0)}</div>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <Button className='btn-block rounded btn-details'>{team.score.toFixed(2)}</Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  )
}

export default TeamLeaderboardScreen
