import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Image, Col, Row, Button, OverlayTrigger, Popover } from 'react-bootstrap'
import { utcToZonedTime, format } from 'date-fns-tz'

const Draft = ({ draft }) => {
  const myTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timePattern = 'HH:mm'
  const datePattern = 'MMM d'

  return (
    <>
      <Col sm={5} md={4} lg={3} xl={2} className='draft-list-column'>
        <Image src={draft.image} alt={draft.name} fluid rounded />
      </Col>
      <Col sm={6} md={5} lg={4} xl={3} className='draft-list-column'>
        <h4>{draft.name}</h4>
        <h5>{draft.series.name}</h5>
      </Col>
      <Col sm={5} md={4} lg={3} xl={2} className='draft-list-column'>
        <div className='start-date'>
          {format(utcToZonedTime(draft.startDate, myTimeZone), datePattern)}
          {', '}
          {format(utcToZonedTime(draft.startDate, myTimeZone), timePattern)}
        </div>
        <OverlayTrigger
          trigger='click'
          rootClose
          delay={{ show: 250, hide: 250 }}
          placement='auto'
          overlay={
            <Popover id='popover-basic'>
              <Popover.Title as='h4'>{draft.name}</Popover.Title>
              <Popover.Content>{draft.description}</Popover.Content>
            </Popover>
          }
        >
          <Button className='btn-block rounded btn-details'>Details</Button>
        </OverlayTrigger>
      </Col>
      <Col sm={7} md={6} lg={5} xl={4} className='draft-list-column'>
        <Row>
          <Col>
            <div className='buyins-block'>
              <h4>
                <i className='fas fa-trophy'></i> $
                {(draft.teamsCountLow * draft.buyinLow - draft.teamsCountLow * draft.rakeLow).toFixed(2)}
              </h4>
              <LinkContainer to={`/draft/${draft._id}/${draft.buyinLow}`}>
                <Button className='btn-block rounded btn-play'>Play ${draft.buyinLow.toFixed(2)}</Button>
              </LinkContainer>
              <h5>{draft.teamsCountLow} Teams</h5>
            </div>
          </Col>
          {draft.buyinHigh !== 0 && (
            <Col>
              <div className='buyins-block'>
                <h4>
                  <i className='fas fa-trophy'></i> $
                  {(draft.teamsCountHigh * draft.buyinHigh - draft.teamsCountHigh * draft.rakeHigh).toFixed(2)}
                </h4>
                <LinkContainer to={`/draft/${draft._id}/${draft.buyinHigh}`}>
                  <Button className='btn-block rounded btn-play'>Play ${draft.buyinHigh.toFixed(2)}</Button>
                </LinkContainer>
                <h5>{draft.teamsCountHigh} Teams</h5>
              </div>
            </Col>
          )}
        </Row>
      </Col>
    </>
  )
}

export default Draft
