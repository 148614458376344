import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import {
  tournamentResultDetails,
  updateTournamentResult,
  listTournamentDetails,
} from '../actions/tournamentActions'
import { listPlayers } from '../actions/playerActions'
import { TOURNAMENT_RESULT_UPDATE_RESET } from '../constants/tournamentConstants'

const TournamentResultEditScreen = ({ match, history }) => {
  const tournamentId = match.params.id
  const resultId = match.params.resultid

  const [player, setPlayer] = useState('')
  const [chips, setChips] = useState(0)
  const [rank, setRank] = useState(0)
  const [points, setPoints] = useState(0)
  const [prize, setPrize] = useState(0)

  const dispatch = useDispatch()

  const resultDetails = useSelector((state) => state.tournamentResultDetails)
  const { loading, error, result } = resultDetails

  const resultUpdate = useSelector((state) => state.tournamentResultUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = resultUpdate

  const playerList = useSelector((state) => state.playerList)
  const { loading: loadingPlayers, error: errorPlayers, players } = playerList

  const tournamentDetails = useSelector((state) => state.tournamentDetails)
  const { tournament } = tournamentDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    } else {
      if (successUpdate) {
        dispatch({ type: TOURNAMENT_RESULT_UPDATE_RESET })
        dispatch(listTournamentDetails(tournamentId))
        history.push(`/admin/tournament/${tournamentId}/results`)
      } else {
        if (!tournament.series || tournament._id !== tournamentId) {
          dispatch(listTournamentDetails(tournamentId))
        } else {
          if (!result.results || result.results[0]._id !== resultId) {
            dispatch(tournamentResultDetails(tournamentId, resultId))
          } else {
            setPlayer(result.results[0].player._id)
            setChips(result.results[0].chips)
            setRank(result.results[0].rank)
            setPoints(result.results[0].points)
            setPrize(result.results[0].prize)
            dispatch(listPlayers(tournament.series.brand))
          }
        }
      }
    }
  }, [
    dispatch,
    history,
    tournamentId,
    resultId,
    result,
    successUpdate,
    userInfo,
    tournament,
  ])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateTournamentResult(tournamentId, resultId, {
        player,
        chips,
        rank,
        points,
        prize,
      })
    )
  }

  return (
    <>
      <LinkContainer to={`/admin/tournament/${tournamentId}/results`}>
        <Button variant='light'>Go Back to {tournament.name} results</Button>
      </LinkContainer>
      <FormContainer>
        <h1>Edit Result</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            {loadingPlayers && <Loader />}
            {errorPlayers && <Message variant='danger'>{errorPlayers}</Message>}
            <Form.Group controlId='player'>
              <Form.Label>Player</Form.Label>
              <Form.Control
                as='select'
                value={player}
                onChange={(e) => setPlayer(e.target.value)}
              >
                <option value=''>Select...</option>
                {players
                  .sort((a, b) =>
                    tournament.series.isOnline === true
                      ? a.nickname > b.nickname
                        ? 1
                        : -1
                      : a.name > b.name
                      ? 1
                      : -1
                  )
                  .map((x) => (
                    <option key={x._id} value={x._id}>
                      {tournament.series.isOnline === true
                        ? x.nickname
                        : x.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId='rank'>
              <Form.Label>Rank</Form.Label>
              <Form.Control
                type='number'
                value={rank}
                onChange={(e) => setRank(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='chips'>
              <Form.Label>Chips</Form.Label>
              <Form.Control
                type='number'
                value={chips}
                onChange={(e) => setChips(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='points'>
              <Form.Label>Points</Form.Label>
              <Form.Control
                type='number'
                value={points}
                onChange={(e) => setPoints(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='prize'>
              <Form.Label>Prize</Form.Label>
              <Form.Control
                type='number'
                value={prize}
                onChange={(e) => setPrize(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button type='submit' variant='primary'>
              Update Result
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}
export default TournamentResultEditScreen
