import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { createTeamPlayer, deleteTeamPlayer, listTeamDetails, selectCaptain } from '../actions/teamActions'
import { listPoolPlayersDetails } from '../actions/poolPlayersActions'
import { listSeriesDetails } from '../actions/seriesActions'
import { TEAM_CREATE_RESULT_RESET, TEAM_DELETE_RESULT_RESET, TEAM_CAPTAIN_RESET } from '../constants/teamConstants'

const TeamPlayerListScreen = ({ match, history }) => {
  const teamId = match.params.id

  const [player, setPlayer] = useState('')
  const [captain, setCaptain] = useState('')
  const [viceCaptain, setViceCaptain] = useState('')

  const dispatch = useDispatch()

  const teamDetails = useSelector((state) => state.teamDetails)
  const { loading, error, team } = teamDetails

  const poolPlayersDetails = useSelector((state) => state.poolPlayersDetails)
  const { loading: loadingPlayers, error: errorPlayers, poolPlayers } = poolPlayersDetails

  const seriesDetails = useSelector((state) => state.seriesDetails)
  const { series } = seriesDetails

  const teamPlayerCreate = useSelector((state) => state.teamPlayerCreate)
  const { success: successCreate, loading: loadingCreate, error: errorCreate } = teamPlayerCreate

  const teamCaptainSelect = useSelector((state) => state.teamCaptainSelect)
  const { success: successSelectCaptain, loading: loadingSelectCaptain, error: errorCreateCaptain } = teamCaptainSelect

  const teamPlayerDelete = useSelector((state) => state.teamPlayerDelete)
  const { success: successDelete, loading: loadingDelete, error: errorDelete } = teamPlayerDelete

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    } else {
      if (successCreate) {
        dispatch({ type: TEAM_CREATE_RESULT_RESET })
        dispatch(listTeamDetails(teamId))
        setPlayer('')
      } else {
        if (successDelete) {
          dispatch({ type: TEAM_DELETE_RESULT_RESET })
          dispatch(listTeamDetails(teamId))
        } else {
          if (!team.name || team._id !== teamId) {
            dispatch(listTeamDetails(teamId))
          } else {
            if (series.brand) {
              dispatch(listPoolPlayersDetails(team.draft.poolPlayers))
              // console.log(poolPlayers)
            } else {
              dispatch(listSeriesDetails(team.draft.series))
            }
          }
        }
      }
      if (successSelectCaptain) {
        dispatch({ type: TEAM_CAPTAIN_RESET })
        dispatch(listTeamDetails(teamId))
        setPlayer('')
      }
    }
  }, [
    dispatch,
    history,
    teamId,
    team,
    series,
    userInfo,
    successCreate,
    successDelete,
    successSelectCaptain,
    // poolPlayers,
  ])

  const submitPlayerHandler = (e) => {
    e.preventDefault()
    dispatch(
      createTeamPlayer(teamId, {
        player,
      })
    )
  }

  const submitCaptainHandler = (e) => {
    e.preventDefault()
    dispatch(
      selectCaptain(teamId, {
        captain,
        viceCaptain,
      })
    )
  }

  const deletePlayerHandler = (playerId) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteTeamPlayer(teamId, playerId))
    }
  }

  return (
    <>
      <Link to='/admin/teamlist' className='btn btn-light my-3'>
        Go Back To Team List
      </Link>
      <Row>
        <Col md={8}>
          <Row className='align-items-center'>
            <Col>
              {team && team.user && team.user.name && (
                <h1>
                  {team.user.name} {team.name} Roster
                </h1>
              )}
            </Col>
          </Row>
          {loadingDelete && <Loader />}
          {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
          {loadingCreate && <Loader />}
          {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <Table striped bordered hover responsive className='table-sm'>
              <thead>
                <tr>
                  <th>PLAYER</th>
                  <th>CAPTAINS</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {team &&
                  team.players &&
                  team.players.length !== 0 &&
                  team.players
                    .sort((a, b) =>
                      series.isOnline === true
                        ? a.player.nickname > b.player.nickname
                          ? 1
                          : -1
                        : a.player.name > b.player.name
                        ? 1
                        : -1
                    )
                    .map((player) => (
                      <tr key={player._id}>
                        <td>
                          {series.isOnline === true
                            ? `${player.player.nickname} (${player.player.name})`
                            : player.player.name}
                        </td>
                        <td>
                          {player.isCaptain === true ? `Captain` : player.isViceCaptain === true && `Vice captain`}
                        </td>
                        <td>
                          <Button variant='danger' className='btn-sm' onClick={() => deletePlayerHandler(player._id)}>
                            <i className='fas fa-trash'></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>
          )}
        </Col>
        <Col md={4}>
          <Form onSubmit={submitPlayerHandler}>
            {loadingPlayers ? (
              <Loader />
            ) : errorPlayers ? (
              <Message variant='danger'>{errorPlayers}</Message>
            ) : (
              <Form.Group controlId='player'>
                <Form.Label>Player</Form.Label>
                <Form.Control as='select' value={player} onChange={(e) => setPlayer(e.target.value)}>
                  <option value=''>Select...</option>
                  {poolPlayers.poolPlayersGroups &&
                    poolPlayers.poolPlayersGroups.length !== 0 &&
                    poolPlayers.poolPlayersGroups.map(
                      (x) =>
                        x.groupPlayers &&
                        x.groupPlayers.length !== 0 &&
                        x.groupPlayers
                          .sort((a, b) =>
                            series.isOnline === true
                              ? a.player.nickname > b.player.nickname
                                ? 1
                                : -1
                              : a.player.name > b.player.name
                              ? 1
                              : -1
                          )
                          .map((y) => (
                            <option key={y.player._id} value={y.player._id}>
                              {series.isOnline === true ? `${y.player.nickname} (${y.player.name})` : y.player.name}
                            </option>
                          ))
                    )}
                </Form.Control>
              </Form.Group>
            )}
            <Button type='submit' variant='primary'>
              Add Player
            </Button>
          </Form>
          <p></p>
          <Form onSubmit={submitCaptainHandler}>
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <Form.Group controlId='captain'>
                <Form.Label>Captain</Form.Label>
                <Form.Control as='select' value={captain} onChange={(e) => setCaptain(e.target.value)}>
                  <option value=''>Select...</option>
                  {team &&
                    team.players &&
                    team.players.length !== 0 &&
                    team.players
                      .sort((a, b) =>
                        series.isOnline === true
                          ? a.player.nickname > b.player.nickname
                            ? 1
                            : -1
                          : a.player.name > b.player.name
                          ? 1
                          : -1
                      )
                      .map(
                        (player) =>
                          player._id !== viceCaptain && (
                            <option key={player._id} value={player._id}>
                              {series.isOnline === true
                                ? `${player.player.nickname} (${player.player.name})`
                                : player.player.name}
                            </option>
                          )
                      )}
                </Form.Control>
              </Form.Group>
            )}
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <Form.Group controlId='viceCaptain'>
                <Form.Label>Vice Captain</Form.Label>
                <Form.Control as='select' value={viceCaptain} onChange={(e) => setViceCaptain(e.target.value)}>
                  <option value=''>Select...</option>
                  {team &&
                    team.players &&
                    team.players.length !== 0 &&
                    team.players
                      .sort((a, b) =>
                        series.isOnline === true
                          ? a.player.nickname > b.player.nickname
                            ? 1
                            : -1
                          : a.player.name > b.player.name
                          ? 1
                          : -1
                      )
                      .map(
                        (player) =>
                          player._id !== captain && (
                            <option key={player._id} value={player._id}>
                              {series.isOnline === true
                                ? `${player.player.nickname} (${player.player.name})`
                                : player.player.name}
                            </option>
                          )
                      )}
                </Form.Control>
              </Form.Group>
            )}
            <Button type='submit' variant='primary'>
              Submit Captains
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  )
}
export default TeamPlayerListScreen
