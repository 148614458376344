import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listBrands, deleteBrand, createBrand } from '../actions/brandActions'
import { BRAND_CREATE_RESET } from '../constants/brandConstants'

const BrandListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const brandList = useSelector((state) => state.brandList)
  const { loading, error, brands } = brandList

  const brandDelete = useSelector((state) => state.brandDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = brandDelete

  const brandCreate = useSelector((state) => state.brandCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    brand: createdBrand,
  } = brandCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    dispatch({ type: BRAND_CREATE_RESET })
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }
    if (successCreate) {
      history.push(`/admin/brand/${createdBrand._id}/edit`)
    } else {
      dispatch(listBrands())
    }
  }, [dispatch, history, userInfo, successDelete, successCreate, createdBrand])

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteBrand(id))
    }
  }

  const createBrandHandler = () => {
    dispatch(createBrand())
  }

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Brands</h1>
        </Col>
        <Col className='text-right'>
          <Button className='my-3' onClick={createBrandHandler}>
            <i className='fas fa-plus'></i> Create Brand
          </Button>
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>LOGO</th>
              <th>NAME</th>
              <th>DESCRIPTION</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {brands
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((brand) => (
                <tr key={brand._id}>
                  <td>
                    <Image
                      src={brand.image}
                      alt={brand.name}
                      width='100vw'
                      fluid
                      rounded
                    />
                  </td>
                  <td>{brand.name}</td>
                  <td>{brand.description}</td>
                  <td>
                    <LinkContainer to={`/admin/brand/${brand._id}/edit`}>
                      <Button variant='light' className='btn-sm'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </LinkContainer>
                    <Button
                      variant='danger'
                      className='btn-sm'
                      onClick={() => deleteHandler(brand._id)}
                    >
                      <i className='fas fa-trash'></i>
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  )
}

export default BrandListScreen
