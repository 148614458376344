import axios from 'axios'

import {
  POOLPLAYERS_LIST_REQUEST,
  POOLPLAYERS_LIST_SUCCESS,
  POOLPLAYERS_LIST_FAIL,
  POOLPLAYERS_DETAILS_REQUEST,
  POOLPLAYERS_DETAILS_SUCCESS,
  POOLPLAYERS_DETAILS_FAIL,
  POOLPLAYERS_DELETE_SUCCESS,
  POOLPLAYERS_DELETE_FAIL,
  POOLPLAYERS_DELETE_REQUEST,
  POOLPLAYERS_CREATE_REQUEST,
  POOLPLAYERS_CREATE_SUCCESS,
  POOLPLAYERS_CREATE_FAIL,
  POOLPLAYERS_UPDATE_REQUEST,
  POOLPLAYERS_UPDATE_SUCCESS,
  POOLPLAYERS_UPDATE_FAIL,
  POOLPLAYERS_CREATE_GROUP_REQUEST,
  POOLPLAYERS_CREATE_GROUP_SUCCESS,
  POOLPLAYERS_CREATE_GROUP_FAIL,
  POOLPLAYERS_DELETE_GROUP_REQUEST,
  POOLPLAYERS_DELETE_GROUP_SUCCESS,
  POOLPLAYERS_DELETE_GROUP_FAIL,
  POOLPLAYERS_GROUP_DETAILS_REQUEST,
  POOLPLAYERS_GROUP_DETAILS_SUCCESS,
  POOLPLAYERS_GROUP_DETAILS_FAIL,
  POOLPLAYERS_GROUP_UPDATE_REQUEST,
  POOLPLAYERS_GROUP_UPDATE_SUCCESS,
  POOLPLAYERS_GROUP_UPDATE_FAIL,
  POOLPLAYERS_ADD_PLAYER_TO_GROUP_REQUEST,
  POOLPLAYERS_ADD_PLAYER_TO_GROUP_SUCCESS,
  POOLPLAYERS_ADD_PLAYER_TO_GROUP_FAIL,
  POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_REQUEST,
  POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_SUCCESS,
  POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_FAIL,
} from '../constants/poolPlayersConstants'

export const listPoolsPlayers = () => async (dispatch) => {
  try {
    dispatch({ type: POOLPLAYERS_LIST_REQUEST })

    const { data } = await axios.get(`/api/poolsplayers`)

    dispatch({
      type: POOLPLAYERS_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: POOLPLAYERS_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const listPoolPlayersDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: POOLPLAYERS_DETAILS_REQUEST })

    const { data } = await axios.get(`/api/poolsplayers/${id}`)

    dispatch({
      type: POOLPLAYERS_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: POOLPLAYERS_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const deletePoolPlayers = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: POOLPLAYERS_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/poolsplayers/${id}`, config)

    dispatch({
      type: POOLPLAYERS_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: POOLPLAYERS_DELETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const createPoolPlayers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: POOLPLAYERS_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/poolsplayers`, {}, config)

    dispatch({
      type: POOLPLAYERS_CREATE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: POOLPLAYERS_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: POOLPLAYERS_CREATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const updatePoolPlayers = (poolPlayers) => async (dispatch, getState) => {
  try {
    dispatch({
      type: POOLPLAYERS_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/poolsplayers/${poolPlayers._id}`, poolPlayers, config)

    dispatch({
      type: POOLPLAYERS_UPDATE_SUCCESS,
    })
    dispatch({
      type: POOLPLAYERS_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: POOLPLAYERS_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const createPoolPlayersGroup = (poolPlayersId, group) => async (dispatch, getState) => {
  try {
    dispatch({
      type: POOLPLAYERS_CREATE_GROUP_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.post(`/api/poolsplayers/${poolPlayersId}/groups`, group, config)

    dispatch({
      type: POOLPLAYERS_CREATE_GROUP_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: POOLPLAYERS_CREATE_GROUP_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const deletePoolPlayersGroup = (poolPlayersId, groupid, poolPlayers) => async (dispatch, getState) => {
  try {
    dispatch({
      type: POOLPLAYERS_DELETE_GROUP_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.put(`/api/poolsplayers/${poolPlayersId}/groups?groupid=${groupid}`, poolPlayers, config)

    dispatch({
      type: POOLPLAYERS_DELETE_GROUP_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: POOLPLAYERS_DELETE_GROUP_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const poolPlayersGroupDetails = (poolPlayersId, groupId) => async (dispatch, getState) => {
  try {
    dispatch({ type: POOLPLAYERS_GROUP_DETAILS_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/poolsplayers/${poolPlayersId}/groups?groupid=${groupId}`, config)

    dispatch({
      type: POOLPLAYERS_GROUP_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: POOLPLAYERS_GROUP_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const updatePoolPlayersGroup = (poolPlayersId, groupId, group) => async (dispatch, getState) => {
  try {
    dispatch({
      type: POOLPLAYERS_GROUP_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/poolsplayers/${poolPlayersId}/groups/edit?groupid=${groupId}`, group, config)

    dispatch({
      type: POOLPLAYERS_GROUP_UPDATE_SUCCESS,
    })
    dispatch({
      type: POOLPLAYERS_GROUP_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: POOLPLAYERS_GROUP_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const addPlayerToGroup = (poolPlayersId, groupId, player) => async (dispatch, getState) => {
  try {
    dispatch({
      type: POOLPLAYERS_ADD_PLAYER_TO_GROUP_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.put(`/api/poolsplayers/${poolPlayersId}/groups/players?groupid=${groupId}`, player, config)

    dispatch({
      type: POOLPLAYERS_ADD_PLAYER_TO_GROUP_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: POOLPLAYERS_ADD_PLAYER_TO_GROUP_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const removePlayerFromGroup = (poolPlayersId, groupId, playerId, poolPlayers) => async (dispatch, getState) => {
  try {
    dispatch({
      type: POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.put(
      `/api/poolsplayers/${poolPlayersId}/groups/players/remove?groupid=${groupId}&playerid=${playerId}`,
      poolPlayers,
      config
    )

    dispatch({
      type: POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: POOLPLAYERS_REMOVE_PLAYER_FROM_GROUP_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
