import axios from 'axios'

import {
  POOLTOURNEYS_LIST_REQUEST,
  POOLTOURNEYS_LIST_SUCCESS,
  POOLTOURNEYS_LIST_FAIL,
  POOLTOURNEYS_DETAILS_REQUEST,
  POOLTOURNEYS_DETAILS_SUCCESS,
  POOLTOURNEYS_DETAILS_FAIL,
  POOLTOURNEYS_DELETE_SUCCESS,
  POOLTOURNEYS_DELETE_FAIL,
  POOLTOURNEYS_DELETE_REQUEST,
  POOLTOURNEYS_CREATE_REQUEST,
  POOLTOURNEYS_CREATE_SUCCESS,
  POOLTOURNEYS_CREATE_FAIL,
  POOLTOURNEYS_UPDATE_REQUEST,
  POOLTOURNEYS_UPDATE_SUCCESS,
  POOLTOURNEYS_UPDATE_FAIL,
  POOLTOURNEYS_CREATE_TOURNAMENT_REQUEST,
  POOLTOURNEYS_CREATE_TOURNAMENT_SUCCESS,
  POOLTOURNEYS_CREATE_TOURNAMENT_FAIL,
  POOLTOURNEYS_DELETE_TOURNAMENT_REQUEST,
  POOLTOURNEYS_DELETE_TOURNAMENT_SUCCESS,
  POOLTOURNEYS_DELETE_TOURNAMENT_FAIL,
  POOLTOURNEYS_TOURNAMENT_DETAILS_REQUEST,
  POOLTOURNEYS_TOURNAMENT_DETAILS_SUCCESS,
  POOLTOURNEYS_TOURNAMENT_DETAILS_FAIL,
  POOLTOURNEYS_TOURNAMENT_UPDATE_REQUEST,
  POOLTOURNEYS_TOURNAMENT_UPDATE_SUCCESS,
  POOLTOURNEYS_TOURNAMENT_UPDATE_FAIL,
} from '../constants/poolTourneysConstants'

export const listPoolsTourneys = () => async (dispatch) => {
  try {
    dispatch({ type: POOLTOURNEYS_LIST_REQUEST })

    const { data } = await axios.get(`/api/poolstourneys`)

    dispatch({
      type: POOLTOURNEYS_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: POOLTOURNEYS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listPoolTourneysDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: POOLTOURNEYS_DETAILS_REQUEST })

    const { data } = await axios.get(`/api/poolstourneys/${id}`)

    dispatch({
      type: POOLTOURNEYS_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: POOLTOURNEYS_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deletePoolTourneys = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: POOLTOURNEYS_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/poolstourneys/${id}`, config)

    dispatch({
      type: POOLTOURNEYS_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: POOLTOURNEYS_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createPoolTourneys = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: POOLTOURNEYS_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/poolstourneys`, {}, config)

    dispatch({
      type: POOLTOURNEYS_CREATE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: POOLTOURNEYS_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: POOLTOURNEYS_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updatePoolTourneys = (poolTourneys) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: POOLTOURNEYS_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/poolstourneys/${poolTourneys._id}`,
      poolTourneys,
      config
    )

    dispatch({
      type: POOLTOURNEYS_UPDATE_SUCCESS,
    })
    dispatch({
      type: POOLTOURNEYS_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: POOLTOURNEYS_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createPoolTourneysTournament = (
  poolTourneysId,
  tournament
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: POOLTOURNEYS_CREATE_TOURNAMENT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.post(
      `/api/poolstourneys/${poolTourneysId}/tournaments`,
      tournament,
      config
    )

    dispatch({
      type: POOLTOURNEYS_CREATE_TOURNAMENT_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: POOLTOURNEYS_CREATE_TOURNAMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deletePoolTourneysTournament = (
  poolTourneysId,
  tournamentid,
  poolTourneys
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: POOLTOURNEYS_DELETE_TOURNAMENT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.put(
      `/api/poolstourneys/${poolTourneysId}/tournaments?tournamentid=${tournamentid}`,
      poolTourneys,
      config
    )

    dispatch({
      type: POOLTOURNEYS_DELETE_TOURNAMENT_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: POOLTOURNEYS_DELETE_TOURNAMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const poolTourneysTournamentDetails = (
  poolTourneysId,
  tournamentId
) => async (dispatch, getState) => {
  try {
    dispatch({ type: POOLTOURNEYS_TOURNAMENT_DETAILS_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `/api/poolstourneys/${poolTourneysId}/tournaments?tournamentid=${tournamentId}`,
      config
    )

    dispatch({
      type: POOLTOURNEYS_TOURNAMENT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: POOLTOURNEYS_TOURNAMENT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updatePoolTourneysTournament = (
  poolTourneysId,
  tournamentId,
  tournament
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: POOLTOURNEYS_TOURNAMENT_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/poolstourneys/${poolTourneysId}/tournaments/edit?tournamentid=${tournamentId}`,
      tournament,
      config
    )

    dispatch({
      type: POOLTOURNEYS_TOURNAMENT_UPDATE_SUCCESS,
    })
    dispatch({
      type: POOLTOURNEYS_TOURNAMENT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: POOLTOURNEYS_TOURNAMENT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
