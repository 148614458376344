import axios from 'axios'

import {
  PLAYER_LIST_REQUEST,
  PLAYER_LIST_SUCCESS,
  PLAYER_LIST_FAIL,
  PLAYER_DETAILS_REQUEST,
  PLAYER_DETAILS_SUCCESS,
  PLAYER_DETAILS_FAIL,
  PLAYER_DELETE_SUCCESS,
  PLAYER_DELETE_FAIL,
  PLAYER_DELETE_REQUEST,
  PLAYER_CREATE_REQUEST,
  PLAYER_CREATE_SUCCESS,
  PLAYER_CREATE_FAIL,
  PLAYER_UPDATE_REQUEST,
  PLAYER_UPDATE_SUCCESS,
  PLAYER_UPDATE_FAIL,
  PLAYER_LEADERBOARD_REQUEST,
  PLAYER_LEADERBOARD_FAIL,
  PLAYER_LEADERBOARD_SUCCESS,
} from '../constants/playerConstants'

export const listPlayers =
  (brand = '') =>
  async (dispatch) => {
    try {
      dispatch({
        type: PLAYER_LIST_REQUEST,
      })

      const { data } = await axios.get(`/api/players?brand=${brand}`)

      dispatch({
        type: PLAYER_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: PLAYER_LIST_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
    }
  }

export const listPlayerDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PLAYER_DETAILS_REQUEST })

    const { data } = await axios.get(`/api/players/${id}`)

    dispatch({
      type: PLAYER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PLAYER_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const deletePlayer = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PLAYER_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/players/${id}`, config)

    dispatch({
      type: PLAYER_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: PLAYER_DELETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const createPlayer = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PLAYER_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/players`, {}, config)

    dispatch({
      type: PLAYER_CREATE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: PLAYER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PLAYER_CREATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const updatePlayer = (player) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PLAYER_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/players/${player._id}`, player, config)

    dispatch({
      type: PLAYER_UPDATE_SUCCESS,
    })
    dispatch({
      type: PLAYER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PLAYER_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const leaderboardPlayers = (draftId, buyin) => async (dispatch) => {
  try {
    dispatch({
      type: PLAYER_LEADERBOARD_REQUEST,
    })

    const { data } = await axios.get(`/api/stats?draftid=${draftId}&&buyin=${buyin}`)

    dispatch({
      type: PLAYER_LEADERBOARD_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PLAYER_LEADERBOARD_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
