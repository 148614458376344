import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import {
  poolTourneysTournamentDetails,
  updatePoolTourneysTournament,
  listPoolTourneysDetails,
} from '../actions/poolTourneysActions'
import { listTournaments } from '../actions/tournamentActions'
import {
  POOLTOURNEYS_TOURNAMENT_UPDATE_RESET,
  POOLTOURNEYS_DETAILS_RESET,
} from '../constants/poolTourneysConstants'

const PoolTourneysTournamentEditScreen = ({ match, history }) => {
  const poolTourneysId = match.params.id
  const tournamentId = match.params.tournamentid

  const [tournamentItem, setTournamentItem] = useState('')
  const [number, setNumber] = useState(0)
  const [seriesId, setSeriesId] = useState('')
  const [poolTourneysName, setPoolTourneysName] = useState('')

  const dispatch = useDispatch()

  const tournamentDetails = useSelector(
    (state) => state.poolTourneysTournamentDetails
  )
  const { tournament } = tournamentDetails
  const { poolTourneysTournaments } = tournament

  const tournamentUpdate = useSelector(
    (state) => state.poolTourneysTournamentUpdate
  )
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = tournamentUpdate

  const tournamentList = useSelector((state) => state.tournamentList)
  const {
    loading: loadingTournaments,
    error: errorTournaments,
    tournaments,
  } = tournamentList

  const poolTourneysDetails = useSelector((state) => state.poolTourneysDetails)
  const { poolTourneys } = poolTourneysDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    } else {
      if (
        !poolTourneysTournaments ||
        poolTourneysTournaments[0]._id !== tournamentId
      ) {
        dispatch(poolTourneysTournamentDetails(poolTourneysId, tournamentId))
      } else {
        setTournamentItem(poolTourneysTournaments[0].tournament._id)
        setNumber(poolTourneysTournaments[0].number)
      }
      if (successUpdate) {
        dispatch({ type: POOLTOURNEYS_TOURNAMENT_UPDATE_RESET })
        dispatch({ type: POOLTOURNEYS_DETAILS_RESET })
        dispatch(poolTourneysTournamentDetails(poolTourneysId, tournamentId))
        dispatch(listPoolTourneysDetails(poolTourneysId))
        history.push(`/admin/pooltourneys/${poolTourneysId}/edit`)
      } else {
        if (seriesId !== '') {
          dispatch(listTournaments(seriesId))
        } else {
          if (poolTourneys.series) {
            setSeriesId(poolTourneys.series._id)
            setPoolTourneysName(poolTourneys.name)
          } else {
            if (!poolTourneys || poolTourneys._id !== poolTourneysId) {
              dispatch(listPoolTourneysDetails(poolTourneysId))
            }
          }
        }
      }
    }
  }, [
    dispatch,
    history,
    poolTourneysTournaments,
    poolTourneysId,
    tournamentId,
    successUpdate,
    userInfo,
    poolTourneys,
    seriesId,
  ])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updatePoolTourneysTournament(poolTourneysId, tournamentId, {
        tournamentItem,
        number,
      })
    )
  }

  return (
    <>
      <LinkContainer to={`/admin/pooltourneys/${poolTourneysId}/edit`}>
        <Button variant='light'>Go Back to {poolTourneysName}</Button>
      </LinkContainer>
      <FormContainer>
        <h1>Edit Tournament</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loadingTournaments ? (
          <Loader />
        ) : errorTournaments ? (
          <Message variant='danger'>{errorTournaments}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='tournamentItem'>
              <Form.Label>Tournament</Form.Label>
              <Form.Control
                as='select'
                value={tournamentItem}
                onChange={(e) => setTournamentItem(e.target.value)}
              >
                <option value=''>Select...</option>
                {tournaments
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((x) => (
                    <option key={x._id} value={x._id}>
                      {x.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId='number'>
              <Form.Label>Number</Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter number'
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button type='submit' variant='primary'>
              Update Tournament
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}
export default PoolTourneysTournamentEditScreen
