import { MY_TEAM_ADD_PLAYER, MY_TEAM_REMOVE_PLAYER } from '../constants/myTeamConstants'

export const myTeamReducer = (state = { myTeamPlayers: [] }, action) => {
  switch (action.type) {
    case MY_TEAM_ADD_PLAYER:
      const player = action.payload

      const existPlayer = state.myTeamPlayers.find((x) => x.draft === player.draft)

      if (existPlayer) {
        return {
          ...state,
          myTeamPlayers: state.myTeamPlayers.map((x) => (x.draft === existPlayer.draft ? player : x)),
        }
      } else {
        return {
          ...state,
          myTeamPlayers: [...state.myTeamPlayers, player],
        }
      }
    case MY_TEAM_REMOVE_PLAYER:
      return {
        ...state,
        myTeamPlayers: state.myTeamPlayers.filter((x) => x.draft !== action.payload),
      }
    default:
      return state
  }
}
