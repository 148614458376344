export const SERIES_LIST_REQUEST = 'SERIES_LIST_REQUEST'
export const SERIES_LIST_SUCCESS = 'SERIES_LIST_SUCCESS'
export const SERIES_LIST_FAIL = 'SERIES_LIST_FAIL'
export const SERIES_LIST_RESET = 'SERIES_LIST_RESET'

export const SERIES_DETAILS_REQUEST = 'SERIES_DETAILS_REQUEST'
export const SERIES_DETAILS_SUCCESS = 'SERIES_DETAILS_SUCCESS'
export const SERIES_DETAILS_FAIL = 'SERIES_DETAILS_FAIL'
export const SERIES_DETAILS_RESET = 'SERIES_DETAILS_RESET'

export const SERIES_DELETE_REQUEST = 'SERIES_DELETE_REQUEST'
export const SERIES_DELETE_SUCCESS = 'SERIES_DELETE_SUCCESS'
export const SERIES_DELETE_FAIL = 'SERIES_DELETE_FAIL'

export const SERIES_CREATE_REQUEST = 'SERIES_CREATE_REQUEST'
export const SERIES_CREATE_SUCCESS = 'SERIES_CREATE_SUCCESS'
export const SERIES_CREATE_FAIL = 'SERIES_CREATE_FAIL'
export const SERIES_CREATE_RESET = 'SERIES_CREATE_RESET'

export const SERIES_UPDATE_REQUEST = 'SERIES_UPDATE_REQUEST'
export const SERIES_UPDATE_SUCCESS = 'SERIES_UPDATE_SUCCESS'
export const SERIES_UPDATE_FAIL = 'SERIES_UPDATE_FAIL'
export const SERIES_UPDATE_RESET = 'SERIES_UPDATE_RESET'
