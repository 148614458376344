import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container, NavDropdown, Image } from 'react-bootstrap'
import SearchBox from './SearchBox'
import { logout } from '../actions/userActions'
import { listDrafts } from '../actions/draftActions'
import { getUserDetails } from '../actions/userActions'

const Header = () => {
  const dispatch = useDispatch()

  const draftList = useSelector((state) => state.draftList)
  const { drafts } = draftList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDetails = useSelector((state) => state.userDetails)
  const { user } = userDetails

  useEffect(() => {
    dispatch(listDrafts())
    dispatch(getUserDetails('profile'))
  }, [dispatch])

  const logoutHandler = () => {
    dispatch(logout())
  }

  return (
    <header>
      <Navbar bg='dark' variant='dark' expand='lg' collapseOnSelect fixed='top'>
        <Container>
          <LinkContainer to='/'>
            <Navbar.Brand>
              <Image
                src='/images/mpd_logo.png'
                alt='MyPokerDraft'
                height='75px'
                rounded
                style={{ margin: '0rem', padding: '0rem' }}
              />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Route render={({ history }) => <SearchBox history={history} />} />
            <Nav className='ml-auto'>
              {user && (
                <LinkContainer to='/profile'>
                  <Nav.Link>
                    <i className='fas fa-wallet'></i> ${user.balance}
                  </Nav.Link>
                </LinkContainer>
              )}
              {userInfo ? (
                <NavDropdown title={userInfo.name} id='username'>
                  <LinkContainer to='/profile'>
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer to='/login'>
                  <Nav.Link>
                    <i className='fas fa-user'></i> Log In | Sign Up
                  </Nav.Link>
                </LinkContainer>
              )}
              {userInfo && userInfo.isAdmin && (
                <NavDropdown title='Admin' id='adminmenu'>
                  <LinkContainer to='/admin/brandlist'>
                    <NavDropdown.Item>Brands</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/draftlist'>
                    <NavDropdown.Item>Drafts</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/orderlist'>
                    <NavDropdown.Item>Orders</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/playerlist'>
                    <NavDropdown.Item>Players</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/pokergamelist'>
                    <NavDropdown.Item>Poker Games</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/poolplayerslist'>
                    <NavDropdown.Item>Pools of Players</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/pooltourneyslist'>
                    <NavDropdown.Item>Pools of Tourneys</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/serieslist'>
                    <NavDropdown.Item>Series</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/teamlist'>
                    <NavDropdown.Item>Teams</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/tournamentlist'>
                    <NavDropdown.Item>Tournaments</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/userlist'>
                    <NavDropdown.Item>Users</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
        <Nav className='navbar-submenu mr-auto'>
          <Container>
            <LinkContainer to='/'>
              <Nav.Link>Drafts</Nav.Link>
            </LinkContainer>
            <NavDropdown title='Leaderboard' id='teamsmenu'>
              {drafts &&
                drafts.length !== 0 &&
                drafts
                  .sort((a, b) => (a.startDate > b.startDate ? -1 : 1))
                  .map((draft) =>
                    draft.isPublic && draft.buyinHigh !== 0 ? (
                      <div key={draft._id}>
                        <LinkContainer to={`/leaderboard/teams/${draft._id}/${draft.buyinLow}`}>
                          <NavDropdown.Item>
                            {draft.name} ${draft.buyinLow}
                          </NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to={`/leaderboard/teams/${draft._id}/${draft.buyinHigh}`}>
                          <NavDropdown.Item>
                            {draft.name} ${draft.buyinHigh}
                          </NavDropdown.Item>
                        </LinkContainer>
                      </div>
                    ) : (
                      draft.isPublic && (
                        <div key={draft._id}>
                          <LinkContainer key={draft._id} to={`/leaderboard/teams/${draft._id}/${draft.buyinLow}`}>
                            <NavDropdown.Item>
                              {draft.name} ${draft.buyinLow}
                            </NavDropdown.Item>
                          </LinkContainer>
                        </div>
                      )
                    )
                  )}
            </NavDropdown>
            <NavDropdown title='Stats' id='playersmenu'>
              {drafts &&
                drafts.length !== 0 &&
                drafts
                  .sort((a, b) => (a.startDate > b.startDate ? -1 : 1))
                  .map((draft) =>
                    draft.isPublic && draft.buyinHigh !== 0 ? (
                      <div key={draft._id}>
                        <LinkContainer to={`/leaderboard/players/${draft._id}/${draft.buyinLow}`}>
                          <NavDropdown.Item>
                            {draft.name} ${draft.buyinLow}
                          </NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to={`/leaderboard/players/${draft._id}/${draft.buyinHigh}`}>
                          <NavDropdown.Item>
                            {draft.name} ${draft.buyinHigh}
                          </NavDropdown.Item>
                        </LinkContainer>
                      </div>
                    ) : (
                      draft.isPublic && (
                        <div key={draft._id}>
                          <LinkContainer key={draft._id} to={`/leaderboard/players/${draft._id}/${draft.buyinLow}`}>
                            <NavDropdown.Item>
                              {draft.name} ${draft.buyinLow}
                            </NavDropdown.Item>
                          </LinkContainer>
                        </div>
                      )
                    )
                  )}
            </NavDropdown>
          </Container>
        </Nav>
      </Navbar>
    </header>
  )
}

export default Header
