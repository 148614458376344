import {
  PLAYER_LIST_REQUEST,
  PLAYER_LIST_SUCCESS,
  PLAYER_LIST_FAIL,
  PLAYER_LIST_RESET,
  PLAYER_DETAILS_REQUEST,
  PLAYER_DETAILS_SUCCESS,
  PLAYER_DETAILS_FAIL,
  PLAYER_DELETE_FAIL,
  PLAYER_DELETE_SUCCESS,
  PLAYER_DELETE_REQUEST,
  PLAYER_CREATE_REQUEST,
  PLAYER_CREATE_SUCCESS,
  PLAYER_CREATE_FAIL,
  PLAYER_CREATE_RESET,
  PLAYER_UPDATE_REQUEST,
  PLAYER_UPDATE_SUCCESS,
  PLAYER_UPDATE_FAIL,
  PLAYER_UPDATE_RESET,
  PLAYER_LEADERBOARD_REQUEST,
  PLAYER_LEADERBOARD_SUCCESS,
  PLAYER_LEADERBOARD_FAIL,
  PLAYER_LEADERBOARD_RESET,
} from '../constants/playerConstants'

export const playerListReducer = (state = { players: [] }, action) => {
  switch (action.type) {
    case PLAYER_LIST_REQUEST:
      return { loading: true, players: [] }
    case PLAYER_LIST_SUCCESS:
      return {
        loading: false,
        players: action.payload,
      }
    case PLAYER_LIST_FAIL:
      return { loading: false, error: action.payload }
    case PLAYER_LIST_RESET:
      return { players: [] }
    default:
      return state
  }
}

export const playerDetailsReducer = (state = { player: {} }, action) => {
  switch (action.type) {
    case PLAYER_DETAILS_REQUEST:
      return { loading: true, ...state }
    case PLAYER_DETAILS_SUCCESS:
      return { loading: false, player: action.payload }
    case PLAYER_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const playerDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAYER_DELETE_REQUEST:
      return { loading: true }
    case PLAYER_DELETE_SUCCESS:
      return { loading: false, success: true }
    case PLAYER_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const playerCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAYER_CREATE_REQUEST:
      return { loading: true }
    case PLAYER_CREATE_SUCCESS:
      return { loading: false, success: true, player: action.payload }
    case PLAYER_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case PLAYER_CREATE_RESET:
      return { playerDetails: {} }
    default:
      return state
  }
}

export const playerUpdateReducer = (state = { player: {} }, action) => {
  switch (action.type) {
    case PLAYER_UPDATE_REQUEST:
      return { loading: true }
    case PLAYER_UPDATE_SUCCESS:
      return { loading: false, success: true, player: action.payload }
    case PLAYER_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case PLAYER_UPDATE_RESET:
      return { player: {} }
    default:
      return state
  }
}

export const playersLeaderboardReducer = (state = { players: [] }, action) => {
  switch (action.type) {
    case PLAYER_LEADERBOARD_REQUEST:
      return { loading: true, players: [] }
    case PLAYER_LEADERBOARD_SUCCESS:
      return {
        loading: false,
        players: action.payload,
      }
    case PLAYER_LEADERBOARD_FAIL:
      return { loading: false, error: action.payload }
    case PLAYER_LEADERBOARD_RESET:
      return { players: [] }
    default:
      return state
  }
}
