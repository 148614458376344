import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  listSeries,
  deleteSeries,
  createSeries,
} from '../actions/seriesActions'
import { SERIES_CREATE_RESET } from '../constants/seriesConstants'

const SeriesListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const seriesList = useSelector((state) => state.seriesList)
  const { loading, error, seriesArray } = seriesList

  const seriesDelete = useSelector((state) => state.seriesDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = seriesDelete

  const seriesCreate = useSelector((state) => state.seriesCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    series: createdSeries,
  } = seriesCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    dispatch({ type: SERIES_CREATE_RESET })
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }
    if (successCreate) {
      history.push(`/admin/series/${createdSeries._id}/edit`)
    } else {
      dispatch(listSeries('all'))
    }
  }, [dispatch, history, userInfo, successDelete, successCreate, createdSeries])

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteSeries(id))
    }
  }

  const createSeriesHandler = () => {
    dispatch(createSeries())
  }

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Series</h1>
        </Col>
        <Col className='text-right'>
          <Button className='my-3' onClick={createSeriesHandler}>
            <i className='fas fa-plus'></i> Create Series
          </Button>
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th></th>
              <th>NAME</th>
              <th>BRAND</th>
              <th>DESCRIPTION</th>
              <th>ONLINE</th>
              <th>ACTIVE</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {seriesArray
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((series) => (
                <tr key={series._id}>
                  <td>
                    {' '}
                    <Image
                      src={series.image}
                      alt={series.name}
                      width='100vw'
                      fluid
                      rounded
                    />
                  </td>
                  <td>{series.name}</td>
                  <td>{series.brand.name}</td>
                  <td>{series.description}</td>
                  <td>
                    {series.isOnline ? (
                      <i
                        className='fas fa-check'
                        style={{ color: 'green' }}
                      ></i>
                    ) : (
                      <i className='fas fa-times' style={{ color: 'grey' }}></i>
                    )}
                  </td>
                  <td>
                    {series.isActive ? (
                      <i
                        className='fas fa-check'
                        style={{ color: 'green' }}
                      ></i>
                    ) : (
                      <i className='fas fa-times' style={{ color: 'grey' }}></i>
                    )}
                  </td>
                  <td>
                    <LinkContainer to={`/admin/series/${series._id}/edit`}>
                      <Button variant='light' className='btn-sm'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </LinkContainer>
                    <Button
                      variant='danger'
                      className='btn-sm'
                      onClick={() => deleteHandler(series._id)}
                    >
                      <i className='fas fa-trash'></i>
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  )
}

export default SeriesListScreen
