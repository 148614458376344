export const TOURNAMENT_LIST_REQUEST = 'TOURNAMENT_LIST_REQUEST'
export const TOURNAMENT_LIST_SUCCESS = 'TOURNAMENT_LIST_SUCCESS'
export const TOURNAMENT_LIST_FAIL = 'TOURNAMENT_LIST_FAIL'
export const TOURNAMENT_LIST_RESET = 'TOURNAMENT_LIST_RESET'

export const TOURNAMENT_DETAILS_REQUEST = 'TOURNAMENT_DETAILS_REQUEST'
export const TOURNAMENT_DETAILS_SUCCESS = 'TOURNAMENT_DETAILS_SUCCESS'
export const TOURNAMENT_DETAILS_FAIL = 'TOURNAMENT_DETAILS_FAIL'
export const TOURNAMENT_DETAILS_RESET = 'TOURNAMENT_DETAILS_RESET'

export const TOURNAMENT_DELETE_REQUEST = 'TOURNAMENT_DELETE_REQUEST'
export const TOURNAMENT_DELETE_SUCCESS = 'TOURNAMENT_DELETE_SUCCESS'
export const TOURNAMENT_DELETE_FAIL = 'TOURNAMENT_DELETE_FAIL'

export const TOURNAMENT_CREATE_REQUEST = 'TOURNAMENT_CREATE_REQUEST'
export const TOURNAMENT_CREATE_SUCCESS = 'TOURNAMENT_CREATE_SUCCESS'
export const TOURNAMENT_CREATE_FAIL = 'TOURNAMENT_CREATE_FAIL'
export const TOURNAMENT_CREATE_RESET = 'TOURNAMENT_CREATE_RESET'

export const TOURNAMENT_UPDATE_REQUEST = 'TOURNAMENT_UPDATE_REQUEST'
export const TOURNAMENT_UPDATE_SUCCESS = 'TOURNAMENT_UPDATE_SUCCESS'
export const TOURNAMENT_UPDATE_FAIL = 'TOURNAMENT_UPDATE_FAIL'
export const TOURNAMENT_UPDATE_RESET = 'TOURNAMENT_UPDATE_RESET'

export const TOURNAMENT_CREATE_RESULT_REQUEST =
  'TOURNAMENT_CREATE_RESULT_REQUEST'
export const TOURNAMENT_CREATE_RESULT_SUCCESS =
  'TOURNAMENT_CREATE_RESULT_SUCCESS'
export const TOURNAMENT_CREATE_RESULT_FAIL = 'TOURNAMENT_CREATE_RESULT_FAIL'
export const TOURNAMENT_CREATE_RESULT_RESET = 'TOURNAMENT_CREATE_RESULT_RESET'

export const TOURNAMENT_DELETE_RESULT_REQUEST =
  'TOURNAMENT_DELETE_RESULT_REQUEST'
export const TOURNAMENT_DELETE_RESULT_SUCCESS =
  'TOURNAMENT_DELETE_RESULT_SUCCESS'
export const TOURNAMENT_DELETE_RESULT_FAIL = 'TOURNAMENT_DELETE_RESULT_FAIL'
export const TOURNAMENT_DELETE_RESULT_RESET = 'TOURNAMENT_DELETE_RESULT_RESET'

export const TOURNAMENT_RESULT_DETAILS_REQUEST =
  'TOURNAMENT_RESULT_DETAILS_REQUEST'
export const TOURNAMENT_RESULT_DETAILS_SUCCESS =
  'TOURNAMENT_RESULT_DETAILS_SUCCESS'
export const TOURNAMENT_RESULT_DETAILS_FAIL = 'TOURNAMENT_RESULT_DETAILS_FAIL'
export const TOURNAMENT_RESULT_DETAILS_RESET = 'TOURNAMENT_RESULT_DETAILS_RESET'

export const TOURNAMENT_RESULT_UPDATE_REQUEST =
  'TOURNAMENT_RESULT_UPDATE_REQUEST'
export const TOURNAMENT_RESULT_UPDATE_SUCCESS =
  'TOURNAMENT_RESULT_UPDATE_SUCCESS'
export const TOURNAMENT_RESULT_UPDATE_FAIL = 'TOURNAMENT_RESULT_UPDATE_FAIL'
export const TOURNAMENT_RESULT_UPDATE_RESET =
  'TOURNAMENT_TOURNAMENT_UPDATE_RESET'
