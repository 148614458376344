export const POOLTOURNEYS_LIST_REQUEST = 'POOLTOURNEYS_LIST_REQUEST'
export const POOLTOURNEYS_LIST_SUCCESS = 'POOLTOURNEYS_LIST_SUCCESS'
export const POOLTOURNEYS_LIST_FAIL = 'POOLTOURNEYS_LIST_FAIL'

export const POOLTOURNEYS_DETAILS_REQUEST = 'POOLTOURNEYS_DETAILS_REQUEST'
export const POOLTOURNEYS_DETAILS_SUCCESS = 'POOLTOURNEYS_DETAILS_SUCCESS'
export const POOLTOURNEYS_DETAILS_FAIL = 'POOLTOURNEYS_DETAILS_FAIL'
export const POOLTOURNEYS_DETAILS_RESET = 'POOLTOURNEYS_DETAILS_RESET'

export const POOLTOURNEYS_DELETE_REQUEST = 'POOLTOURNEYS_DELETE_REQUEST'
export const POOLTOURNEYS_DELETE_SUCCESS = 'POOLTOURNEYS_DELETE_SUCCESS'
export const POOLTOURNEYS_DELETE_FAIL = 'POOLTOURNEYS_DELETE_FAIL'

export const POOLTOURNEYS_CREATE_REQUEST = 'POOLTOURNEYS_CREATE_REQUEST'
export const POOLTOURNEYS_CREATE_SUCCESS = 'POOLTOURNEYS_CREATE_SUCCESS'
export const POOLTOURNEYS_CREATE_FAIL = 'POOLTOURNEYS_CREATE_FAIL'
export const POOLTOURNEYS_CREATE_RESET = 'POOLTOURNEYS_CREATE_RESET'

export const POOLTOURNEYS_UPDATE_REQUEST = 'POOLTOURNEYS_UPDATE_REQUEST'
export const POOLTOURNEYS_UPDATE_SUCCESS = 'POOLTOURNEYS_UPDATE_SUCCESS'
export const POOLTOURNEYS_UPDATE_FAIL = 'POOLTOURNEYS_UPDATE_FAIL'
export const POOLTOURNEYS_UPDATE_RESET = 'POOLTOURNEYS_UPDATE_RESET'

export const POOLTOURNEYS_CREATE_TOURNAMENT_REQUEST =
  'POOLTOURNEYS_CREATE_TOURNAMENT_REQUEST'
export const POOLTOURNEYS_CREATE_TOURNAMENT_SUCCESS =
  'POOLTOURNEYS_CREATE_TOURNAMENT_SUCCESS'
export const POOLTOURNEYS_CREATE_TOURNAMENT_FAIL =
  'POOLTOURNEYS_CREATE_TOURNAMENT_FAIL'
export const POOLTOURNEYS_CREATE_TOURNAMENT_RESET =
  'POOLTOURNEYS_CREATE_TOURNAMENT_RESET'

export const POOLTOURNEYS_DELETE_TOURNAMENT_REQUEST =
  'POOLTOURNEYS_DELETE_TOURNAMENT_REQUEST'
export const POOLTOURNEYS_DELETE_TOURNAMENT_SUCCESS =
  'POOLTOURNEYS_DELETE_TOURNAMENT_SUCCESS'
export const POOLTOURNEYS_DELETE_TOURNAMENT_FAIL =
  'POOLTOURNEYS_DELETE_TOURNAMENT_FAIL'
export const POOLTOURNEYS_DELETE_TOURNAMENT_RESET =
  'POOLTOURNEYS_DELETE_TOURNAMENT_RESET'

export const POOLTOURNEYS_TOURNAMENT_DETAILS_REQUEST =
  'POOLTOURNEYS_TOURNAMENT_DETAILS_REQUEST'
export const POOLTOURNEYS_TOURNAMENT_DETAILS_SUCCESS =
  'POOLTOURNEYS_TOURNAMENT_DETAILS_SUCCESS'
export const POOLTOURNEYS_TOURNAMENT_DETAILS_FAIL =
  'POOLTOURNEYS_TOURNAMENT_DETAILS_FAIL'
export const POOLTOURNEYS_TOURNAMENT_DETAILS_RESET =
  'POOLTOURNEYS_TOURNAMENT_DETAILS_RESET'

export const POOLTOURNEYS_TOURNAMENT_UPDATE_REQUEST =
  'POOLTOURNEYS_TOURNAMENT_UPDATE_REQUEST'
export const POOLTOURNEYS_TOURNAMENT_UPDATE_SUCCESS =
  'POOLTOURNEYS_TOURNAMENT_UPDATE_SUCCESS'
export const POOLTOURNEYS_TOURNAMENT_UPDATE_FAIL =
  'POOLTOURNEYS_TOURNAMENT_UPDATE_FAIL'
export const POOLTOURNEYS_TOURNAMENT_UPDATE_RESET =
  'POOLTOURNEYS_TOURNAMENT_UPDATE_RESET'
