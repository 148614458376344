import axios from 'axios'

import {
  TEAM_LIST_REQUEST,
  TEAM_LIST_SUCCESS,
  TEAM_LIST_FAIL,
  TEAM_DETAILS_REQUEST,
  TEAM_DETAILS_SUCCESS,
  TEAM_DETAILS_FAIL,
  TEAM_DELETE_SUCCESS,
  TEAM_DELETE_FAIL,
  TEAM_DELETE_REQUEST,
  TEAM_CREATE_REQUEST,
  TEAM_CREATE_SUCCESS,
  TEAM_CREATE_FAIL,
  TEAM_CREATE_BY_USER_REQUEST,
  TEAM_CREATE_BY_USER_SUCCESS,
  TEAM_CREATE_BY_USER_FAIL,
  TEAM_UPDATE_REQUEST,
  TEAM_UPDATE_SUCCESS,
  TEAM_UPDATE_FAIL,
  TEAM_CREATE_RESULT_REQUEST,
  TEAM_CREATE_RESULT_SUCCESS,
  TEAM_CREATE_RESULT_FAIL,
  TEAM_CAPTAIN_REQUEST,
  TEAM_CAPTAIN_SUCCESS,
  TEAM_CAPTAIN_FAIL,
  TEAM_DELETE_RESULT_REQUEST,
  TEAM_DELETE_RESULT_SUCCESS,
  TEAM_DELETE_RESULT_FAIL,
  TEAM_RESULT_DETAILS_REQUEST,
  TEAM_RESULT_DETAILS_SUCCESS,
  TEAM_RESULT_DETAILS_FAIL,
  TEAM_LEADERBOARD_REQUEST,
  TEAM_LEADERBOARD_FAIL,
  TEAM_LEADERBOARD_SUCCESS,
} from '../constants/teamConstants'

export const listTeams =
  (draft = '') =>
  async (dispatch) => {
    try {
      dispatch({
        type: TEAM_LIST_REQUEST,
      })

      const { data } = await axios.get(`/api/teams?draftid=${draft}`)

      dispatch({
        type: TEAM_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: TEAM_LIST_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
    }
  }

export const listTeamDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_DETAILS_REQUEST })

    const { data } = await axios.get(`/api/teams/${id}`)

    dispatch({
      type: TEAM_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TEAM_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const deleteTeam = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TEAM_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/teams/${id}`, config)

    dispatch({
      type: TEAM_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: TEAM_DELETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const createTeam = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: TEAM_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/teams`, {}, config)

    dispatch({
      type: TEAM_CREATE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: TEAM_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TEAM_CREATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const createTeamByUser = (team) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TEAM_CREATE_BY_USER_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/teams`, team, config)

    dispatch({
      type: TEAM_CREATE_BY_USER_SUCCESS,
    })
    dispatch({
      type: TEAM_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TEAM_CREATE_BY_USER_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const updateTeam = (team) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TEAM_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/teams/${team._id}`, team, config)

    dispatch({
      type: TEAM_UPDATE_SUCCESS,
    })
    dispatch({
      type: TEAM_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TEAM_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const createTeamPlayer = (teamId, player) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TEAM_CREATE_RESULT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.post(`/api/teams/${teamId}/players`, player, config)

    dispatch({
      type: TEAM_CREATE_RESULT_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: TEAM_CREATE_RESULT_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const selectCaptain = (teamId, player) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TEAM_CAPTAIN_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.put(`/api/teams/${teamId}/players/captain`, player, config)

    dispatch({
      type: TEAM_CAPTAIN_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: TEAM_CAPTAIN_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const deleteTeamPlayer = (teamId, playerId, team) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TEAM_DELETE_RESULT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.put(`/api/teams/${teamId}/players?playerid=${playerId}`, team, config)

    dispatch({
      type: TEAM_DELETE_RESULT_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: TEAM_DELETE_RESULT_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const teamPlayerDetails = (teamId, playerId) => async (dispatch, getState) => {
  try {
    dispatch({ type: TEAM_RESULT_DETAILS_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/teams/${teamId}/players?playerid=${playerId}`, config)

    dispatch({
      type: TEAM_RESULT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TEAM_RESULT_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const leaderboardTeams = (draftId, buyin) => async (dispatch) => {
  try {
    dispatch({
      type: TEAM_LEADERBOARD_REQUEST,
    })

    const { data } = await axios.get(`/api/leaderboards/teams/?draftid=${draftId}&&buyin=${buyin}`)

    dispatch({
      type: TEAM_LEADERBOARD_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TEAM_LEADERBOARD_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
