import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Button, ListGroupItem, Form } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import { Pagination } from 'react-bootstrap'
import { listDraftDetails } from '../actions/draftActions'
import { listPoolPlayersDetails } from '../actions/poolPlayersActions'
import { listSeriesDetails } from '../actions/seriesActions'
import { createTeamByUser } from '../actions/teamActions'
import { getUserDetails } from '../actions/userActions'
import { TEAM_CREATE_BY_USER_RESET } from '../constants/teamConstants'

const DraftScreen = ({ history, match }) => {
  const pages = 10
  const pageLimit = 24
  const [budget, setBudget] = useState(0)
  const [playersCount, setPlayersCount] = useState(0)
  const [page, setPage] = useState(1)
  const [myTeam, setMyTeam] = useState([])
  const [teamName, setTeamName] = useState('Team')

  const dispatch = useDispatch()

  const draftDetails = useSelector((state) => state.draftDetails)
  const { loading, error, draft } = draftDetails

  const poolPlayersDetails = useSelector((state) => state.poolPlayersDetails)
  const { loading: loadingPlayers, error: errorPlayers, poolPlayers } = poolPlayersDetails

  const seriesDetails = useSelector((state) => state.seriesDetails)
  const { series } = seriesDetails

  const userDetails = useSelector((state) => state.userDetails)
  const { user } = userDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const teamCreateByUser = useSelector((state) => state.teamCreateByUser)
  const { error: errorCreateByUser, success: successCreateByUser } = teamCreateByUser

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      if (successCreateByUser) {
        dispatch({ type: TEAM_CREATE_BY_USER_RESET })
        dispatch(getUserDetails('profile'))
        history.push(`/leaderboard/teams/${draft._id}/${match.params.buyin}`)
      } else {
        if (draft && draft.series) {
          if (series && series.brand) {
            dispatch(listPoolPlayersDetails(draft.poolPlayers._id))
            dispatch(getUserDetails('profile'))
          } else {
            dispatch(listSeriesDetails(draft.series._id))
          }
        } else {
          dispatch(listDraftDetails(match.params.id, match.params.buyin))
        }
      }
    }
  }, [dispatch, match, series, userInfo, draft, history, successCreateByUser])

  const selectPlayer = (event, price, name, image, id, groupId, groupLimit) => {
    let currentBudget = myTeam.reduce((acc, item) => item.price + acc, 0)
    let currentPlayersCount = myTeam.length
    let playersFromGroup = myTeam.filter(({ group }) => group === groupId)
    let currentPlayersGroupCount = playersFromGroup.length

    if (
      currentBudget + price <= draft.budget &&
      currentPlayersCount < draft.playersLimit &&
      currentPlayersGroupCount < groupLimit &&
      !event.currentTarget.classList.contains('player-selected')
    ) {
      event.currentTarget.classList.toggle('player-selected')
      myTeam.push({
        player: id,
        src: image,
        alt: name,
        price: price,
        group: groupId,
      })
      currentBudget = myTeam.reduce((acc, item) => item.price + acc, 0)
      currentPlayersCount = myTeam.length
      setBudget(currentBudget)
      setPlayersCount(currentPlayersCount)
    } else {
      if (event.currentTarget.classList.contains('player-selected')) {
        event.currentTarget.classList.remove('player-selected')
        let newMyTeam = myTeam.filter(({ player }) => player !== id)
        setMyTeam(newMyTeam)
        currentBudget = newMyTeam.reduce((acc, item) => item.price + acc, 0)
        currentPlayersCount = newMyTeam.length
        setBudget(currentBudget)
        setPlayersCount(currentPlayersCount)
      }
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      createTeamByUser({
        draft: draft._id,
        buyin: match.params.buyin,
        user: userInfo._id,
        name: teamName,
        players: myTeam,
      })
    )
  }

  return (
    <>
      {errorCreateByUser && <Message variant='danger'>{errorCreateByUser}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Meta title={`${draft.name} | MyPokerDraft`} />
          <Row key={draft._id}>
            <Col md={4}>
              <div align='center'>
                <h4>Player Pool</h4>
              </div>
              <ListGroup variant='flush'>
                <ListGroupItem key='players-pool-header' className='player-row'>
                  <Row className='players-pool'>
                    <Col className='player-image-column oneRem top-bottom-pad-oneRem'>Player</Col>
                    <Col className='player-name-column'></Col>
                    <Col className='player-price-column oneRem top-bottom-pad-oneRem'>Price</Col>
                  </Row>
                </ListGroupItem>
                {loadingPlayers ? (
                  <Loader />
                ) : errorPlayers ? (
                  <Message variant='danger'>{errorPlayers}</Message>
                ) : (
                  <>
                    {poolPlayers &&
                      poolPlayers.poolPlayersGroups &&
                      poolPlayers.poolPlayersGroups.length !== 0 &&
                      poolPlayers.poolPlayersGroups.map(
                        (x) =>
                          x.groupPlayers &&
                          x.groupPlayers.length !== 0 &&
                          x.groupPlayers
                            .sort((a, b) =>
                              a.playerCost === b.playerCost
                                ? a.player.name.toLowerCase() > b.player.name.toLowerCase()
                                  ? 1
                                  : -1
                                : a.playerCost > b.playerCost
                                ? -1
                                : 1
                            )
                            .map((y, i) =>
                              i > pageLimit * (page - 1) - 1 &&
                              i < pageLimit * page &&
                              myTeam.find((quadMember) => {
                                return quadMember.player === y.player._id
                              }) ? (
                                <ListGroupItem
                                  key={y._id}
                                  className='player-row player-selected'
                                  onClick={(e) =>
                                    selectPlayer(
                                      e,
                                      y.playerCost,
                                      y.player.name,
                                      y.player.image,
                                      y.player._id,
                                      x._id,
                                      x.groupLimit
                                    )
                                  }
                                >
                                  <Row className='players-pool'>
                                    <Col className='player-image-column'>
                                      <Image src={y.player.image} width='45px'></Image>
                                    </Col>
                                    <Col className='player-name-column'>
                                      {series.isOnline === true
                                        ? `${y.player.nickname} (${y.player.name})`
                                        : y.player.name}
                                    </Col>
                                    <Col className='player-price-column'>${y.playerCost}</Col>
                                  </Row>
                                </ListGroupItem>
                              ) : (
                                i > pageLimit * (page - 1) - 1 &&
                                i < pageLimit * page && (
                                  <ListGroupItem
                                    key={y._id}
                                    className='player-row'
                                    onClick={(e) =>
                                      selectPlayer(
                                        e,
                                        y.playerCost,
                                        y.player.name,
                                        y.player.image,
                                        y.player._id,
                                        x._id,
                                        x.groupLimit
                                      )
                                    }
                                  >
                                    <Row className='players-pool'>
                                      <Col className='player-image-column'>
                                        <Image src={y.player.image} width='45px'></Image>
                                      </Col>
                                      <Col className='player-name-column'>
                                        {series.isOnline === true
                                          ? `${y.player.nickname} (${y.player.name})`
                                          : y.player.name}
                                      </Col>
                                      <Col className='player-price-column'>${y.playerCost}</Col>
                                    </Row>
                                  </ListGroupItem>
                                )
                              )
                            )
                      )}
                    <div className='pagination'>
                      <Pagination>
                        {[...Array(pages).keys()].map((x) => (
                          <Pagination.Item active={x + 1 === page} key={x + 1} onClick={(e) => setPage(x + 1)}>
                            {x + 1}
                          </Pagination.Item>
                        ))}
                      </Pagination>
                    </div>
                  </>
                )}
              </ListGroup>
            </Col>
            <Col md={7}>
              <div align='center'>
                <h4>Your Team</h4>
              </div>
              <Row>
                <Col md={4}>
                  <div className='count-header'>
                    <div>Remaining budget: </div>
                    <div className='count-value'>
                      ${budget} / ${draft && draft.budget}
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className='count-header'>
                    <div>Players selected: </div>
                    <div className='count-value'>
                      {playersCount} / {draft && draft.playersLimit}
                    </div>
                  </div>
                </Col>
                <Col>
                  {myTeam.length === draft.playersLimit && user && user.balance >= match.params.buyin ? (
                    // && draft.startDate < Date.now()
                    <Button onClick={submitHandler} className='btn-block btn-play btn-pad' type='button'>
                      Enter Draft (${match.params.buyin})
                    </Button>
                  ) : (
                    <Button onClick={submitHandler} className='btn-block btn-pad' type='button' disabled>
                      Enter Draft (${match.params.buyin})
                    </Button>
                  )}
                </Col>
              </Row>
              <div className='oneRem'>Enter team name:</div>
              <div>
                <Form.Control
                  type='name'
                  placeholder='Enter name'
                  value={teamName}
                  onChange={(e) => setTeamName(e.target.value)}
                ></Form.Control>
              </div>

              <div className='team-roster'>
                <div className='grid-box'>
                  {myTeam.length >= 1 && (
                    <>
                      <div className={`player-image1 ${myTeam[0].className}`}>
                        <Image src={myTeam[0].src} width='140px'></Image>
                      </div>
                      <div className={`player-text player-text1 ${myTeam[0].className}`}>
                        <div>{myTeam[0].alt}</div>
                        <div className='info-price-value'>${myTeam[0].price}</div>
                      </div>
                    </>
                  )}
                </div>
                <div className='grid-box'>
                  {myTeam.length >= 2 && (
                    <>
                      <div className={`player-image2 ${myTeam[1].className}`}>
                        <Image src={myTeam[1].src} width='140px'></Image>
                      </div>
                      <div className={`player-text player-text2 ${myTeam[1].className}`}>
                        <div>{myTeam[1].alt}</div>
                        <div className='info-price-value'>${myTeam[1].price}</div>
                      </div>
                    </>
                  )}
                </div>
                <div className='grid-box'>
                  {myTeam.length >= 3 && (
                    <>
                      <div className={`player-image3 ${myTeam[2].className}`}>
                        <Image src={myTeam[2].src} width='140px'></Image>
                      </div>
                      <div className={`player-text player-text3 ${myTeam[2].className}`}>
                        <div>{myTeam[2].alt}</div>
                        <div className='info-price-value'>${myTeam[2].price}</div>
                      </div>
                    </>
                  )}
                </div>
                <div className='grid-box'>
                  {myTeam.length >= 4 && (
                    <>
                      <div className={`player-image4 ${myTeam[3].className}`}>
                        <Image src={myTeam[3].src} width='140px'></Image>
                      </div>
                      <div className={`player-text player-text4 ${myTeam[3].className}`}>
                        <div>{myTeam[3].alt}</div>
                        <div className='info-price-value'>${myTeam[3].price}</div>
                      </div>
                    </>
                  )}
                </div>
                <div></div>
                <div className='grid-box'>
                  {myTeam.length >= 5 && (
                    <>
                      <div className={`player-image5 ${myTeam[4].className}`}>
                        <Image src={myTeam[4].src} width='140px'></Image>
                      </div>
                      <div className={`player-text player-text5 ${myTeam[4].className}`}>
                        <div>{myTeam[4].alt}</div>
                        <div className='info-price-value'>${myTeam[4].price}</div>
                      </div>
                    </>
                  )}
                </div>
                <div className='grid-box'>
                  {myTeam.length >= 6 && (
                    <>
                      <div className={`player-image6 ${myTeam[5].className}`}>
                        <Image src={myTeam[5].src} width='140px'></Image>
                      </div>
                      <div className={`player-text player-text6 ${myTeam[5].className}`}>
                        <div>{myTeam[5].alt}</div>
                        <div className='info-price-value'>${myTeam[5].price}</div>
                      </div>
                    </>
                  )}
                </div>
                <div className='grid-box'>
                  <div className='draft-name'>
                    {draft && draft.name} ${match.params.buyin}
                  </div>
                </div>
                <div></div>
                <div className='grid-box'>
                  {myTeam.length >= 7 && (
                    <>
                      <div className={`player-image7 ${myTeam[6].className}`}>
                        <Image src={myTeam[6].src} width='140px'></Image>
                      </div>
                      <div className={`player-text player-text7 ${myTeam[6].className}`}>
                        <div>{myTeam[6].alt}</div>
                        <div className='info-price-value'>${myTeam[6].price}</div>
                      </div>
                    </>
                  )}
                </div>
                <div className='grid-box'>
                  {myTeam.length >= 8 && (
                    <>
                      <div className={`player-image8 ${myTeam[7].className}`}>
                        <Image src={myTeam[7].src} width='140px'></Image>
                      </div>
                      <div className={`player-text player-text8 ${myTeam[7].className}`}>
                        <div>{myTeam[7].alt}</div>
                        <div className='info-price-value'>${myTeam[7].price}</div>
                      </div>
                    </>
                  )}
                </div>
                <div className='grid-box'>
                  {myTeam.length >= 9 && (
                    <>
                      <div className={`player-image9 ${myTeam[8].className}`}>
                        <Image src={myTeam[8].src} width='140px'></Image>
                      </div>
                      <div className={`player-text player-text9 ${myTeam[8].className}`}>
                        <div>{myTeam[8].alt}</div>
                        <div className='info-price-value'>${myTeam[8].price}</div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default DraftScreen
