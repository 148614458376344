import axios from 'axios'

import {
  TOURNAMENT_LIST_REQUEST,
  TOURNAMENT_LIST_SUCCESS,
  TOURNAMENT_LIST_FAIL,
  TOURNAMENT_DETAILS_REQUEST,
  TOURNAMENT_DETAILS_SUCCESS,
  TOURNAMENT_DETAILS_FAIL,
  TOURNAMENT_DELETE_SUCCESS,
  TOURNAMENT_DELETE_FAIL,
  TOURNAMENT_DELETE_REQUEST,
  TOURNAMENT_CREATE_REQUEST,
  TOURNAMENT_CREATE_SUCCESS,
  TOURNAMENT_CREATE_FAIL,
  TOURNAMENT_UPDATE_REQUEST,
  TOURNAMENT_UPDATE_SUCCESS,
  TOURNAMENT_UPDATE_FAIL,
  TOURNAMENT_CREATE_RESULT_REQUEST,
  TOURNAMENT_CREATE_RESULT_SUCCESS,
  TOURNAMENT_CREATE_RESULT_FAIL,
  TOURNAMENT_DELETE_RESULT_REQUEST,
  TOURNAMENT_DELETE_RESULT_SUCCESS,
  TOURNAMENT_DELETE_RESULT_FAIL,
  TOURNAMENT_RESULT_DETAILS_REQUEST,
  TOURNAMENT_RESULT_DETAILS_SUCCESS,
  TOURNAMENT_RESULT_DETAILS_FAIL,
  TOURNAMENT_RESULT_UPDATE_REQUEST,
  TOURNAMENT_RESULT_UPDATE_SUCCESS,
  TOURNAMENT_RESULT_UPDATE_FAIL,
} from '../constants/tournamentConstants'

export const listTournaments = (series = '') => async (dispatch) => {
  try {
    dispatch({
      type: TOURNAMENT_LIST_REQUEST,
    })

    const { data } = await axios.get(`/api/tournaments?series=${series}`)

    dispatch({
      type: TOURNAMENT_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TOURNAMENT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listTournamentDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: TOURNAMENT_DETAILS_REQUEST })

    const { data } = await axios.get(`/api/tournaments/${id}`)

    dispatch({
      type: TOURNAMENT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TOURNAMENT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteTournament = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TOURNAMENT_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/tournaments/${id}`, config)

    dispatch({
      type: TOURNAMENT_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: TOURNAMENT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createTournament = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: TOURNAMENT_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/tournaments`, {}, config)

    dispatch({
      type: TOURNAMENT_CREATE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: TOURNAMENT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TOURNAMENT_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateTournament = (tournament) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TOURNAMENT_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/tournaments/${tournament._id}`,
      tournament,
      config
    )

    dispatch({
      type: TOURNAMENT_UPDATE_SUCCESS,
    })
    dispatch({
      type: TOURNAMENT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TOURNAMENT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createTournamentResult = (tournamentId, result) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: TOURNAMENT_CREATE_RESULT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.post(`/api/tournaments/${tournamentId}/results`, result, config)

    dispatch({
      type: TOURNAMENT_CREATE_RESULT_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: TOURNAMENT_CREATE_RESULT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteTournamentResult = (
  tournamentId,
  resultId,
  tournament
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TOURNAMENT_DELETE_RESULT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.put(
      `/api/tournaments/${tournamentId}/results?resultid=${resultId}`,
      tournament,
      config
    )

    dispatch({
      type: TOURNAMENT_DELETE_RESULT_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: TOURNAMENT_DELETE_RESULT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const tournamentResultDetails = (tournamentId, resultId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: TOURNAMENT_RESULT_DETAILS_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `/api/tournaments/${tournamentId}/results?resultid=${resultId}`,
      config
    )

    dispatch({
      type: TOURNAMENT_RESULT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TOURNAMENT_RESULT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateTournamentResult = (
  tournamentId,
  resultId,
  result
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TOURNAMENT_RESULT_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/tournaments/${tournamentId}/results/edit?resultid=${resultId}`,
      result,
      config
    )

    dispatch({
      type: TOURNAMENT_RESULT_UPDATE_SUCCESS,
    })
    dispatch({
      type: TOURNAMENT_RESULT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TOURNAMENT_RESULT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
