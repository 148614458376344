import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col, ListGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import {
  listPoolPlayersDetails,
  updatePoolPlayers,
  createPoolPlayersGroup,
  deletePoolPlayersGroup,
} from '../actions/poolPlayersActions'
import { listSeries } from '../actions/seriesActions'
import {
  POOLPLAYERS_UPDATE_RESET,
  POOLPLAYERS_CREATE_GROUP_RESET,
  POOLPLAYERS_DELETE_GROUP_RESET,
} from '../constants/poolPlayersConstants'
import { SERIES_DETAILS_RESET } from '../constants/seriesConstants'

const PoolPlayersEditScreen = ({ match, history }) => {
  const poolPlayersId = match.params.id

  const [name, setName] = useState('')
  const [series, setSeries] = useState('')
  const [isActive, setIsActive] = useState(false)
  const [poolPlayersGroups, setPoolPlayersGroups] = useState([])
  const [groupName, setGroupName] = useState('')
  const [groupLimit, setGroupLimit] = useState(0)

  const dispatch = useDispatch()

  const seriesList = useSelector((state) => state.seriesList)
  const { seriesArray } = seriesList

  const poolPlayersDetails = useSelector((state) => state.poolPlayersDetails)
  const { loading, error, poolPlayers } = poolPlayersDetails

  const poolPlayersGroupCreate = useSelector(
    (state) => state.poolPlayersGroupCreate
  )
  const {
    success: successPoolPlayersGroup,
    error: errorPoolPlayersGroup,
  } = poolPlayersGroupCreate

  const poolPlayersGroupDelete = useSelector(
    (state) => state.poolPlayersGroupDelete
  )
  const {
    success: successPoolPlayersGroupDelete,
    error: errorPoolPlayersGroupDelete,
  } = poolPlayersGroupDelete

  const poolPlayersUpdate = useSelector((state) => state.poolPlayersUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = poolPlayersUpdate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    dispatch({ type: SERIES_DETAILS_RESET })
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }
    if (successPoolPlayersGroup) {
      setGroupLimit(0)
      setGroupName('')
      dispatch({ type: POOLPLAYERS_CREATE_GROUP_RESET })
      dispatch(listPoolPlayersDetails(poolPlayersId))
    }

    if (successPoolPlayersGroupDelete) {
      dispatch({ type: POOLPLAYERS_DELETE_GROUP_RESET })
      dispatch(listPoolPlayersDetails(poolPlayersId))
    }

    if (successUpdate) {
      dispatch({ type: POOLPLAYERS_UPDATE_RESET })
      history.push('/admin/poolplayerslist')
    } else {
      if (!poolPlayers || poolPlayers._id !== poolPlayersId) {
        dispatch(listPoolPlayersDetails(poolPlayersId))
      } else {
        setName(poolPlayers.name)
        setSeries(poolPlayers.series._id)
        setIsActive(poolPlayers.isActive)
        setPoolPlayersGroups(poolPlayers.poolPlayersGroups)
      }
      dispatch(listSeries())
    }
  }, [
    dispatch,
    history,
    poolPlayers,
    poolPlayersId,
    successUpdate,
    successPoolPlayersGroup,
    successPoolPlayersGroupDelete,
    userInfo,
  ])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updatePoolPlayers({
        _id: poolPlayersId,
        name,
        series,
        isActive,
      })
    )
  }

  const submitGroupHandler = (e) => {
    e.preventDefault()
    dispatch(
      createPoolPlayersGroup(match.params.id, {
        groupName,
        groupLimit,
      })
    )
  }

  const deleteGroupHandler = (groupid) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deletePoolPlayersGroup(match.params.id, groupid))
    }
  }

  return (
    <>
      <Link to='/admin/poolplayerslist' className='btn btn-light my-3'>
        Go Back To Pool List
      </Link>
      <Row>
        <Col md={8}>
          <FormContainer>
            <h1>Edit Pool</h1>
            {loadingUpdate && <Loader />}
            {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <Form onSubmit={submitHandler}>
                <Form.Group controlId='name'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type='name'
                    placeholder='Enter name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId='brand'>
                  <Form.Label>Series</Form.Label>
                  <Form.Control
                    as='select'
                    value={series}
                    onChange={(e) => setSeries(e.target.value)}
                  >
                    <option value=''>Select...</option>
                    {seriesArray
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((seriesname) => (
                        <option key={seriesname._id} value={seriesname._id}>
                          {seriesname.name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId='isactive'>
                  <Form.Check
                    type='checkbox'
                    label='Is Active'
                    checked={isActive}
                    onChange={(e) => setIsActive(e.target.checked)}
                  ></Form.Check>
                </Form.Group>

                <Button type='submit' variant='primary'>
                  Update Pool
                </Button>
              </Form>
            )}
          </FormContainer>
        </Col>
        <Col md={4}>
          <Row>
            <Col>
              <h2>Groups</h2>
              {poolPlayersGroups.length === 0 && <Message>No Groups</Message>}
              <ListGroup variant='flush'>
                {poolPlayersGroups
                  .sort((a, b) => (a.groupName > b.groupName ? 1 : -1))
                  .map((group) => (
                    <ListGroup.Item key={group._id}>
                      <Row>
                        <Col>
                          <strong>
                            {group.groupName}, limit: {group.groupLimit}
                          </strong>
                        </Col>
                        <Col>
                          <LinkContainer
                            to={`/admin/poolplayers/${poolPlayersId}/groups/${group._id}/edit`}
                          >
                            <Button variant='light' className='btn-sm'>
                              <i className='fas fa-th'></i>
                            </Button>
                          </LinkContainer>
                          <Button
                            variant='danger'
                            className='btn-sm'
                            onClick={() => deleteGroupHandler(group._id)}
                          >
                            <i className='fas fa-trash'></i>
                          </Button>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                <ListGroup.Item>
                  {errorPoolPlayersGroup && (
                    <Message variant='danger'>{errorPoolPlayersGroup}</Message>
                  )}
                  {errorPoolPlayersGroupDelete && (
                    <Message variant='danger'>
                      {errorPoolPlayersGroupDelete}
                    </Message>
                  )}
                  <Form onSubmit={submitGroupHandler}>
                    <Form.Group controlId='groupName'>
                      <Form.Label>New group name</Form.Label>
                      <Form.Control
                        type='groupName'
                        placeholder='Enter group name'
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='groupLimit'>
                      <Form.Label>Players limit for a team</Form.Label>
                      <Form.Control
                        type='number'
                        value={groupLimit}
                        onChange={(e) => setGroupLimit(e.target.value)}
                      ></Form.Control>
                    </Form.Group>

                    <Button type='submit' variant='primary'>
                      Create Group
                    </Button>
                  </Form>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
export default PoolPlayersEditScreen
