import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { createTournamentResult, deleteTournamentResult, listTournamentDetails } from '../actions/tournamentActions'
import { listPlayers } from '../actions/playerActions'
import { TOURNAMENT_CREATE_RESULT_RESET, TOURNAMENT_DELETE_RESULT_RESET } from '../constants/tournamentConstants'

const TournamentResultListScreen = ({ match, history }) => {
  const tournamentId = match.params.id

  const [player, setPlayer] = useState('')
  const [chips, setChips] = useState(0)
  const [rank, setRank] = useState(0)
  const [points, setPoints] = useState(0)
  const [prize, setPrize] = useState(0)

  const dispatch = useDispatch()

  const tournamentDetails = useSelector((state) => state.tournamentDetails)
  const { loading, error, tournament } = tournamentDetails

  const playerList = useSelector((state) => state.playerList)
  const { loading: loadingPlayers, error: errorPlayers, players } = playerList

  const tournamentResultCreate = useSelector((state) => state.tournamentResultCreate)
  const { success: successCreate, loading: loadingCreate, error: errorCreate } = tournamentResultCreate

  const tournamentResultDelete = useSelector((state) => state.tournamentResultDelete)
  const { success: successDelete, loading: loadingDelete, error: errorDelete } = tournamentResultDelete

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    } else {
      if (successCreate) {
        dispatch({ type: TOURNAMENT_CREATE_RESULT_RESET })
        dispatch(listTournamentDetails(tournamentId))
        setPlayer('')
        setChips(0)
        setRank(0)
        setPoints(0)
        setPrize(0)
      } else {
        if (successDelete) {
          dispatch({ type: TOURNAMENT_DELETE_RESULT_RESET })
          dispatch(listTournamentDetails(tournamentId))
        } else {
          if (!tournament.series || tournament._id !== tournamentId) {
            dispatch(listTournamentDetails(tournamentId))
          } else {
            dispatch(listPlayers(tournament.series.brand))
          }
        }
      }
    }
  }, [dispatch, history, tournamentId, tournament, userInfo, successCreate, successDelete])

  const submitResultHandler = (e) => {
    e.preventDefault()
    dispatch(
      createTournamentResult(tournamentId, {
        player,
        chips,
        rank,
        points,
        prize,
      })
    )
  }

  const deleteResultHandler = (resultId) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteTournamentResult(tournamentId, resultId))
    }
  }

  return (
    <>
      <Link to='/admin/tournamentlist' className='btn btn-light my-3'>
        Go Back To Tournament List
      </Link>
      <Row>
        <Col md={8}>
          <Row className='align-items-center'>
            <Col>
              <h1>Tournaments</h1>
            </Col>
          </Row>
          {loadingDelete && <Loader />}
          {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
          {loadingCreate && <Loader />}
          {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <Table striped bordered hover responsive className='table-sm'>
              <thead>
                <tr>
                  <th>PLAYER</th>
                  <th>COUNTRY</th>
                  <th>RANK</th>
                  <th>CHIPS</th>
                  <th>POINTS</th>
                  <th>PRIZE</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tournament.results &&
                  tournament.results.length !== 0 &&
                  tournament.results
                    .sort((a, b) =>
                      tournament.series.isOnline === true
                        ? a.player.nickname > b.player.nickname
                          ? 1
                          : -1
                        : a.player.name > b.player.name
                        ? 1
                        : -1
                    )
                    .map((result) => (
                      <tr key={result._id}>
                        <td>{tournament.series.isOnline === true ? result.player.nickname : result.player.name}</td>
                        <td>{result.player.country}</td>
                        <td>{result.rank}</td>
                        <td>{result.chips}</td>
                        <td>{result.points}</td>
                        <td>{result.prize}</td>
                        <td>
                          <LinkContainer to={`/admin/tournament/${tournamentId}/result/${result._id}/edit`}>
                            <Button variant='light' className='btn-sm'>
                              <i className='fas fa-edit'></i>
                            </Button>
                          </LinkContainer>
                          <Button variant='danger' className='btn-sm' onClick={() => deleteResultHandler(result._id)}>
                            <i className='fas fa-trash'></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>
          )}
        </Col>
        <Col md={4}>
          <h2>Create Result</h2>
          <Form onSubmit={submitResultHandler}>
            {loadingPlayers && <Loader />}
            {errorPlayers && <Message variant='danger'>{errorPlayers}</Message>}
            <Form.Group controlId='player'>
              <Form.Label>Player</Form.Label>
              <Form.Control as='select' value={player} onChange={(e) => setPlayer(e.target.value)}>
                <option value=''>Select...</option>
                {players
                  .sort((a, b) =>
                    tournament.series.isOnline === true ? (a.nickname > b.nickname ? 1 : -1) : a.name > b.name ? 1 : -1
                  )
                  .map((x) => (
                    <option key={x._id} value={x._id}>
                      {tournament.series.isOnline === true ? x.nickname : x.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId='rank'>
              <Form.Label>Rank</Form.Label>
              <Form.Control type='number' value={rank} onChange={(e) => setRank(e.target.value)}></Form.Control>
            </Form.Group>

            <Form.Group controlId='chips'>
              <Form.Label>Chips</Form.Label>
              <Form.Control type='number' value={chips} onChange={(e) => setChips(e.target.value)}></Form.Control>
            </Form.Group>

            <Form.Group controlId='points'>
              <Form.Label>Points</Form.Label>
              <Form.Control type='number' value={points} onChange={(e) => setPoints(e.target.value)}></Form.Control>
            </Form.Group>

            <Form.Group controlId='prize'>
              <Form.Label>Prize</Form.Label>
              <Form.Control type='number' value={prize} onChange={(e) => setPrize(e.target.value)}></Form.Control>
            </Form.Group>

            <Button type='submit' variant='primary'>
              Add Result
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  )
}
export default TournamentResultListScreen
