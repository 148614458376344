import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  listPokerGames,
  deletePokerGame,
  createPokerGame,
} from '../actions/pokerGameActions'
import { POKERGAME_CREATE_RESET } from '../constants/pokerGameConstants'

const PokerGameListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const pokerGameList = useSelector((state) => state.pokerGameList)
  const { loading, error, pokerGames } = pokerGameList

  const pokerGameDelete = useSelector((state) => state.pokerGameDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = pokerGameDelete

  const pokerGameCreate = useSelector((state) => state.pokerGameCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    pokerGame: createdPokerGame,
  } = pokerGameCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    dispatch({ type: POKERGAME_CREATE_RESET })
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }
    if (successCreate) {
      history.push(`/admin/pokergame/${createdPokerGame._id}/edit`)
    } else {
      dispatch(listPokerGames())
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdPokerGame,
  ])

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deletePokerGame(id))
    }
  }

  const createPokerGameHandler = () => {
    dispatch(createPokerGame())
  }

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Poker Games</h1>
        </Col>
        <Col className='text-right'>
          <Button className='my-3' onClick={createPokerGameHandler}>
            <i className='fas fa-plus'></i> Create Poker Game
          </Button>
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>NAME</th>
              <th>DESCRIPTION</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {pokerGames
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((pokerGame) => (
                <tr key={pokerGame._id}>
                  <td>{pokerGame.name}</td>
                  <td>{pokerGame.description}</td>
                  <td>
                    <LinkContainer
                      to={`/admin/pokergame/${pokerGame._id}/edit`}
                    >
                      <Button variant='light' className='btn-sm'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </LinkContainer>
                    <Button
                      variant='danger'
                      className='btn-sm'
                      onClick={() => deleteHandler(pokerGame._id)}
                    >
                      <i className='fas fa-trash'></i>
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  )
}

export default PokerGameListScreen
