import axios from 'axios'

import {
  DRAFT_LIST_REQUEST,
  DRAFT_LIST_SUCCESS,
  DRAFT_LIST_FAIL,
  DRAFT_DETAILS_REQUEST,
  DRAFT_DETAILS_SUCCESS,
  DRAFT_DETAILS_FAIL,
  DRAFT_DELETE_SUCCESS,
  DRAFT_DELETE_FAIL,
  DRAFT_DELETE_REQUEST,
  DRAFT_CREATE_REQUEST,
  DRAFT_CREATE_SUCCESS,
  DRAFT_CREATE_FAIL,
  DRAFT_UPDATE_REQUEST,
  DRAFT_UPDATE_SUCCESS,
  DRAFT_UPDATE_FAIL,
} from '../constants/draftConstants'

export const listDrafts =
  (keyword = '', pageNumber = '') =>
  async (dispatch) => {
    try {
      dispatch({ type: DRAFT_LIST_REQUEST })

      const { data } = await axios.get(`/api/drafts?keyword=${keyword}&pageNumber=${pageNumber}`)

      dispatch({
        type: DRAFT_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: DRAFT_LIST_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
    }
  }

export const listDraftDetails = (id, buyin) => async (dispatch) => {
  try {
    dispatch({ type: DRAFT_DETAILS_REQUEST })
    const { data } = await axios.get(`/api/drafts/${id}/${buyin}`)

    dispatch({
      type: DRAFT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DRAFT_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const deleteDraft = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DRAFT_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/drafts/${id}`, config)

    dispatch({
      type: DRAFT_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: DRAFT_DELETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const createDraft = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: DRAFT_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/drafts`, {}, config)

    dispatch({
      type: DRAFT_CREATE_SUCCESS,
      payload: data,
    })
    dispatch({
      type: DRAFT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DRAFT_CREATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const updateDraft = (draft) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DRAFT_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/drafts/${draft._id}`, draft, config)

    dispatch({
      type: DRAFT_UPDATE_SUCCESS,
    })
    dispatch({
      type: DRAFT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DRAFT_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
