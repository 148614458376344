import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description}></meta>
      <meta name='keywords' content={keywords}></meta>
    </Helmet>
  )
}

Meta.defaultProps = {
  title: 'Welcome To MyPokerDraft',
  keywords:
    'poker draft, poker fantasy, wsop fantasy, wcoop fantasy, scoop fantasy',
  description: 'The best fantasy poker drafts for your pleasure.',
}

export default Meta
